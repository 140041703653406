// @/src/components/Table/index.jsx
import React, { useState, useEffect } from "react";
import Loader from "../../common/Loader";
import { apiConstants, post, get } from "../../common/constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './notification.css';
import { getMerchant } from '../../helpers/ReduxHelpers';
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { statusNotifications } from "../../helpers/UtilityHelper";

const B2CNotification = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''
    const { enqueueSnackbar } = useSnackbar();

    const modules = statusNotifications;

    const onSubmit = (data) => {
        setLoading(true);
        const payload = {
            "subscriptions": {
                "WHATSAPP": data.whatsapp === false ? [] : data.whatsapp,
                "SMS": data.sms === false ? [] : data.sms,
                "EMAIL": data.email === false ? [] : data.email
            }
        }

        post(`${apiConstants.BASE_LOCAL}${apiConstants.POST_SUBSCRIPTION}/${merchantId}`, payload).then(response => {
            loadSubscription();
            if (response?.data?.responseObj === 'Alerts updated Successfully.') {
                // toast.success('Preference updated successfully');
                enqueueSnackbar("Preference updated successfully", { variant: "success" });
            }
            else {
                enqueueSnackbar(response?.data?.responseObj, { variant: "error" });
            }
            // toast.success(response.data.responseObj);

            setLoading(false);

        })
            .catch(err => {
                console.error(err);
                if (err && err.error) {
                    // toast.error(err.error);
                    enqueueSnackbar(err.error, { variant: "error" });
                }
                else if (err && err.response && err.response.data && err.response.data.message) {
                    enqueueSnackbar(err.response.data.message, { variant: "error" });
                }
                else if (err && err.message) {
                    enqueueSnackbar(err.message, { variant: "error" });
                }
                setLoading(false);
            });
    }

    const loadSubscription = () => {
        setLoading(true);
        get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_SUBSCRIPTION}`).then(response => {
            setLoading(false);
            if (response.data && response.data.subscriptions) {
                let defaultValues = {};
                defaultValues.whatsapp = (response.data.subscriptions.WHATSAPP) ? response.data.subscriptions.WHATSAPP : [];
                defaultValues.sms = (response.data.subscriptions.SMS) ? response.data.subscriptions.SMS : [];
                defaultValues.email = (response.data.subscriptions.EMAIL) ? response.data.subscriptions.EMAIL : [];
                reset({ ...defaultValues });
            }

        })
            .catch(err => {
                console.error(err);
                if (err && err.error) {
                    toast.error(err.error);
                }
                else if (err && err.response && err.response.data && err.response.data.message) {
                    toast.error(err.response.data.message);
                }
                else if (err && err.message) {
                    toast.error(err.message);
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        loadSubscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {(loading) && <Loader />}
            <div className="row">
                <div className="col-12">
                    <div className="card card-pr card-check-pincode">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th width="15%">Event</th>
                                                <th width="55%">Notification</th>
                                                <th width="30%">Notification Channel</th>
                                                {/*<th className="text-md-end action-btn" width="20%">Template Preview</th>*/}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {modules.map((item) =>
                                                <tr key={item.val}>
                                                    <td>{item.label}</td>
                                                    <td>{item.msg}</td>
                                                    <td className={`${errors.sms || errors.whatsapp ? "is-invalid" : ""}`}>
                                                        <input
                                                            {...register('sms')}
                                                            className="form-check-input notification-cbx"
                                                            type="checkbox"
                                                            value={item.val}
                                                        //onClick={handleSmsClick}
                                                        //name="sms[]"
                                                        />
                                                        <label className="for m-check-label notification-lbl">SMS</label>
                                                        <input
                                                            {...register('whatsapp')}
                                                            className="form-check-input notification-cbx"
                                                            type="checkbox"
                                                            value={item.val}
                                                        //onClick={handleWhatsappClick}
                                                        //name="whatsapp[]"
                                                        />
                                                        <label className="form-check-label notification-lbl">Whatsapp</label>
                                                        <input
                                                            {...register('email')}
                                                            className="form-check-input notification-cbx"
                                                            type="checkbox"
                                                            value={item.val}
                                                        //onClick={handleWhatsappClick}
                                                        //name="whatsapp[]"
                                                        />
                                                        <label className="form-check-label notification-lbl">Email</label>
                                                    </td>
                                                    {/*<td className="text-md-end action-btn">
                                                            <a href="#" className="btn btn-link">
                                                                <img src="img/book.svg" width="20" className="me-2" alt="" />
                                                                Preview
                                                            </a>
                                                            </td>*/}
                                                </tr>
                                            )}
                                            <tr>
                                                <td>
                                                    &nbsp;
                                                </td>
                                                <td>
                                                    &nbsp;
                                                </td>

                                                <td>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mt-0 notification-save-btn"
                                                    >Save</button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};

export default B2CNotification
