import { Box, Button, Divider, Drawer, IconButton, Popover, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";
import * as React from 'react';
import { useNavigate } from "react-router-dom";

const StyledDrawer = styled(Drawer)(({ theme, width }) => ({
    '& .MuiDrawer-paper': {
        width: width,
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    }
}))

export default function SideDrawer({ open, setOpen, drawerWidth, anchor, title, children, subtitle }) {
    return (
        <StyledDrawer
            anchor={anchor || 'right'}
            open={open}
            onClose={() => setOpen(false)}
            width={drawerWidth || '60%'}
        >
            <Box sx={{
                px: '30px',
                py: '10px',
            }} width={1} display="flex" alignItems="center" justifyContent="space-between">
                <Box><Typography variant="h6">{title}</Typography></Box>
                <Box><IconButton onClick={() => setOpen(false)} ><CloseIcon /></IconButton></Box>
            </Box>
            <Box sx={{ px: '30px' }}><Typography variant='subtitle2'>{subtitle}</Typography></Box>
            <Box
                width={1}
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: 2,
                    px: '30px',
                    py: '10px'
                }}>
                {children}
            </Box>
        </StyledDrawer>
    )
}

export function SideDrawer2({ open, setOpen, drawerWidth, anchor, title, children, subtitle }) {
    return (
        <StyledDrawer
            anchor={anchor || 'right'}
            open={open}
            onClose={() => setOpen(false)}
            width={drawerWidth || '60%'}
            sx={{
                '& .MuiDrawer-paper': {
                    width: drawerWidth || '60%',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                    backgroundColor: '#f7f9fc',
                }
            }}
        >
            <Box sx={{
                px: '30px',
                py: '10px',
                backgroundColor: '#07ff23b0',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <Typography variant="h6">{title}</Typography>
                <IconButton onClick={() => setOpen(false)} sx={{ color: '#fff' }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {subtitle && (
                <Box sx={{ px: '30px', py: '10px', backgroundColor: '#e3f2fd' }}>
                    <Typography variant="subtitle2">{subtitle}</Typography>
                </Box>
            )}
            <Divider />
            <Box
                width={1}
                sx={{
                    marginTop: 2,
                    px: '30px',
                    py: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >
                {children}
            </Box>
        </StyledDrawer>
    );
}


export function CustomPopover({
    open,
    setOpen,
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    subtitle = '',
    children,
    popoverWidth = '300px',
    popoverProps = {},
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    const viewAllHandler = () => {
        setOpen(false);
        navigate('/allupdates');
    };

    return (
        <div>
            <Popover
                id="custom-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                PaperProps={{
                    sx: {
                        width: popoverWidth,
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                        mt: 3,
                        borderRadius: '12px',
                        transform: 'scale(1.05)', // Slightly scale up for emphasis
                        transition: 'transform 0.2s ease', // Smooth scaling transition
                        '&:hover': {
                            transform: 'scale(1.1)', // Slightly larger on hover for effect
                            boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.3)', // Deeper shadow on hover
                        },
                    },
                }}
                {...popoverProps}
            >
                {subtitle && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            px: 2,
                            py: 1,
                            backgroundColor: '#212B36',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                        }}
                    >
                        <img
                            src="/img/logo-prozo.png"
                            width="27"
                            height="40"
                            alt="logo"
                            id="home"
                        />
                        <Typography
                            variant="subtitle1"
                            sx={{ color: 'var(--bs-gray-300)', ml: 2 }}
                        >
                            {subtitle}
                        </Typography>
                    </Box>
                )}
                <Divider />
                <Box
                    sx={{
                        maxHeight: '80vh',
                        minHeight: '50vh',
                        overflowY: 'auto',
                        p: 2,
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',  // Fully transparent track for modern feel
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',  // Subtle dark scrollbar thumb
                            borderRadius: '10px',
                            transition: 'background-color 0.3s',  // Smooth transition effect
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Darker on hover
                        },
                    }}
                >
                    {children}
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={viewAllHandler}
                    >
                        View All
                    </Button>
                    {/* {children.props?.pushNotification?.length > 0 && children.props?.pushNotification.map((child, index) => (
                        <Box key={index}>
                            
                            {index === 0 && (
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={viewAllHandler}
                                >
                                    View All
                                </Button>
                            )}
                        </Box>
                    ))} */}
                </Box>
                <Divider />
            </Popover>
        </div>
    );
}
