import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { FormProvider, RHFFileField, RHFRadioGroup, RHFSelectField, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { escalationArray, generateRandom10DigitNumber } from "../../helpers/UtilityHelper";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { apiConstants, post } from "../../common/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Divider from '@mui/material/Divider';
import RHFTextArea from "../../common/hook-form/RHFTextArea";
import { getMerchant } from "../../helpers/ReduxHelpers";
import { useSnackbar } from "notistack";

const CreateTicket = ({ setFiltersChange, handleOpen, setTicketId }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [formFields, setFormFields] = useState([])
    const [dueDate, setDueDate] = useState("")
    const [frDueDate, setFrDueDate] = useState("")
    // const { userInfo } = useSelector((state) => state.user);
    // const userToken = jwt_decode(getToken());
    // const merchantId = userToken && userToken ? userToken : "";

    const merchantDetail = getMerchant();    
   
    const { enqueueSnackbar } = useSnackbar();
    

    const formElementNames = formFields?.map((val) => ({
        name: val.name,
        isMandatory: val.isMandatory,
        type: val.type
    })) ?? []

    const createValidationSchema = (formElementNames) => {
        const schemaObject = {};
        formElementNames?.forEach((element, index) => {
            const key = element.type === 'file' ? `${element.name}_${index}` : element.name;
            if (element.isMandatory === 'Y' && element.type === 'file') {
                schemaObject[key] = Yup.mixed().required(`Please upload file`);
            } else if (element.isMandatory === 'Y' && element.type !== 'file') {
                schemaObject[key] = Yup.string().required(`Please provide ${element.name}`);
            }else if (key === "add_email") {
                schemaObject['add_email'] = Yup.string().test(
                    'valid-emails',
                    'Invalid email address',
                    (value) => {
                        if (!value) return true;
                        const emails = value.split(',').map(email => email.trim());
                        return emails.every(email => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email));
                    }
                );
            }
        });
        return Yup.object().shape(schemaObject);
    };

    const validationSchema = createValidationSchema([
        ...formElementNames,
        { name: 'category', isMandatory: 'Y' },
        { name: 'subcategory', isMandatory: 'Y' },
        { name: 'add_email', isMandatory: 'N' },
    ]);


    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            category: '',
            subcategory: '',
            add_email: '',
        },
        shouldUnregister: true
    });

    const { handleSubmit, reset, watch, setValue } = methods;

    const selectedCategory = watch("category");
    const selectedSubcategory = watch("subcategory");
    const data = escalationArray.filter((val) => val.categoryTitle);
    const categoryOptions = _.orderBy(data, "name").map((val) => ({
        value: val.categoryTitle,
        label: val.categoryTitle,
    }));

    const subcategoryOptions =
        selectedCategory &&
        data.find((val) => val.categoryTitle === selectedCategory)?.subCategories.map((subCat) => ({
            value: subCat.subCatTitle,
            label: subCat.subCatTitle,
        }));

    useEffect(() => {
        if (selectedSubcategory) {
            const formData =
                selectedCategory &&
                data.find((val) => val.categoryTitle === selectedCategory)?.subCategories.find(
                    (subCat) => subCat.subCatTitle === selectedSubcategory
                );
            const newFormFields = formData?.formElements || [];
            setDueDate(formData?.due)
            setFrDueDate(formData?.frDue)
            setFormFields(newFormFields);
            reset();
            newFormFields.forEach((elem, index) => {
                if (elem.type !== "file") {
                    setValue(elem.name, '');
                } else {
                    setValue(`${elem.name}_${index}`, null);
                }
            });
            setValue("category", selectedCategory);
            setValue("subcategory", selectedSubcategory);

        }
    }, [selectedSubcategory, selectedCategory]);

    const buildFormData = (data, awbNumber, cpId) => {
        let formData = new FormData();
        let descriptionString = data.description ? data.description : 'No Description';

        const ccEmails = data.add_email ? data.add_email.split(',') : [];
        ccEmails.forEach(email => {
            formData.append('cc_emails[]', email.trim());
        });
        
        for (const key in data) {
            if (key !== 'email' && key !== 'subject' && key !== 'status' && key !== 'priority' &&
                key !== 'category' && key !== 'awbNumber' && key !== 'merchantId' &&
                key !== 'subcategory' && key !== 'description' && !key.startsWith('uploadedFile_')) {
                descriptionString += `|${key} ${data[key]}`;
            }
        }
        formData.append('description', descriptionString);
        for (const key in data) {
            if (key.startsWith('uploadedFile_') && data[key] !== undefined && data[key] !== null) {
                formData.append('attachments', data[key][0]);
            }
        }
        // formData.append('email', merchantId?.name);
        // formData.append('name', merchantId?.fullname);
        // formData.append('custom_fields[cf_user_email]', merchantId?.name);
        // formData.append('custom_fields[cf_user_name]', merchantId?.fullname);
        formData.append('email', merchantDetail?.email);
        formData.append('name', merchantDetail?.name);
        formData.append('custom_fields[cf_user_email]', merchantDetail?.email);
        formData.append('custom_fields[cf_user_name]', merchantDetail?.name);
        formData.append('subject', `${data.category} / ${data.subcategory} / ${awbNumber ? awbNumber : generateRandom10DigitNumber()}`);
        formData.append('status', 2);
        formData.append('priority', 2);
        formData.append('frDueBy', frDueDate ? frDueDate : 2);
        formData.append('dueBy', dueDate ? dueDate : 24);
        formData.append('custom_fields[cf_category]', data.category);
        if (awbNumber) {
            formData.append('custom_fields[cf_awb_number387695]', awbNumber);
        }
        if (cpId && cpId !== null) {
            formData.append('custom_fields[cf_cp_id]', cpId);
        }
        formData.append('custom_fields[cf_sub_category]', data.subcategory);
        // formData.append('custom_fields[cf_merchant_id]', userInfo?.merchant?.id);
        // formData.append('custom_fields[cf_merchant_name]', userInfo.merchant?.name);
        // formData.append('tags', userInfo?.merchant?.id ? userInfo?.merchant?.id : '-');
        formData.append('custom_fields[cf_merchant_id]', merchantDetail?.id);
        formData.append('custom_fields[cf_merchant_name]', merchantDetail?.name);
        formData.append('tags', merchantDetail?.id ? merchantDetail?.id : '-');
        // formData.append('tags', data.category ? data.category : '-');
        // formData.append('tags', data.subcategory ? data.subcategory : '-');
        cpId && (formData.append('tags', cpId));

        return formData;
    };

    const onSubmit = async (data) => {
        setIsLoading(true)
        try {
            if (data.awbNumber) {
                const awbNumbers = data.awbNumber.split(',').map(num => num.trim());
                const uniqueAWBNumbers = Array.from(new Set(awbNumbers));
                // const merId = merchantId.merchantOid
                const merId = merchantDetail?.id
                for (const awb of uniqueAWBNumbers) {
                    let d = await fetchAWBData(awb, merId)
                    if (!d || d === null) {
                        enqueueSnackbar(`AWB Number ${awb} invalid or doesnt belong to this merchant`, { variant: 'error' })
                        setIsLoading(false);
                    } else {
                        let cpId = d;
                        const formData = buildFormData(data, awb, cpId);
                        submitForm(formData);
                    }
                }
            } else {
                const formData = buildFormData(data, null);
                submitForm(formData);
            }
        } catch (err) {
            toast("Some error occurred!", { type: "error" });
            setIsLoading(false);
            console.log(err);
        }
    };

    const submitForm = async (formData) => {
        try {
            const res = await post(`${apiConstants.BASE_LOCAL}${apiConstants.CREATE_FRESHDESK}`, formData);
            if (res.data?.status === "ERROR") {
                enqueueSnackbar(res.data?.responseObj || "Some error occurred!", { variant: 'error' })
                setIsLoading(false)
            } else {
                const parsedData = JSON.parse(res.data?.responseObj);
                setTicketId(parsedData?.id);
                handleOpen();
                reset();
                setFormFields([]);
                setFiltersChange(filterChange => filterChange + 1);
            }
            setIsLoading(false);
        } catch (err) {
            enqueueSnackbar("Some error occurred!", { variant: 'error' })
            setIsLoading(false);
            console.log(err);
        }
    };

    const fetchAWBData = async (awb, merchantSelectId) => {
        try {
            const andfilter = [
                {
                    "field": "awbNumber",
                    "operator": "eq",
                    "value": awb.trim()
                }
            ];
            const includes = "merchantDetail,courierDetail";
            let d = { "andfilter": andfilter, "includes": includes };
            const res = await post(`${apiConstants.BASE_LOCAL}${apiConstants.ORDER_LISTS}`, d); // Wait for the post request to complete
            if (res.status !== 200 || res?.data?.count === 0) {
                return null;
            } else {
                let data = res.data?.data[0];
                let merchantFromData = data?.merchantDetail.key;
                if (merchantFromData !== merchantSelectId) {
                    return null;
                } else {
                    return data?.courierDetail?.prozoId;
                }
            }
        } catch (error) {
            console.error('Error fetching AWB data:', error);
            return null; // Return null if there's an issue fetching the AWB data
        }
    };

    return (
        <>
            <Grid item container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Divider soft sx={{ mb: 2 }} />
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <label class="form-label">Select Category</label>
                                <RHFSelectField
                                    label="Category"
                                    name="category"
                                    options={categoryOptions}
                                    width="100%"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {selectedCategory && (
                                    <>
                                        <label class="form-label">Select Subcategory</label>
                                        <RHFSelectField
                                            label="Subcategory"
                                            name="subcategory"
                                            options={subcategoryOptions}
                                            width="100%"
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {(selectedCategory && selectedSubcategory) && (
                                    <>
                                        <RHFTextField
                                            label="Additional Email (Comma Separated)"
                                            name="add_email"
                                        />
                                    </>
                                )}
                            </Grid>
                            <FormFields formFields={formFields} />
                            <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                                <LoadingButton loading={isLoading} type="submit" disabled={formFields.length === 0} variant="contained">
                                    Create Ticket
                                </LoadingButton>
                                <Button sx={{ ml: 1 }} variant="contained" color="error" disabled={formFields.length === 0} onClick={() => {
                                    reset()
                                    setFormFields([])
                                }}>
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid>
            </Grid>
        </>
    );
};

export default CreateTicket


const FormFields = ({ formFields }) => {
    return (
        <>
            {formFields?.map((formElement, index) => (
                <React.Fragment key={`${formElement.subcategory}_${index}`}>
                    {formElement.type === "text" && (
                        <Grid item key={index} xs={12} md={6}>
                            <>
                                <RHFTextField
                                    label={formElement.isMandatory === "Y" ? `${formElement.title} *` : formElement.title}
                                    name={formElement.name}
                                    fullWidth
                                />
                            </>
                        </Grid>
                    )}
                    {formElement.type === "textarea" && (
                        <Grid item key={index} xs={12} md={12}>
                            <>
                                <RHFTextArea
                                    // label={formElement.title}
                                    label={formElement.isMandatory === "Y" ? `${formElement.title} *` : formElement.title}
                                    name={formElement.name}
                                    rows={4}
                                    fullWidth
                                />
                            </>
                        </Grid>
                    )}
                    {formElement.type === "file" && (
                        <Grid item key={index} xs={12} md={6}>
                            <RHFFileField
                                type="file"
                                label={formElement.isMandatory === "Y" ? `${formElement.title} *` : formElement.title}
                                name={`${formElement.name}_${index}`}
                            />
                        </Grid>
                    )}
                    {formElement.type === "radio" && (
                        <Grid item key={index} xs={12} md={6}>
                            <RHFRadioGroup
                                type="radio"
                                label={formElement.isMandatory === "Y" ? `${formElement.title} *` : formElement.title}
                                name={formElement.name}
                                options={formElement.options.map((val) => {
                                    return {
                                        label: val, value: val
                                    }
                                })}
                            />
                        </Grid>
                    )}
                </React.Fragment>
            ))
            }
        </>
    )
}
export { FormFields }
