import React, { useEffect, useState } from "react";
import { getMerchant } from '../../helpers/ReduxHelpers';
import { apiConstants, getGeneric, postGeneric } from '../../common/constants';
import { useForm } from "react-hook-form";
import { Button, Card, CardContent, FormControlLabel, FormGroup, Grid, Switch, Tooltip, Typography } from "@mui/material";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import Loader from "../../common/Loader"
import { useSnackbar } from "notistack";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import ConfirmDialog from "../../common/ConfirmDialog";

const MisConfig = () => {
    // const userToken = jwt_decode(getToken());
    // // const merchantId = (userToken && userToken.merchantOid) ? userToken.merchantOid : "";
    // const { merchant } = useSelector((state) => state.user.userInfo);
    // const merchantId =
    //     merchant?.id || (userToken && userToken.merchantOid ? userToken.merchantOid : "");

    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''


    const { enqueueSnackbar } = useSnackbar();
    const [isLoadingMIS, setLoadingMIS] = useState(false);
    const [isMISEnabled, setMISEnabled] = useState(false);
    const [isMonthlyMISEnabled, setMonthlyMISEnabled] = useState(false);
    const [merchantConfig, setMerchantConfig] = useState();
    const [reload, setReload] = useState(0);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [switchConfirm, setSwitchConfirm] = useState(false);
    const [monthlyswitchConfirm, setMonthlySwitchConfirm] = useState(false);

    const columns = [
        {
            field: 'emails',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Emails',
            flex: 1.2,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <div style={{ paddingTop: 15, paddingBottom: 15, fontSize: 15 }}>
                        {row?.email}
                    </div>
                )
            },
        },
        {
            field: 'action',
            headerAlign: "left",
            hideSortIcons: true,
            headerName: 'Action',
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Button
                        disabled={!isMISEnabled && !isMonthlyMISEnabled}
                        onClick={() => handleCancelOrder(row)}
                        color="error"
                        variant="contained"
                        size="small"
                    >
                        Delete
                    </Button>
                );
            },
        },
    ];
    const emailListRegExp = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}\s*,\s*)*[\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}$/

    const methodsMIS = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                emailMIS: Yup.string()
                    .matches(emailListRegExp, { message: 'Invalid email format', excludeEmptyString: true })
                    .required("Email is required!"),
            })
        ),
        defaultValues: {
            emailMIS: '',
        }
    })
    const { handleSubmit: handleSubmitFormMIS, reset } = methodsMIS


    const postMerchantConfig = (payload) => {
        postGeneric(`${apiConstants.UPDATE_MERCHANT_CONFIG}/${merchantId}/mis`, payload).then(response => {
            enqueueSnackbar('Success', { variant: 'success' })
            setReload(reload => reload + 1)
            reset()
            setLoadingMIS(false)
        })
            .catch((err) => {
                enqueueSnackbar('Some error occurred', { variant: 'error' })
                console.error(err)
                setLoadingMIS(false)
            })
    }

    const handleCancelOrder = (data) => {
        setDeleteData(data)
        setDeleteConfirm(true)
    }
    const handleDelete = () => {
        const index = merchantConfig?.misConfig?.recievedByEmail.indexOf(deleteData.email);
        if (index !== -1) {
            merchantConfig?.misConfig?.recievedByEmail.splice(index, 1);
        }
        const payload = {
            "dailyEnabled": true,
            "recievedByEmail": [...merchantConfig?.misConfig?.recievedByEmail],
            "recievedByBccEmail": ["ProshipTechTeam@prozo.com"]
        }
        postMerchantConfig(payload)
    }


    const onSubmitMIS = (data) => {
        setLoadingMIS(true);
        const existingEmails = merchantConfig?.misConfig?.recievedByEmail ?? [];
        const emailsMISArray = data.emailMIS.split(',').map(email => email.trim());
        const payload = {
            "dailyEnabled": true,
            "recievedByEmail": [...existingEmails, ...emailsMISArray],
            "recievedByBccEmail": ["ProshipTechTeam@prozo.com"]
        }
        postMerchantConfig(payload)
    }


    useEffect(() => {
        setLoadingMIS(true)
        getGeneric(`${apiConstants.UPDATE_MERCHANT_CONFIG}/${merchantId}`).then((res) => {
            setMerchantConfig(res.data);
            setLoadingMIS(false)
        }).catch((err) => {
            console.log(err)
            setLoadingMIS(false)
        })
    }, [merchantId, reload])

    const toggleDailyEmail = (e) => {
        const payload = {
            monthlyEnabled: merchantConfig?.misConfig?.monthlyEnabled,
            dailyEnabled: !merchantConfig?.misConfig?.dailyEnabled,
            recievedByEmail: merchantConfig?.misConfig?.recievedByEmail,
            recievedByBccEmail: ["ProshipTechTeam@prozo.com"]

        }
        postGeneric(`${apiConstants.UPDATE_MERCHANT_CONFIG}/${merchantId}/mis`, payload).then(response => {
            reset()
            setReload(reload => reload + 1)
            setLoadingMIS(false)
        })
            .catch((err) => {
                console.error(err)
                setLoadingMIS(false)
            })
    }
    const toggleMonthlyEmail = (e) => {
        const payload = {
            monthlyEnabled: !merchantConfig?.misConfig?.monthlyEnabled,
            dailyEnabled: merchantConfig?.misConfig?.dailyEnabled,
            recievedByEmail: merchantConfig?.misConfig?.recievedByEmail,
            recievedByBccEmail: ["ProshipTechTeam@prozo.com"]

        }
        postGeneric(`${apiConstants.UPDATE_MERCHANT_CONFIG}/${merchantId}/mis`, payload).then(response => {
            reset()
            setReload(reload => reload + 1)
            setLoadingMIS(false)
        })
            .catch((err) => {
                console.error(err)
                setLoadingMIS(false)
            })
    }

    useEffect(() => {
        setMISEnabled(merchantConfig?.misConfig?.dailyEnabled)
    }, [merchantConfig?.misConfig?.dailyEnabled])

    useEffect(() => {
        setMonthlyMISEnabled(merchantConfig?.misConfig?.monthlyEnabled)
    }, [merchantConfig?.misConfig?.monthlyEnabled])

    const handleSwitchConfirmation = (e) => {
        setSwitchConfirm(true);
    };
    const handleSwitchAction = () => {
        toggleDailyEmail()
    };
    const handleMonthlySwitchConfirmation = (e) => {
        setMonthlySwitchConfirm(true);
    };
    const handleMonthlySwitchAction = () => {
        toggleMonthlyEmail()
    };

    return (
        <>
            {(isLoadingMIS) && <Loader />}
            <ConfirmDialog
                title=''
                open={deleteConfirm}
                disableButtons={merchantConfig?.misConfig?.recievedByEmail?.length === 1}
                setOpen={setDeleteConfirm}
                onConfirm={() => handleDelete()}
            >
                {merchantConfig?.misConfig?.recievedByEmail?.length === 1 ? "Please disable via Toggle Switch" : "Are you sure you want to delete ?"}
            </ConfirmDialog>
            <ConfirmDialog
                title=''
                open={switchConfirm}
                setOpen={setSwitchConfirm}
                onConfirm={() => handleSwitchAction()}>
                Are you sure you want to change the email configuration?
            </ConfirmDialog>
            <ConfirmDialog
                title=''
                open={monthlyswitchConfirm}
                setOpen={setMonthlySwitchConfirm}
                onConfirm={() => handleMonthlySwitchAction()}>
                Are you sure you want to change the email configuration?
            </ConfirmDialog>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardContent>
                            <FormProvider methods={methodsMIS} onSubmit={handleSubmitFormMIS(onSubmitMIS)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <RHFTextField color="warning"
                                            name="emailMIS"
                                            label="Add Emails *"
                                            size="medium"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LoadingButton isLoading={isLoadingMIS}
                                            type="submit"
                                            variant="contained"
                                            color="info"
                                            size="small"
                                        >Add</LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Tooltip title={isMISEnabled ? "Disable Daily Email" : "Enable Daily Email"}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={isMISEnabled}
                                                            onChange={(e) => handleSwitchConfirmation(e)}
                                                        />} label={<Typography color='error' variant="subtitle2">{`Use toggle to ${isMISEnabled ? 'Disable' : 'Enable'} daily emails`}</Typography>}
                                                    labelPlacement="bottom"
                                                />
                                            </FormGroup>
                                        </Tooltip>
                                        <Tooltip title={isMonthlyMISEnabled ? "Disable Monthly Email" : "Enable Monthly Email"}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={isMonthlyMISEnabled}
                                                            onChange={(e) => handleMonthlySwitchConfirmation(e)}
                                                        />} label={<Typography color='error' variant="subtitle2">{`Use toggle to ${isMonthlyMISEnabled ? 'Disable' : 'Enable'} monthly emails`}</Typography>}
                                                    labelPlacement="bottom"
                                                />
                                            </FormGroup>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </CardContent>
                    </Card>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <ProzoDataGrid
                            columns={columns}
                            autoHeight={true}
                            rows={(merchantConfig && merchantConfig?.misConfig?.recievedByEmail?.length > 0)
                                ? merchantConfig?.misConfig?.recievedByEmail.map((email, index) => ({ id: index, email }))
                                : []
                            }
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            paginationMode='client'
                            pagination={true}
                            rowHeight={120}
                            rowCount={merchantConfig?.misConfig?.recievedByEmail?.length || 0}
                            getRowId={(row) => row.id}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MisConfig;
