import React, { useState, useEffect } from 'react';
import withLayout from '../../../hocs/Layout';
import { apiConstants, get } from '../../../common/constants';
import { getMerchant } from '../../../helpers/ReduxHelpers';
import Loader from '../../../common/Loader';
import './preview.css';
import { Link } from 'react-router-dom';
import Carousel from '../carousel';

const MerchantPreview = () => {
    const [loading, setLoading] = useState(false);
    const [merchant, setMerchant] = useState({});

    const merchantD = getMerchant();
    const merchantId = merchantD.id ? merchantD.id : ''

    const loadMerchant = () => {
        setLoading(true);
        get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT}/${merchantId}`).then(response => {
            setLoading(false);
            setMerchant(response.data);
        })
            .catch(err => { console.error(err); setLoading(false); });
    }
    useEffect(() => {
        loadMerchant();
    }, []);
    return (
        <>
            <div id="main-content" className="container-fluid">
                {loading &&
                    <Loader />
                }
                <div className="row">
                    <div id="page-title" className="col-12">
                        <Link to={"/"} className="page-title">
                            <img src="img/back.svg" width="21px" alt="" />
                            Company profile
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-7 col-lg-9">
                        <div className="card card-company-profile">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="card-title">Company details</h4>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-label">Company logo</div>
                                        <div className="company-logo d-flex justify-content-center align-items-center">
                                            {(merchant && merchant.logo) &&
                                                <img src={merchant.logo} alt="merchantLogo" width="90" />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md pt-4">
                                        <div className="row">
                                            <div className="col-12 col-lg-2 mb-4">
                                                <div className="form-label">Company Name</div>
                                                {(merchant && merchant.name) ? merchant.name : ""} </div>
                                            <div className="col-10 mb-4">
                                                <div className="form-label">Email address</div>
                                                {(merchant && merchant.email) ? merchant.email : ""} </div>
                                            <div className="col-12 mb-4">
                                                <div className="form-label">Phone number</div>
                                                +91 | {(merchant && merchant.contact_no) ? merchant.contact_no : ""} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="card-title m-0">Billing details</h4>
                                    </div>
                                    <div className="col pt-4">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg mb-4 mb-lg-0">
                                                <div className="form-label">Name</div>
                                                {(merchant && merchant.bank_details && merchant.bank_details.beneficiaryName) ? merchant.bank_details.beneficiaryName : ""}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg mb-4 mb-lg-0">
                                                <div className="form-label">Account number</div>
                                                {(merchant && merchant.bank_details && merchant.bank_details.accountNumber) ? merchant.bank_details.accountNumber : ""}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg mb-4 mb-lg-0">
                                                <div className="form-label">Bank name</div>
                                                {(merchant && merchant.bank_details && merchant.bank_details.bankName) ? merchant.bank_details.bankName : ""}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg mb-4 mb-lg-0">
                                                <div className="form-label">Account type</div>
                                                {(merchant && merchant.bank_details && merchant.bank_details.accountType) ? merchant.bank_details.accountType : ""}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg mb-4 mb-lg-0">
                                                <div className="form-label">IFSC code</div>
                                                {(merchant && merchant.bank_details && merchant.bank_details.ifscCode) ? merchant.bank_details.ifscCode : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex align-items-center download-template">
                                <div className="flex-shrink-0"> <img src="img/email.svg" height="54" alt="" /> </div>
                                <div className="flex-grow-1 ms-3"> Would you like to request for an update?<br />
                                    <a href="#" target="_blank">Request update</a> </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-3 order-tips mt-4 mt-md-0">
                        <div className="ad-space border">
                        <Carousel />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withLayout(MerchantPreview);