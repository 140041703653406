import React from 'react'
import { Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from '@mui/material'

import { Box } from '@mui/system'
import PercentIcon from '@mui/icons-material/Percent'
import LooksOneIcon from '@mui/icons-material/LooksOne'

import { allZonez, numberPrecision, transformChartDataToTable } from '../../../../helpers/UtilityHelper'
import MaterialTable from '../../../../common/MaterialTable'

export default function ZoneWiseSplit({ data }) {
  const [tableView, setTableView] = React.useState('percentage')

  const { rows, allColumns } = transformChartDataToTable(data, [
    ...allZonez.map((z) => {
      const key = tableView === 'percentage' ? `${z}_perc` : `${z}_count`
      return { key, label: z }
    }),
  ])

  const tabelColumns = [
    { label: 'Zone', value: 'name', align: 'left' },
    ...allColumns.map((col) => ({
      label: col,
      value: (row) => `${numberPrecision(row[col], 1)}${tableView === 'percentage' ? '%' : ''}`,
      align: 'right',
      numeric: true,
    })),
  ]

  return (
    <Card elevation={1}>
      <CardHeader
        title='Zone Wise Booked Shipments'
        action={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ToggleButtonGroup
              sx={{ ml: 1 }}
              color='primary'
              size='small'
              exclusive
              onChange={(e, v) => setTableView(v)}
              value={tableView}
            >
              <ToggleButton value='percentage' title='View Data In %'>
                <PercentIcon />
              </ToggleButton>
              <ToggleButton value='number' title='View Data In Count'>
                <LooksOneIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        }
      />
      <CardContent>
        <MaterialTable
          size='small'
          fileName='ZoneWiseBookedShipments'
          addBorders
          rows={rows}
          columns={tabelColumns}
          frozenColumnsCount={1}
          exportExcel={false}
        />
      </CardContent>
    </Card>
  )
}
