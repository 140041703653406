import React, { useEffect } from "react";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import { get, apiConstants } from "../../../common/constants";
import "./onboarding.css";
import { FormControl, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  FormProvider,
  RHFTextField,
} from "../../../common/hook-form";
import { LoadingButton } from "@mui/lab";

export default function MerchantDetails() {
  const method = useForm({
    defaultValues: {
      companyName: "",
      companyEmail: "",
      companyContact: "",
    },
  });
  const { handleSubmit: handleSubmit1, setValue } = method;

  const merchant = getMerchant();
  const merchantId = merchant.id ? merchant.id : ''


  useEffect(() => {
    get(`${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT}/${merchantId}`)
      .then((response) => {
        setValue("companyName", response.data?.name);
        setValue("companyEmail", response.data?.email);
        setValue("companyContact", response.data?.contact_no);
        setValue("companyType", response.data?.name);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [merchantId]);

  const onSubmit = () => {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl component="fieldset" sx={{ width: 1, p: 2 }}>
          <FormProvider methods={method} onSubmit={handleSubmit1(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="companyName"
                  variant="filled"
                  label="Company Name"
                  disabled
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <RHFSelectField
                  options={[
                    { label: "Limited Company", value: "Limited_Company" },
                    { label: "Limited Liablity Partership", value: "LimitedLiablityPartership" },
                    { label: "Patnership Firm", value: "PatnershipFirm" },
                    { label: "Sole Proprietorship", value: "SoleProprietorship" },
                  ]}
                  name="companyType"
                  width="100%"
                  variant="filled"
                  label="Company Type"
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="companyEmail"
                  variant="filled"
                  label="Email Address"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="companyContact"
                  variant="filled"
                  label="Contact No"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton variant="contained" size="medium" disabled>
                  Update details
                </LoadingButton>
              </Grid>
            </Grid>
          </FormProvider>
        </FormControl>
      </Grid>
    </Grid>
  );
}
