import React, { useEffect, useState } from 'react';
import { apiConstants, get } from '../../common/constants';
import { convertToCSV, downloadCSV, formatDate, parseDateAndTime } from '../../helpers/UtilityHelper';
// import { CSVLink } from "react-csv";
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Chip, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Loader from '../../common/Loader';

const headers = [
    { label: "AWB Number", key: "awbNumber" },
    { label: "Credit Amount", key: "credit" },
    { label: 'Debit Amount', key: 'debit' },
    { label: 'Description', key: 'description' },
    { label: 'Ref. Number', key: 'orderReferenceNumber' },
    { label: 'Trans. Time', key: 'date' }
];

const OrderTransaction = (props) => {
    const { merchantId, walletDetails, dateRange } = props;
    const [walletHistory, setWalletHistory] = useState([]);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(40);
    const [btnLoading, setBtnLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    

    const loadOrderTransaction = () => {
        if (merchantId && walletDetails && walletDetails.walletType) {
            // setLoading(true);
            setDataLoading(true);
            get(
                `${apiConstants.BASE_LOCAL}${apiConstants.ORDER_TRANSACTION}?from_date=${formatDate(dateRange[0].startDate, "yyyy-MM-dd")}&to_date=${formatDate(dateRange[0].endDate, "yyyy-MM-dd")}&merchantId=${merchantId}&limit=${sizePerPage}&offset=${page * sizePerPage}`
            ).then(response => {
                // setLoading(false);
                setDataLoading(false);
                setWalletHistory(response.data);
                // setRowCountState(response.data.length);
            }).catch(err => { console.error(err); setDataLoading(false); });
        }
    }

    useEffect(() => {
        loadOrderTransaction();

        // eslint-disable-next-line
    }, [dateRange, page, sizePerPage]);

    const columns = [
        {
            field: "awbNumber",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1,
            headerName: "AWB Number",
            width: 160,
        },
        {
            field: "orderReferenceNumber",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1,
            headerName: "Reference Number",
        },
        {
            field: "transactionType",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1,
            headerName: "Transaction Type",
            renderCell: (params) => {
                const { row } = params;
                const transactionType = row.credit && row.credit > 0 ? 'Credit' : 'Debit';
                const color = row.credit && row.credit > 0 ? "success" : "error";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={transactionType} />
                            </Grid>
                        </Grid>
                    </>
                );
            },
        },
        {
            field: "transactionAmount",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1,
            headerName: "Transaction Amount",
            renderCell: (params) => {
                const { row } = params;
                const transactionAmount = row.credit && row.credit > 0 ? row.credit : row.debit;
                return 'Rs.' + transactionAmount;
            },
        },
        {
            field: "description",
            headerAlign: "center",
            hideSortIcons: true,
            flex: 1.5,
            headerName: "Description",
        },
        {
            field: "date",
            headerAlign: "center",
            hideSortIcons: true,
            headerName: "Date",
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return parseDateAndTime(row.date);
            },
        },

    ]

    const downloadTransactionFile = () => {
        setBtnLoading(true);
        get(
            `${apiConstants.BASE_LOCAL}${apiConstants.ORDER_TRANSACTION}?from_date=${formatDate(dateRange[0].startDate, "yyyy-MM-dd")}&to_date=${formatDate(dateRange[0].endDate, "yyyy-MM-dd")}&limit=100000`
        ).then(response => {
            setBtnLoading(false);
            const csvData = convertToCSV((response.data && response.data.length > 0) ? response.data : [], headers);
            const filename = 'order-transaction.csv';
            downloadCSV(csvData, filename);
        }).catch(err => {
            console.error(err);
            setBtnLoading(false);
        });
    }

    return (
        <Grid item container spacing={2}>
            {(dataLoading) && <Loader />}
            <Grid item xs={12}>
                {/* <CSVLink
                    data={(walletHistory && walletHistory.length > 0) ? walletHistory : []}
                    headers={headers}
                    className="d-none d-md-inline-block"
                    filename={"order-transaction.csv"}
                >
                    Download
                </CSVLink> */}
                <Typography component='span' sx={{ ml: 1 }} color='warning'>
                    <LoadingButton loading={btnLoading} onClick={() => { downloadTransactionFile() }} variant="contained" color="warning">Download</LoadingButton>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {/* {walletHistory.map((item, index) => */}
                <ProzoDataGrid columns={columns}
                    autoHeight={true}
                    rows={walletHistory.map((row, index) => ({ ...row, id: index }))}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    setPage={setPage}
                    page={page}
                    pagination={true}
                    hideFooterPagination={false}
                    // filterMode={"server"}
                    // paginationMode={'client'}
                    rowHeight={180}
                    // rowCount={rowCountState || 0}
                    rowCount={Number.MAX_VALUE}
                    getRowId={(row) => row.id}
                />
                {/* )} */}
            </Grid>
        </Grid >
    );
}

export default OrderTransaction;