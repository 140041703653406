import React from "react"
import _ from 'lodash'
import { courierOrMerchantWiseSplit, formatDate, getOrderStatus, getYYYYMMDD, numberPrecision } from "../../../../helpers/UtilityHelper"
import { addDays, addMonths, addWeeks, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isWithinInterval } from "date-fns"
import useGetData from "./useGetData"
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector"
import { useGetAllCourierPartnersQuery } from "../../../../redux/commonRTK"

const weekStartsOn = 1

export default function useRVP(groupOn) {
    const [isCalc, setIsCalc] = React.useState(true)
    const { from_date, to_date } = useGetSelectedDateRange()
    const { allPickupShipments, allPendingPickupShipments, allDeliveredShipments, allInTransitShipments, finalDataWithAllAdditionalData, isLoading } = useGetData();

    const andfilter = [{ "field": "is_deleted", "operator": "eq", "value": '0' }];
    const requestPayload = { andfilter, offset: 0, limit: 500, includes: "id,name,parent,prozoId" };

    const { data: allCouriers, isLoading: isLoadingCouriers, isFetching: isFetchingCouriers } = useGetAllCourierPartnersQuery(requestPayload);

    const result = React.useMemo(() => {

        if (isLoading) {
            setIsCalc(true)
            return;
        }

        let intervalArr = [],
            courierWiseDeliveryAttempt = [],
            courierWiseDelivered = [],
            uniqCourierIds = _(finalDataWithAllAdditionalData).uniqBy('courier').map('courier').value(),
            uniqMerchantIds = _(finalDataWithAllAdditionalData).uniqBy('merchant').map('merchant').value(),
            uniqDeliveryCourierIds = _(allDeliveredShipments).uniqBy('courier').map('courier').value(),
            uniqDeliveryMerchantIds = _(allDeliveredShipments).uniqBy('merchant').map('merchant').value(),
            deliveryOutTat = [],
            chartData = [],
            courierOrMerchantWiseDelivered = [];

        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1)
            intervalArr = eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1)
            intervalArr = eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1)
            intervalArr = eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        }

        const allRegisteredDataGroupedByDate = _.groupBy(finalDataWithAllAdditionalData, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })

        const allRegisteredDataGroupedByDateDeliverd = _.groupBy(allDeliveredShipments, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })


        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index]
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : start
            const row = { name: null }

            if (groupOn === 'DOD') {
                row.name = formatDate(start, 'dd MMM')
            } else if (groupOn === 'WOW') {
                row.name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`
            } else {
                row.name = formatDate(start, 'MMM')
            }

            const allRegisteredDatesBetween = _.filter(Object.keys(allRegisteredDataGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })

            const allRegisteredDataBetweenDate = allRegisteredDatesBetween.map(key => allRegisteredDataGroupedByDate[key]).flat();
            const allRegisteredDataBetweenDateDelivered = allRegisteredDatesBetween.map(key => allRegisteredDataGroupedByDateDeliverd[key]).flat();
            row.percentage = Number(numberPrecision((allRegisteredDataBetweenDate.length / finalDataWithAllAdditionalData.length) * 100));

            _(allRegisteredDataBetweenDate)
                .groupBy((shipment) => getAgeingKey(shipment.pickupAttemptCount))
                .forEach(function (items, key) {
                    const count = items.length
                    row[`Pickup_${key}_count`] = count
                    row[`Pickup_${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDate.length) * 100))
                })

            // Group By pickup ageing
            _(allRegisteredDataBetweenDate)
                .groupBy((shipment) => getAgeingKey(shipment.diffInDaysPickup))
                .forEach(function (items, key) {
                    const count = items.length
                    row[`Ageing_${key}_count`] = count
                    row[`Ageing_${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDate.length) * 100))
                })

            // Group By delivery ageing
            _(allRegisteredDataBetweenDate)
                .groupBy((shipment) => getAgeingKey(shipment.diffInDaysDelivery))
                .forEach(function (items, key) {
                    const count = items.length
                    row[`Delivery_${key}_count`] = count
                    row[`Delivery_${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDate.length) * 100))
                })

            // Group by status
            _(allRegisteredDataBetweenDate)
                .groupBy(function (item) {
                    return getOrderStatus(item.orderStatus)
                }).forEach(function (items, key) {
                    row[key] = items.length
                });

            _(allRegisteredDataBetweenDate)
                .groupBy('merchantZone')
                .forEach(function (items, key) {

                    const count = items.length
                    row[`${key}_count`] = count;
                    row[`${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDate.length) * 100))
                });

            // Group By Courier OR Merchant
            const groupByKey = 'courier';
            _(allRegisteredDataBetweenDate)
                .groupBy(groupByKey).forEach(function (items, key) {
                    const count = items.length
                    row[`${key}_count`] = count;
                    row[`${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDate.length) * 100))
                })

            _(allRegisteredDataBetweenDateDelivered)
                .groupBy(groupByKey).forEach(function (items, key) {
                    const count = items.length
                    row[`delivery_${key}_count`] = count;
                    row[`delivery_${key}_perc`] = Number(numberPrecision((count / allRegisteredDataBetweenDateDelivered.length) * 100))
                })

            const delivered = courierOrMerchantWiseSplit(allRegisteredDataBetweenDate, uniqDeliveryCourierIds, 'isBreached')
            courierOrMerchantWiseDelivered.push({ name: row.name, ...delivered });

            chartData.push(row)
        }

        const groupByKey = 'courier';
        const findNameFrom = allCouriers;
        const courierWiseAgeingPickup = _(finalDataWithAllAdditionalData)
            .groupBy(groupByKey)
            .map(function (items, key) {
                const row = { name: key, ...groupByKeyObj(items, 'diffInDaysPickup', items.length) };
                return row;
            }).value()

        const courierWiseAgeingDelivery = _(finalDataWithAllAdditionalData)
            .groupBy(groupByKey)
            .map(function (items, key) {
                const row = { name: key, ...groupByKeyObj(items, 'diffInDaysDelivery', items.length) };
                return row;
            }).value()

        const courierWisePickupAttemptAgeing = _(finalDataWithAllAdditionalData)
            .groupBy(groupByKey)
            .map(function (items, key) {
                const courierOrMerchant = _.find(findNameFrom, { id: key })
                let name = "NA"
                if (courierOrMerchant) {
                    name = `${courierOrMerchant.parent || courierOrMerchant.name}/${courierOrMerchant.id}`
                }
                const row = { name, ...groupByKeyObj(items, 'pickupAttemptCount', items.length) };
                return row;
            }).value()


        const totalDelivered = allDeliveredShipments?.length || 0;
        let cumulativePerc = 0;

        const groupedByInOutTatData = _(allDeliveredShipments)
            .groupBy(function (item) {
                if (item.firstAttemptWithinSLA) {
                    return 'Within TAT'
                }

                if (item.deliveryBreachedAgeingDays <= 12) {
                    return `${item.deliveryBreachedAgeingDays}`
                }

                return '12+';

            }).map(function (items, name) {
                return { items, name };
            }).orderBy(function (i) {
                return i.name === 'Within TAT' ? 0 : parseInt(i.name.replace(/\D+/g, ""))
            }).map(function (item) {

                const count = item.items?.length || [];
                const perc = count / totalDelivered * 100

                cumulativePerc += perc;

                return { name: item.name, count, perc, cumulative: cumulativePerc };

            }).value();

        const results = {
            courierWiseAgeingDelivery,
            courierWiseAgeingPickup,
            chartData,
            deliveryOutTat,
            courierWiseDeliveryAttempt,
            uniqCourierIds,
            courierWiseDelivered,
            uniqMerchantIds,
            uniqDeliveryCourierIds,
            uniqDeliveryMerchantIds,
            courierWisePickupAttemptAgeing,
            courierOrMerchantWiseDelivered,
            groupedByInOutTatData
        };

        setIsCalc(false)
        return results;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalDataWithAllAdditionalData?.length, from_date, to_date, isLoading, isLoadingCouriers, isFetchingCouriers, groupOn])

    if (isCalc) return { isLoading: isCalc }

    if (result) {
        result.totalRVPOrders = finalDataWithAllAdditionalData?.length || 0
        result.pickedUpRVP = allPickupShipments?.length || 0
        result.pendingPickup = allPendingPickupShipments?.length || 0
        result.delivered = allDeliveredShipments?.length || 0
        result.intransit = allInTransitShipments?.length || 0

        result.rvpDeliveredPercentage = Math.round((result.delivered / result.pickedUpRVP) * 100)
        result.rvpPickupPercentage = Math.round((result.pickedUpRVP / result.totalRVPOrders) * 100)
        result.rvpPendingPickupPercentage = Math.round((result.pendingPickup / result.totalRVPOrders) * 100)
        result.rawData = finalDataWithAllAdditionalData;
    }

    return { data: result, isLoading: isCalc, allPendingPickupShipments, allPickupShipments };
}

function groupByKeyObj(items, key, totalRvpOrders) {

    let row = {};
    const groupedData = _.groupBy(items, (ship) => getAgeingKey(ship[key]));
    for (const key of Object.keys(groupedData)) {
        const count = groupedData[key].length;
        row[`${key}_count`] = count;
        row[`${key}_perc`] = Number(numberPrecision((count / totalRvpOrders) * 100));
    }

    return row;
}

function getAgeingKey(diffInDaysPickup) {
    if (diffInDaysPickup >= 0 && diffInDaysPickup <= 1) {
        return '0-1 Day'
    } else if (diffInDaysPickup <= 3) {
        return '2-3 Days'
    } else if (diffInDaysPickup <= 7) {
        return '4-7 Days'
    } else if (diffInDaysPickup <= 15) {
        return '5-15 Days'
    }

    return '15+ Days'
}