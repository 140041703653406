import React, { useState } from "react";
import { Box, Button, Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../../common/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup"
import { apiConstants, post, postGeneric } from "../../../common/constants";
import { useNavigate } from "react-router-dom";
import CustomerDetails from "./CustomerDetails"
import PickupLocation from "./PickupLocation"
import OrderDetails from "./OrderDetails";
import PackageDetails from "./PackageDetails";
import { useGetMerchantQuery } from "../../../redux/commonRTK";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import PageTitle from "../../../common/PageTitle";
import { useSnackbar } from "notistack";
import SelectCourier from "./Courier";
import { exclude } from "../list2";
import Loader from "../../../common/Loader";

const steps = [
    "Pickup Details",
    "Drop Details",
    "Order Details",
    "Package Details",
    "Select Courier",
];

function CreateB2COrderRVP() {
    const merchant = getMerchant();
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();
    const [creatingOrder, setIsCreatingOrder] = React.useState(false);
    const { data } = useGetMerchantQuery({ id: merchant.id });
    const [response, setResponse] = useState()
    const [awbLoading, setAwbLoading] = useState(false)
    const pickupLocations = data?.pickup_details || [];
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        defaultValues: {
            reference: '',
            payment_mode: 'PREPAID',
            order_type: 'Reverse Shipment',
            pickup_details: {
                from_phone_number: '',
                from_name: '',
                from_email: '',
                from_pincode: '',
                from_city: '',
                from_state: '',
                from_address: '',
            },
            client_order_id: '',
            invoice_value: '',
            // invoice_number: '',
            shipping_charge: '',
            transaction_charge: '',
            giftwrap_charge: '',
            shipment_detail: [{
                item_weight: '',
                item_length: '',
                item_breadth: '',
                item_height: '',
            }],
            courier_type: 'Prozo Choice'
        },
        resolver: yupResolver(
            Yup.object().shape({
                pickup_details: Yup.object().shape({
                    from_phone_number: Yup.string()
                        .required("Please enter your phone number.")
                        .matches(/^\d{10}$/, "Your phone number must be exactly 10 digits long."),
                    from_name: Yup.string().required("Please enter your name."),
                    from_email: Yup.string()
                        .email("Please enter a valid email address.")
                        .required("Please enter your email address."),
                    from_pincode: Yup.string()
                        .required("Please enter your pincode.")
                        .matches(/^\d{6}$/, "Pincode must be exactly 6 digits long."),
                    from_city: Yup.string().required("Please enter your city."),
                    from_state: Yup.string().required("Please enter your state."),
                    from_address: Yup.string().required("Please enter your address."),
                }),
                item_list: Yup.array().of(
                    Yup.object().shape({
                        item_name: Yup.string().required("Please enter the item name."),
                        units: Yup.number()
                            .min(1, "Quantity must be at least 1.")
                            .required("Please enter the quantity."),
                        sku_id: Yup.string().required("Please enter the SKU ID."),
                        selling_price: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Selling price must be at least Rs. 1.")
                            .required("Please enter the selling price."),
                        discount: Yup.number().optional(),
                        tax: Yup.number().optional(),
                        hsn: Yup.string().optional(),
                    })
                ),
                cod_amount: Yup.number().when('payment_mode', {
                    is: val => val === 'COD',
                    then: Yup.number()
                        .transform((value, originalValue) => {
                            const stringValue = String(originalValue).replace(/[\s]+/g, ''); // Remove spaces
                            return stringValue.match(/^[0-9]*$/) ? Number(stringValue) : null;
                        })
                        .typeError("COD amount must be a numeric value.") // Handling type error for non-numeric input
                        .required("COD amount is required when payment mode is COD.")
                        .min(1, "COD amount must be at least Rs. 1.")
                        .max(Yup.ref('invoice_value'), "COD amount cannot be greater than the invoice value.")
                }),
                rvp_reason: Yup.string().when('order_type', {
                    is: val => {
                        return val === 'Reverse Shipment';
                    },
                    then: Yup.string().required("RVP reason is mandatory for Reverse Shipment."),
                }),
                payment_mode: Yup.string().required("Please select a payment mode."),
                reference: Yup.string().required("Please enter a reference number."),
                // client_order_id: Yup.string().required("Please enter the client order ID."),
                pickupLocation: Yup.string().required("Please enter the pickup location."),
                invoice_value: Yup.number()
                    .transform((value, originalValue) => {
                        let stringValue = originalValue != null ? String(originalValue).trim() : "";
                        return stringValue === "" ? null : value;
                    })
                    .nullable()
                    .min(1, "Invoice amount must be at least Rs. 1.")
                    .required("Please enter the invoice value."),
                // invoice_number: Yup.string().optional(),
                shipping_charge: Yup.string().optional(),
                transaction_charge: Yup.string().optional(),
                giftwrap_charge: Yup.string().optional(),
                shipment_detail: Yup.array().of(
                    Yup.object().shape({
                        item_weight: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Weight must be at least 1 gram.")
                            .required("Weight is required."),
                        item_length: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Length must be at least 1 cm.")
                            .required("Length is required."),
                        item_breadth: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Breadth must be at least 1 cm.")
                            .required("Breadth is required."),
                        item_height: Yup.number()
                            .transform((value, originalValue) => {
                                let stringValue = originalValue != null ? String(originalValue).trim() : "";
                                return stringValue === "" ? null : value;
                            })
                            .nullable()
                            .min(1, "Height must be at least 1 cm.")
                            .required("Height is required.")
                    })
                ).required("Please provide shipment details.")
            })
        )
    });
    const { handleSubmit, reset } = methods;
    React.useEffect(() => {
        if (response && response?.delivery_details) {
            methods.setValue('pickup_details.from_phone_number', response?.delivery_details.to_phone_number || '');
            methods.setValue('pickup_details.from_name', response?.delivery_details.to_name || '');
            methods.setValue('pickup_details.from_email', response?.delivery_details.to_email || '');
            methods.setValue('pickup_details.from_pincode', response?.delivery_details.to_pincode || '');
            methods.setValue('pickup_details.from_city', response?.delivery_details.to_city || '');
            methods.setValue('pickup_details.from_state', response?.delivery_details.to_state || '');
            methods.setValue('pickup_details.from_address', response?.delivery_details.to_address || '');

            methods.setValue('shipment_detail[0].item_weight', response?.shipment_detail[0]?.item_weight);
            methods.setValue('shipment_detail[0].item_length', response?.shipment_detail[0]?.item_length);
            methods.setValue('shipment_detail[0].item_breadth', response?.shipment_detail[0]?.item_breadth);
            methods.setValue('shipment_detail[0].item_height', response?.shipment_detail[0]?.item_height);
        } else {
            reset()
        }
    }, [response, methods]);



    const onSubmit = (formData) => {
        setIsCreatingOrder(true);
        let request = { ...formData, dispatch_mode: null, merchantOId: merchant.id };

        const selectedAddress = pickupLocations.find(loc => loc.id === request.pickupLocation);
        const delivery_details = {
            to_address: selectedAddress.address_line,
            to_city: selectedAddress.city,
            to_country: selectedAddress.country,
            to_name: selectedAddress.name,
            to_pincode: selectedAddress.pincode,
            to_state: selectedAddress.state,
            to_phone_number: selectedAddress.contactNumber,
            to_email: selectedAddress.email,
            gstin: selectedAddress.gstin
        }

        if (request.payment_mode === "PREPAID") {
            request.cod_amount = 0;
        }
        request.skipRule = request.courier_type === "Other" ? true : false;
        if (request.courier_type !== "Other") {
            delete request.courierPartner;
        } else {
            request.courier_id = {
                prozoId: request.courierPartner
            };
            delete request.courierPartner;
        }

        request = { ...request, delivery_details };
        request.pickup_details.from_country = 'IN';
        request.is_reverse = true;
        postGeneric(apiConstants.CREATE_ORDER, request)
            .then((response) => {
                if (response?.data?.meta?.message?.includes("Order already placed with reference number")) {
                    enqueueSnackbar("Reference number already exists", { variant: 'error' });
                } else if (response?.data?.meta?.message?.includes("Order created with reference number")) {
                    enqueueSnackbar("Successfully Created!", { variant: 'success' });
                    if (request.order_type === "Forward Shipment") {
                        navigate("/orders");
                    } else {
                        navigate("/reverse-orders");
                    }
                }
            }).catch((err) => {
                const message = err?.response?.data?.message || err.message;
                enqueueSnackbar(message || "Error creating order", { variant: "error" });
            }).finally(() => {
                setIsCreatingOrder(false)
            })
    }

    return (
        <div id="main-content" className="container-fluid">
            {awbLoading && <Loader />}
            <OrderSearchAWB setResponse={setResponse} setAwbLoading={setAwbLoading} />
            <PageTitle>Create Reverse Shipment </PageTitle>
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={2}>
                    <Stepper orientation="vertical" activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Box sx={{ p: 5 }} component={Paper} elevation={1}>
                        <Typography sx={{ mb: 3 }} variant="h6" >{activeStep + 1}. {steps[activeStep]}</Typography>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            {(activeStep === 0) && (
                                <CustomerDetails setActiveStep={setActiveStep} details={response?.pickup_details} />
                            )}
                            {(activeStep === 1) && (
                                <PickupLocation setActiveStep={setActiveStep} response={response} />
                            )}
                            {(activeStep === 2) && (
                                <OrderDetails setActiveStep={setActiveStep} response={response} reset={reset} />
                            )}
                            {(activeStep === 3) && (
                                <PackageDetails creatingOrder={creatingOrder} setActiveStep={setActiveStep} />
                            )}
                            {(activeStep === 4) && (
                                <SelectCourier creatingOrder={creatingOrder}
                                    setActiveStep={setActiveStep}
                                    formData={methods.getValues()}
                                    pickupLocations={pickupLocations}
                                    merchantId={merchant.id}
                                />
                            )}
                        </FormProvider>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateB2COrderRVP;

function OrderSearchAWB({ setResponse, setAwbLoading }) {
    const excludes = exclude;
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                awb: Yup.string().required("AWB Number is required")
            })
        ),
        defaultValues: {
            awb: '',
        },

    });
    const { handleSubmit } = methods;

    const onSubmit = (data) => {
        setAwbLoading(true)
        post(`${apiConstants.BASE_LOCAL}${apiConstants.ORDER_LISTS}`, {
            excludes,
            andfilter: [
                {
                    field: 'awbNumber',
                    operator: 'eq',
                    value: data.awb,
                },
            ],
        }).then((response) => {
            setAwbLoading(false)
            if (response?.data?.count > 0 && response?.data?.data[0]?.orderStatus === "DELIVERED" && response?.data?.data[0]?.order_type !== "REVERSE SHIPMENT") {
                setResponse(response?.data?.data[0])
            }
            else if (response?.data?.count > 0 && response?.data?.data[0]?.orderStatus !== "DELIVERED" && response?.data?.data[0]?.order_type !== "REVERSE SHIPMENT") {
                setResponse([])
                enqueueSnackbar("Order status is not DELIVERED", { variant: 'error' });
            }
            else if (response?.data?.count > 0 && response?.data?.data[0]?.order_type === "REVERSE SHIPMENT") {
                setResponse([])
                enqueueSnackbar("Order is already of type Reverse", { variant: 'error' });
            }
            else {
                setResponse([])
                enqueueSnackbar("Invalid AWB", { variant: 'error' });
            }
        }).catch((error) => {
            setAwbLoading(false)
            enqueueSnackbar("Some error occurred", { variant: 'error' });
            console.error(error);
            return null;
        })
    }

    return (
        // <div id="main-content" className="container-fluid">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
            <Typography sx={{ mb: 1 }} variant="subtitle1" >Enter AWB to get order details</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} sx={{ mb: 3 }}>
                    <RHFTextField
                        variant="filled"
                        name="awb"
                        label="AWB"
                    />
                </Grid>
                <Grid item xs={12} md={4} sx={{ mt: 1 }}>
                    <Button variant="contained" size="small" type="submit">Search</Button>
                </Grid>
            </Grid>
        </FormProvider>
        // </div>
    )
}
