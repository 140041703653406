/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./actions/userAction";

// initialize userToken from local storage
/* const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null */

const initialState = {
  loading: false,
  userInfo: {}, // for user object
  userToken: null, // for storing the JWT
  error: null,
  success: false, // for monitoring the login process.
  selectedUserId: null, // new field for selected user
  loginUserData: {}, // new field for selected user
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken"); // deletes token from storage
      localStorage.removeItem("proPilotToken"); // deletes token from storage
      state.loading = false;
      state.userInfo = {};
      state.userToken = null;
      state.error = null;
      state.selectedUserId = null;
      state.loginUserData = {};
    },
    switchAccount: (state) => {
      state.userInfo = {};
      state.selectedUserId = null;
      
    },
    loginUserDataSlice: (state, { payload }) => {
      state.loginUserData = payload;
    },
    login: (state, { payload }) => {
      state.userInfo = payload;
    },
    setSelectedUserId: (state, { payload }) => {
      state.selectedUserId = payload;
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.userToken;
      // state.selectedUserId = payload.selectedUserId || null; // set selectedUserId from payload if present
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logout, login, setSelectedUserId, switchAccount, loginUserDataSlice } = userSlice.actions;

export default userSlice.reducer;
