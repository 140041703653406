import { Button, Grid, Typography } from "@mui/material";
import { useGetMerchantQuery } from "../../redux/commonRTK";
import { getMerchant } from "../../helpers/ReduxHelpers";
import { FormSkeleton } from "../../common/Skeletons";
import { RHFSearchableSelectField } from "../../common/hook-form";
import _ from "lodash";
import { addressString, filterByProp } from "../../helpers/UtilityHelper";
import React from "react";
import { useFormContext } from "react-hook-form";

function PickupLocation({ setActiveStep, useIdAsKey }) {
    const merchant = getMerchant();
    const { trigger } = useFormContext()

    const { data, isLoading, isFetching } = useGetMerchantQuery({ id: merchant.id })

    // const pickupLocations = data?.pickup_details || [];
    const pickupLocations = filterByProp(data?.pickup_details, "is_deleted") || [];
    console.log(pickupLocations);

    const pickupLocationOptions = _(pickupLocations)
        .filter({ status: 'ACTIVE', address_type: 'PICKUP' })
        .map((loc) => ({
            value: useIdAsKey ? loc.id : loc.name,
            label: addressString(loc)
        })).value()

    if (isLoading || isFetching) {
        return <FormSkeleton />
    }

    const onNextHandler = async () => {
        const status = await trigger(['warehouse_name']);
        if (status) {
            setActiveStep(1)
        }
    }

    const onKeyPressHandler = (e) => {
        if (e.charCode === 13) {
            onNextHandler();
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ mb: 3 }}>
                <RHFSearchableSelectField
                    options={pickupLocationOptions}
                    width="100%"
                    label="Please Select Pickup Location *"
                    name={useIdAsKey ? "pickupLocation" : "warehouse_name"} 
                    onKeyPress={onKeyPressHandler}
                    autoFocus={true}
                />
                <Typography variant="body2">
                    If Pickup Location is not present then create a new
                    <Button sx={{ py: 0, textTransform: 'none' }} target="_blank" href="/merchant-step2">
                        Pickup Location
                    </Button>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" size="small" onClick={onNextHandler}>Next</Button>
            </Grid>
        </Grid>
    )
}

export default PickupLocation;