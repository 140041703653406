import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

RHFDateField.propTypes = {
  name: PropTypes.string,
};

export default function RHFDateField({ name, size = 'small', ...other }) {
  const { control, watch } = useFormContext();
  const fieldValue = watch(name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            {...field}
            inputFormat="dd/MM/yyyy"
            value={fieldValue === undefined || fieldValue === null || (typeof fieldValue === 'number' && fieldValue === 0) ? null : fieldValue}
            renderInput={(params) => <TextField
              {...params}
              error={!!error}
              fullWidth
              size={size}
              variant="filled"
              helperText={error?.message}
            />}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}
