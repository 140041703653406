import React, { useEffect, useState } from "react";
import withLayout from "../../../hocs/Layout";
import "./orderList.css";
import "react-toastify/dist/ReactToastify.css";
import { apiConstants, postGeneric } from "../../../common/constants";
import { Button, Grid, IconButton, Tooltip, Typography, Paper, Chip } from "@mui/material";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import { useForm } from "react-hook-form";
import MUIModal from "../../../common/MUIModal";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UploadIcon from "@mui/icons-material/Upload";
import Loader from "../../../common/Loader";
import UpdateStatus from "./UpdateStatus";
import UploadImage from "./UploadImage";
import StatusLog from "./StatusLog";
import _ from 'lodash';
import { useSnackbar } from "notistack";
import { convertDate, formatCurrency, formatDate } from "../../../helpers/UtilityHelper";
import { Link } from "react-router-dom";
import { FormProvider, RHFDateRangePickerField, RHFSelectField, RHFTextField } from "../../../common/hook-form";
// import { DownloadExcelMultipleSheets } from "../../../helpers/ExcelGenerator";
import { useGetCouriersQuery } from "../../../redux/commonRTKPersist";
import PageTitle from "../../../common/PageTitle";
import { useNavigate } from "react-router-dom";

const WeightDiscrepancy = () => {
    const methods = useForm({
        defaultValues: {
            status: "",
            merchant: "",
            awb: "",
        }
    })
    const { handleSubmit, reset } = methods
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''


    const { enqueueSnackbar } = useSnackbar();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [awb, setAwb] = useState();
    const [Status, setStatus] = useState();
    const [dispute, setDispute] = useState(false);
    const [agree, setAgree] = useState(false);
    const [log, setLog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const [filters, setFilters] = useState({});
    const [count, setCount] = useState({})
    const status = [{ label: "New", value: 'NEW' }, { label: "DISPUTED BY COURIER", value: 'DISPUTED_BY_COURIER' }, { label: "DISPUTED BY MERCHANT", value: 'DISPUTED_BY_MERCHANT' }, { label: "ACCEPTED BY COURIER", value: 'ACCEPTED_BY_COURIER' }, { label: "ACCEPTED BY MERCHANT", value: 'ACCEPTED_BY_MERCHANT' }, { label: "AUTO ACCEPTED BY SYSTEM", value: 'AUTO_ACCEPTED_BY_SYSTEM' }]
    const { data: allCouriers } = useGetCouriersQuery();
    const navigate = useNavigate()

    const onSubmit = (data) => {
        setFilters({
            statusIn: data.status,
            awbNumber: data.awb,
            createdDateLT: data.toDate ? convertDate(data.toDate) : "",
            createdDateGT: data.fromDate ? convertDate(data.fromDate) : ""
        })
        setCount({
            merchantId: data.merchant,
            statusIn: data.status,
            awbNumber: data.awb,
            createdDateLT: data.toDate ? convertDate(data.toDate) : "",
            createdDateGT: data.fromDate ? convertDate(data.fromDate) : ""
        })
    }

    useEffect(() => {
        setLoading(true);
        filters.merchantId = merchantId;
        filters.skip = (page) * sizePerPage;
        filters.limit = sizePerPage;
        postGeneric(apiConstants.GET_WEIGHT_DICREPENACY, filters)
            .then((res) => {
                setLoading(false);
                setList(res?.data?.responseObj);
            })
            .catch((err) => {
                setLoading(false);
                console.log("err", err);
            });
    }, [filters, reload, sizePerPage, page, merchantId]);

    const columns = [
        {
            field: 'shippingdetails',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Shipment Details',
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                const courier = allCouriers?.filter((item) => item.prozoId === row?.couriorProvider)
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} component={Link} to={`/order-detail?waybill=${row.awbNumber}`} color='info'><b>AWB : </b>{row?.awbNumber}</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{courier[0]?.parent}</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'current_tatus',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Current Status',
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                let color = (row.status === 'NEW') ? 'primary' : (row.status === 'ACCEPTED_BY_MERCHANT' || row.status === 'ACCEPTED_BY_COURIER' || row.status === 'AUTO_ACCEPTED_BY_SYSTEM') ? 'success' : (row.status === 'DISPUTED_BY_MERCHANT' || row.status === 'DISPUTED_BY_COURIER') ? 'error' : 'primary'
                let text = row.status;
                switch (row.status) {
                    case 'NEW':
                        text = 'New'
                        break;
                    case 'ACCEPTED_BY_MERCHANT':
                        // console.log('Selected fruit is banana.');
                        text = 'Accepted By Merchant'
                        break;
                    case 'ACCEPTED_BY_COURIER':
                        text = 'Accepted By Courier'
                        break;
                    case 'DISPUTED_BY_COURIER':
                        text = 'Disputed By Courier'
                        break;
                    case 'DISPUTED_BY_MERCHANT':
                        text = 'Disputed By Merchant'
                        break;
                    case 'AUTO_ACCEPTED_BY_SYSTEM':
                        text = 'System Accepted'
                        break;
                    default:
                        text = row.status
                }
                // const length = row?.statusLog.length
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Chip size="small" color={color} label={text} />
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'last_status',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Last Update',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                const length = row?.statusLog.length
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{formatDate(row?.statusLog[length - 1]?.date)}</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'originalweight',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Original Weight',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{((row?.originalWeight) / 1000).toFixed(2)} kg</Grid> */}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{((row?.originalWeight) / 1000).toFixed(2)} kg</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{row?.originalAmount ? formatCurrency(row?.originalAmount) : "NA"}</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>{row?.weightSlabsMerchant ? (((row?.weightSlabsMerchant?.originalSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {row?.weightSlabsMerchant ? (((row?.weightSlabsMerchant?.originalSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'appliedweight',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Applied Weight',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{((row?.finalChargedWeight) / 1000).toFixed(2)} kg</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{row?.finalAmountCharged ? formatCurrency(row?.finalAmountCharged) : "NA"}</Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color: 'red' }}>{row?.weightSlabsMerchant ? (((row?.weightSlabsMerchant?.finalAppliedSlab?.startWeight) / 1000).toFixed(2)) : "NA"} - {row?.weightSlabsMerchant ? (((row?.weightSlabsMerchant?.finalAppliedSlab?.endWeight) / 1000).toFixed(2)) : "NA"} kg</Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'createdDate',
            headerAlign: "center",
            hideSortIcons: true,
            align: 'center',
            headerName: 'Created Date',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{formatDate(row?.createdDate)} </>
                )
            },
        },
        {
            field: 'courierImages',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Courier Images',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                if (row?.courierImagesS3Link) {
                    return (
                        <Grid container >
                            {row?.courierImagesS3Link.map((item) => (
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Link href={item.signedLink} target="_blank" rel='noreferrer' style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}>View Image</Link>
                                    {/* <a href={item.signedLink} target="_blank" rel='noreferrer' style={{ color: '#0000FF' }}>View Image </a> */}
                                    {/* <Link href={item.preSignedUrl}>View Pod</Link> */}
                                </Grid>

                            ))}
                        </Grid>
                    )
                }
                else {
                    return "-"
                }
            },
        },
        {
            field: 'merchantImages',
            headerAlign: "center",
            align: 'center',
            hideSortIcons: true,
            headerName: 'Merchant Images',
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                if (row?.merchantImagesS3Link) {
                    return (
                        <Grid container >
                            {row?.merchantImagesS3Link.map((item) => (
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Link href={item.signedLink} target="_blank" rel='noreferrer' style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}>View Image</Link>
                                    {/* <Link href={item.preSignedUrl}>View Pod</Link> */}
                                </Grid>

                            ))}
                        </Grid>
                    )
                }
                else {
                    return "-"
                }
            },
        },
        {
            field: "action",
            headerAlign: "center",
            align: "center",
            hideSortIcons: true,
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                const list = ['AUTO_ACCEPTED_BY_SYSTEM', 'ACCEPTED_BY_MERCHANT', 'ACCEPTED_BY_COURIER']
                const verified = _.intersection(list, [row?.status])
                if (verified?.length > 0) {
                    return (
                        <>
                            <Tooltip title="Status Log">
                                <IconButton size="small" varient='contained' onClick={() => handleOpenstatus(row)}>
                                    <LocalLibraryIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Upload Image">
                                <IconButton color="info" size="small" onClick={() => handleOpen(row?.awbNumber)}>
                                    <UploadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                }
                else if (row?.status === 'DISPUTED_BY_MERCHANT') {
                    return (
                        <>
                            <Tooltip title="Status Log">
                                <IconButton size="small" varient='contained' onClick={() => handleOpenstatus(row)}>
                                    <LocalLibraryIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Accept">
                                <IconButton color="success" size="small" onClick={() => handleOpenAgree(row?.awbNumber)}>
                                    <CheckCircleIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Upload Image">
                                <IconButton color="info" size="small" onClick={() => handleOpen(row?.awbNumber)}>
                                    <UploadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>

                    )
                }
                else {
                    return (
                        <>
                            <Tooltip title="Status Log">
                                <IconButton size="small" varient='contained' onClick={() => handleOpenstatus(row)}>
                                    <LocalLibraryIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Dispute">
                                <IconButton color="button" size="small" onClick={() => handleOpenDispute(row?.awbNumber)}>
                                    <SmsFailedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Accept">
                                <IconButton color="success" size="small" onClick={() => handleOpenAgree(row?.awbNumber)}>
                                    <CheckCircleIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Upload Image">
                                <IconButton color="info" size="small" onClick={() => handleOpen(row?.awbNumber)}>
                                    <UploadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>

                    )
                }
            }
        },
    ];

    const handleOpen = (id) => {
        setOpen(true);
        setAwb(id);
    };
    const handleOpenDispute = (id) => {
        setDispute(true);
        setAwb(id);
        setStatus("DISPUTE");
    };
    const handleOpenAgree = (id) => {
        setAgree(true);
        setAwb(id);
        setStatus("ACCEPT");
    };

    const handleClose = () => {
        setOpen(false);
        setAgree(false);
        setDispute(false);
        setLog(false);
        // setImages(false);
        // setCourImg(false);
    };
    const handleOpenstatus = (id) => {
        setLog(true);
        setAwb(id);
    };


    const downloadFile = (fileName) => {
        setLoading(true);
        count.skip = 0;
        count.limit = 100000;
        count.merchantId = merchantId;
        postGeneric(`${apiConstants.GET_WEIGHT_DICREPENACY}?download=true`, count).then((res) => {
            const data = res?.data
            var blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            let csvContent = URL.createObjectURL(blob);
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            const eleId = `${fileName}`
            link.setAttribute("id", eleId);
            link.setAttribute("download", `${fileName}.csv`);
            document.body.appendChild(link);
            link.click();
            document.getElementById(eleId).remove()
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log(err);
            enqueueSnackbar("Something went wrong", { variant: "error" });
            // setIsSubmitting(false);
        });

    }

    return (
        <div id="main-content" className="container-fluid">
            {loading && <Loader />}
            <PageTitle>Weight Discrepancy Management</PageTitle>
            <MUIModal
                open={open}
                handleClose={handleClose}
                children={
                    <UploadImage
                        id={awb}
                        setReload={setReload}
                        reload={reload}
                        setOpen={setOpen}
                    />
                }
                title={"Upload Image"}
                child={
                    <Typography variant="body2" color="red">
                        File must be in .pdf, .png, .jpg, .jpeg format
                    </Typography>
                }
            />
            <MUIModal
                open={dispute}
                handleClose={handleClose}
                children={
                    <UpdateStatus
                        id={awb}
                        setReload={setReload}
                        Status={Status}
                        setOpen={setDispute}
                    />
                }
                title={"Raise a dispute"}
            />
            <MUIModal
                open={agree}
                handleClose={handleClose}
                children={
                    <UpdateStatus
                        id={awb}
                        setReload={setReload}
                        Status={Status}
                        setOpen={setAgree}
                    />
                }
                title={"Accept Dispute"}
            />
            <MUIModal
                open={log}
                handleClose={handleClose}
                children={<StatusLog id={awb} />}
                title={"Status Log"}
                maxWidth="md"
            />
            <Grid item container spacing={4}>
                <Grid item xs={12}>
                    <Paper>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <RHFSelectField name='status' label='Select Status' options={status.map((op) => ({ label: op.label.toLowerCase().replace(/(^\w| \w)/g, match => match.toUpperCase()), value: op.value }))} width='100%' variant="filled" />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <RHFDateRangePickerField name="created_date" label="Created Date" maxDate={new Date()} width='100%' />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginTop: 1 }}>
                                    <Button variant="contained" type='submit'>Submit</Button>
                                    <Button variant="contained" color='error' sx={{ ml: 1 }} onClick={() => {
                                        reset({
                                            awb: "",
                                            merchant: "",
                                            status: ""
                                        })
                                        setFilters({})
                                        setReload(reload + 1)
                                    }
                                    }>Reset</Button>
                                    <Typography component='span' sx={{ ml: 1 }} color='warning'><Button onClick={() => downloadFile('Weight_Discrepancy')} variant="contained" color="warning">Download</Button></Typography>
                                    <Button variant="contained" type='submit' sx={{ ml: 1 }} onClick={() => { navigate("/weight-discrepancy-bulk-action") }} >Bulk Action Upload</Button>
                                    {/* <Button variant="contained" type='submit' sx={{ ml: 1 }}>Sample</Button> */}
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        autoHeight={true}
                        rows={list.length > 0 ? list.map((val, index) => ({ ...val, id: index })) : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={280}
                        rowCount={Number.MAX_VALUE}
                        hideDisplayRows={"none"}
                        getRowId={(row) => row?.id}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default withLayout(WeightDiscrepancy);
