import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import "./login.css";
import { Box, Button, Card, CardContent, Divider, InputAdornment, TableContainer, TextField } from "@mui/material"
import 'react-toastify/dist/ReactToastify.css';
import { apiConstants } from '../../common/constants';
import axios from 'axios';
import { Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Logout from '@mui/icons-material/Logout';
import { login, setSelectedUserId } from '../../redux/userSlice';
import { fetchReportData } from '../../redux/actions/reportAction';
import Loader from '../../common/Loader';
import { decodeAuthToken } from '../../helpers/UtilityHelper';
import SearchIcon from '@mui/icons-material/Search';


export default function SelectLoginUser() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [merList, setMerList] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [searchTerm, setSearchTerm] = React.useState("");
    const [filteredMerList, setFilteredMerList] = React.useState([]);
    const dataLogin = useSelector((state) => state.user?.loginUserData);
    const { merchantOid, merchantList } = decodeAuthToken(dataLogin.accessToken);
    // get assigned user list
    useEffect(() => {
        const fetchMerchantData = async () => {
            setIsLoading(true);
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("userToken"),
                },
            };
            try {
                let merchantUrl = apiConstants.BASE_LOCAL + apiConstants.GET_MERCHANT_SEARCH;
                const { data } = await axios.post(
                    merchantUrl,
                    {
                        "andfilter": [
                            {
                                "field": "isTestMerchant",
                                "operator": "ne",
                                "value": true
                            },
                            {
                                "field": "status",
                                "operator": "eq",
                                "value": "KYC_APPROVED"
                            }
                        ],
                        "offset": 0,
                        "limit": 500,
                        "includes": "id,name,merchantId,is_deleted"
                    },
                    config
                );
                if (merchantList?.includes(merchantOid)) {
                    setMerList(data?.data);
                    setFilteredMerList(data?.data);
                } else {
                    // remove parent id if not assigned
                    const filterData = data?.data.filter((val) => val?.id !== merchantOid)
                    setMerList(filterData)
                    setFilteredMerList(filterData)
                }
            } catch (error) {
                console.error("Error fetching merchant data:", error); // Log the error if the request fails
            } finally {
                setIsLoading(false);
            }
        };
        fetchMerchantData();
    }, []);

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = merList.filter((merchant) =>
            merchant.name.toLowerCase().includes(value)
        );
        setFilteredMerList(filtered);
    };



    // login via selected user
    const handleMerchantSelect = async (val) => {
        try {
            let config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("userToken"),
                },
            };

            // Make a request to fetch the selected merchant's data
            let merchantUrl = `${apiConstants.BASE_LOCAL}${apiConstants.GET_MERCHANT_SEARCH}`;
            const { data } = await axios.post(
                merchantUrl,
                {
                    offset: 0,
                    limit: 1,
                    orfilter: [],
                    andfilter: [{ field: "id", operator: "eq", value: val }],
                    excludes: "mappedOrderRule,sla,codRemittanceConfig",
                },
                config
            );

            let merchantData = data.data[0];
            delete merchantData.codRemittanceConfig;
            delete merchantData.mappedOrderRule;
            delete merchantData.wallet;
            delete merchantData.pickup_details;
            delete merchantData.bank_details;
            delete merchantData.alertSubscription;
            delete merchantData.gstDetails;
            delete merchantData.merchantWebHooks;
            delete merchantData.activeSlas;
            delete merchantData.alertSubscriptionB2B;
            dispatch(login({ ...dataLogin, merchant: merchantData }));
            dispatch(setSelectedUserId(val)); // Store the selected merchant ID in Redux
            dispatch(fetchReportData(val));

        } catch (error) {
            console.error("Error selecting merchant:", error);
        }
    };

    const handleLogout = () => {
        dispatch({ type: 'USER_LOGGED_OUT' })
        navigate('/')
    }

    return (
        <>
            {isLoading && <Loader />}
            <div id="login-wrapper" className="container-fluid vh-100">
                <div className="row h-100 position-relative">
                    <div className="col-12 col-md-5 col-lg-4 d-none d-md-block">
                        <img src="img/prozo-logo2.svg" className="logo" height="100" alt="Prozo Logo" />
                        <h5 style={{ marginLeft: "95px" }}>Shipping made easy</h5>
                    </div>
                    <div className="col-12 col-lg-5 position-relative"></div>

                    {/* Position the card over the row */}
                    <Box
                        className="col-12 position-absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{ top: 0, bottom: 0, right: 0 }}
                    >
                        <Card sx={{ maxWidth: 550, width: '100%', mr: 1, maxHeight: '90%', minHeight: '90%' }}> {/* Adjust the margin as needed */}
                            <CardContent>
                                <img src="/img/prozo-logo2.svg" className="d-md-none d-lg-none m-auto mb-5" height="50" alt="Proship Logo" />
                                <Typography variant="h5" sx={{ mt: -1, ml: 1 }}>Select Merchant</Typography>
                                <Typography variant="subtitle2" sx={{ mb: 2, mt: 0.5, ml: 1 }}>
                                    Select the merchant you want to access!
                                </Typography>
                                <Divider />
                                <TextField
                                    variant="outlined"
                                    placeholder="Search merchants..."
                                    fullWidth
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ my: 2 }}
                                />
                                <TableContainer
                                    sx={{
                                        maxHeight: 350,
                                        overflowY: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: '8px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#888',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                backgroundColor: '#555',
                                            },
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#f1f1f1',
                                            borderRadius: '8px',
                                        },
                                    }}
                                >
                                    <Table>
                                        <TableBody >
                                            {filteredMerList?.length > 0 && (
                                                filteredMerList.map((val, index) => (
                                                    <TableRow
                                                        key={index}
                                                        hover
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() => handleMerchantSelect(val.id)}
                                                    >
                                                        <TableCell>
                                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                <Typography variant="body1">{val.name}</Typography>
                                                                <KeyboardArrowRightIcon />
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ mt: 4 }}>
                                    <Button
                                        variant='contained'
                                        color='error'
                                        size='small'
                                        onClick={() => handleLogout()}
                                        startIcon={<Logout />}
                                        sx={{ ml: 1 }}
                                    >
                                        Logout
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>

                </div>
            </div>

        </>
    );

}