import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { LooksOne, Percent } from '@mui/icons-material'
import { numberPrecision } from '../../helpers/UtilityHelper'
import useGetDayWiseData from './useGetDayWiseData'
import { TableSkeleton } from '../Skeletons'
import MaterialTable from '../MaterialTable'

export default function DayWiseAttempt({ data, title, groupOn, isRvp }) {
  const [tableView, setTableView] = React.useState('percentage')

  const { finalData, isLoading } = useGetDayWiseData(data, groupOn, isRvp)
  const allColumns = _.map(_.get(finalData, '0.split', []), 'name')

  const tableData = React.useMemo(() => {
    const transformData = finalData.map((item) => {
      const row = { ageing: item.ageing, count: item.count, percentage: item.percentage }

      for (const i of item.split) {
        row[i.name] = { percentage: i.percentage, count: i.count }
      }

      return row
    })

    return transformData
  }, [JSON.stringify(finalData)])


  const tabelColumns = React.useMemo(() => {
    return [
      { label: 'Attempt Day', value: 'ageing', align: 'left' },
      ...allColumns.map((col) => ({
        label: col,
        value: (row) =>
          `${numberPrecision(row[col][tableView], 1)}${tableView === 'percentage' ? '%' : ''}`,
        numeric: true,
        align: 'right',
      })),
      {
        label: 'Total',
        value: (row) =>
          `${numberPrecision(row[tableView], 1)}${tableView === 'percentage' ? '%' : ''}`,
        numeric: true,
        align: 'right',
      },
    ]
  }, [JSON.stringify(allColumns), JSON.stringify(tableView)])

  if (isLoading) {
    return <TableSkeleton size='large' />
  }

  return (
    <Card elevation={1}>
      <CardHeader
        title={title}
        subheader={
          <Typography variant='body2'>
            {isRvp
              ? 'The data below offers an overview of the number of days it has taken for the courier partner to attempt pickup for all pickedup shipments. The days are measured from the awb registered date and first pickup attempt.'
              : 'The data below offers an overview of the number of days it has taken for the courier partner to attempt delivery for all delivered shipments. The days are measured from the actual pickup date.'}
          </Typography>
        }
        action={
          <ToggleButtonGroup
            color='primary'
            size='small'
            exclusive
            onChange={(e, v) => setTableView(v)}
            value={tableView}
          >
            <ToggleButton value='percentage' title='View Data In %'>
              <Percent />
            </ToggleButton>
            <ToggleButton value='count' title='View Data In Count'>
              <LooksOne />
            </ToggleButton>
          </ToggleButtonGroup>
        }
      />
      <CardContent>
        <MaterialTable
          size='small'
          fileName={title}
          addBorders
          rows={tableData}
          columns={tabelColumns}
          frozenColumnsCount={1}
          exportExcel={false}
        />
      </CardContent>
    </Card>
  )
}
