import React from 'react';
import withLayout from '../../hocs/Layout';
import './dashboard.css';
import DateRangePicker from "../../components/DateRangePicker";
import { useState } from 'react';
import Couriers from './Couriers';
/* import RTO from './RTO'; */
import Orders from './Orders';
// import NDR from './NDR';
import Lost from './Lost';
import ShippingOverview from './ShippingOverview';
// import Performance from './Performance';
import { subDays } from "date-fns";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PerformanceDashboard from './PerformanceDashboard';
import PageTitle from '../../common/PageTitle';
import WhatsAppComm from './whatsapp';
import { getShipmentInfo } from '../../helpers/UtilityHelper';
import { getMerchant } from '../../helpers/ReduxHelpers';

const Dashboard = () => {
  const [tab, setTab] = useState(0)
  const [shipType, setShipType] = useState("B2C")
  const [dateRange, setDateRange] = useState([{
    endDate: new Date(),
    startDate: subDays(new Date(), 7),
    key: 'selection'
  }])
  // const { userInfo } = useSelector((state) => state.user)
  // const merchantId = merchant.id ? merchant.id : ''
  const merchant = getMerchant();
  const shipmentInfo = getShipmentInfo(merchant, shipType);  

  const handleChange = (event) => {
    setShipType(event.target.value);
  }
  const handleDropDown = (_v, popupState) => {
    setTab(_v)
    popupState.close()
  }

  return (
    <div id="main-content" className="container-fluid">
      <PageTitle>Dashboard</PageTitle>
      <div className="row">
        <div className='col-12 d-flex align-items-center bg-white shadow-sm'>
          <div className="col-8 position-relative">
            <Box>
              <Tabs onChange={(_v, next) => {
                setTab(next)
              }} value={tab} sx={{
                '& .MuiTabs-indicator': {
                  display: 'none'
                },
                '& .MuiTab-root': {
                  maxWidth: '40%'
                },
                '& .Mui-selected': {
                  color: '#000 !important',
                  background: '#f0ba32',
                  border: '1px solid #ccc',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  borderBottomWidth: '0px'
                }
              }}>
                <Tab label="Overview" sx={{ fontSize: 15, fontWeight: 400, color: '#181A23' }} />
                <Tab label="Shipments" sx={{ fontSize: 15, fontWeight: 400, color: '#181A23' }} />
                <Tab label="Courier" sx={{ fontSize: 15, fontWeight: 400, color: '#181A23' }} />
                <Tab label="Lost & Damaged" sx={{ fontSize: 15, fontWeight: 400, color: '#181A23' }} />

                {[5, 6, 7, 8, 9].map((v) => <Tab key={v} label="NA" value={v} sx={{ display: 'none' }} />)}

                {/* <Tab label="NDR" sx={{ fontSize: 17, fontWeight: 400, color: '#181A23' }} /> */}


                <PopupState variant="popover" popupId="WhatsApp-Comm">
                  {(popupState) => (
                    <React.Fragment>
                      <Tab
                        {...bindTrigger(popupState)}
                        label="WhatsApp"
                        iconPosition='end'
                        icon={<ArrowDropDownIcon fontSize='small' />}
                        sx={{ fontSize: 15, fontWeight: 400, color: '#181A23', opacity: 1 }}
                        className={(tab > 7) ? 'Mui-selected' : ""}
                      />
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem sx={{ width: 190 }} onClick={() => handleDropDown(8, popupState)}>Shipment Status</MenuItem>
                        <MenuItem sx={{ width: 190 }} onClick={() => handleDropDown(9, popupState)}>NDR Automation</MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                {/* <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Tab
                        {...bindTrigger(popupState)}
                        label="Performance"
                        iconPosition='end'
                        icon={<ArrowDropDownIcon fontSize='small' />}
                        sx={{ fontSize: 15, fontWeight: 400, color: '#181A23', opacity: 1 }}
                        className={(tab > 4 && tab < 8) ? 'Mui-selected' : ""}
                      />
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => handleDropDown(5, popupState)}>Forward</MenuItem>
                        <MenuItem onClick={() => handleDropDown(6, popupState)}>Pickup</MenuItem>
                        <MenuItem onClick={() => handleDropDown(7, popupState)}>RTO</MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState> */}
              </Tabs>
            </Box>
          </div>
          <div className='col-4 d-flex justify-content-end align-items-center position-relative'>
            {merchant?.isD2CMerchant && merchant?.isPTLMerchant && [0, 1, 2, 3].includes(tab) && (
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120, mr: 2 }}>
                <InputLabel id="demo-simple-select-filled-label">Shipment Type</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={shipType}
                  onChange={handleChange}
                  label="Shipment Type"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: 'red', // Change the icon color
                    },
                  }}
                >
                  <MenuItem value={"B2C"}>B2C</MenuItem>
                  <MenuItem value={"B2B"}>B2B</MenuItem>
                </Select>
              </FormControl>
            )}
            {[0, 1, 2, 4, 8, 9].includes(tab) && (
              <DateRangePicker
                defaultRange={dateRange}
                maxDate={new Date()}
                onChange={(range) => setDateRange(range)}
              />
            )}
          </div>

        </div>
        <div className="col-12 mt-3 px-0">
          {(tab === 0) && <ShippingOverview dateRange={dateRange} shipType={shipmentInfo} />}
          {(tab === 1) && <Orders dateRange={dateRange} shipType={shipmentInfo} />}
          {(tab === 2) && <Couriers dateRange={dateRange} shipType={shipmentInfo} />}
          {/* {(tab === 3) && <NDR dateRange={dateRange} /> */}
          {(tab === 3) && <Lost shipType={shipmentInfo} />}
          {/* {(tab === 4) && <div>hey</div>} */}
          {([5, 6, 7].includes(tab)) && <PerformanceDashboard tab={tab} />}
          {([8, 9].includes(tab)) && <WhatsAppComm tab={tab} dateRange={dateRange} />}
        </div>
      </div>

    </div >
  );
};

export default withLayout(Dashboard);