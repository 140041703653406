import * as React from 'react';
import { formatCurrency, formatDate, parseDateAndTime } from "../../helpers/UtilityHelper";
import _ from 'lodash';
import Loader from '../../common/Loader';
import useLostAndDamaged from '../../common/hooks/useLostAndDamaged';
import { getMerchant } from '../../helpers/ReduxHelpers';
import StatCard from '../StatCard';
import { Box, Card, CardHeader, Grid } from '@mui/material';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Link } from 'react-router-dom';
import DateRangePicker from '../DateRangePicker';
import { subDays } from 'date-fns';

export default function Lost({ shipType }) {
    const [dateRange, setDateRange] = React.useState([{
        endDate: new Date(),
        startDate: subDays(new Date(), 90),
        key: 'selection'
    }])
    const { fromDate: from_date, toDate: to_date } = { fromDate: formatDate(dateRange[0].startDate, 'yyyy-MM-dd'), toDate: formatDate(dateRange[0].endDate, 'yyyy-MM-dd') }
    // const userToken = jwt_decode(getToken());
    const merchant = getMerchant();
    const merchantId = merchant.id
    
    const { isCalculating, data: lostOrders } = useLostAndDamaged(merchantId, from_date, to_date, shipType);
    const noDeliveryOrders = _.filter(lostOrders, (item) => item.deliveryAttemptCount === 0 || _.isNil(item.deliveryAttemptCount));
    const noDeliveryOrderCount = _.size(noDeliveryOrders);
    const courierData = _.chain(lostOrders)
        .groupBy("courierName")
        .map((orders, courierName) => ({
            courierName,
            totalCount: orders.length,
            totalInvoiceValue: _.sumBy(orders, "invoiceValue"),
        }))
        .value();

    const zoneData = _.chain(lostOrders)
        .groupBy("merchantZone")
        .map((orders, merchantZone) => ({
            merchantZone,
            totalCount: orders.length,
            totalInvoiceValue: _.sumBy(orders, "invoiceValue"),
        }))
        .value();
    const [sizePerPage, setSizePerPage] = React.useState(5);
    const [sizePerPage1, setSizePerPage1] = React.useState(30);
    const [page, setPage] = React.useState(0);

    const courierColumns = [
        {
            field: 'Courier Name',
            renderCell: ({ row }) => row?.courierName,
            flex: 1.5
        },
        {
            field: 'Shipping Cost',
            align: 'left',
            renderCell: ({ row }) => formatCurrency(row?.totalInvoiceValue),
            flex: 1.2
        },
        {
            field: 'Total Shipments',
            renderCell: ({ row }) => row?.totalCount,
            flex: 1
        },
    ]

    const zoneColumns = [
        {
            field: 'Zone Name',
            renderCell: ({ row }) => row?.merchantZone,
            flex: 1.5
        },
        {
            field: 'Shipping Cost',
            align: 'left',
            renderCell: ({ row }) => formatCurrency(row?.totalInvoiceValue),
            flex: 1.2
        },
        {
            field: 'Total Shipments',
            renderCell: ({ row }) => row?.totalCount,
            flex: 1
        },
    ]

    const allShipmentsColumns = [
        {
            field: 'AWB Number',
            renderCell: ({ row }) => {
                return (
                    <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row.awbNumber}`}>
                        {row.awbNumber}
                    </Link>
                )
            },
            flex: 1.1
        },
        {
            field: 'Order Date',
            renderCell: ({ row }) => parseDateAndTime(row?.date),
            flex: 1
        },
        {
            field: 'Courier Partner',
            renderCell: ({ row }) => row?.courierName,
            flex: 1.2
        },
        {
            field: 'Reference Number',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {row.createdDate ? parseDateAndTime(row.createdDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                );
            },
            flex: 1
        },
        {
            field: 'Zone',
            align: 'left',
            renderCell: ({ row }) => row?.merchantZone,
            flex: 0.8
        },
        {
            field: 'Invoice Value',
            align: 'left',
            renderCell: ({ row }) => row?.invoiceValue,
            flex: 1
        },
        {
            field: 'Pickup Pincode',
            align: 'left',
            renderCell: ({ row }) => row?.pickupPincode,
            flex: 1
        },
        {
            field: 'Delivery Details',
            align: 'left',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={12}>
                                {row.deliveryCity} ,  {row.deliveryState}
                            </Grid>
                            <Grid item xs={12}>
                                {row.dropPincode}
                            </Grid>
                        </Grid>
                    </>
                );
            },
            flex: 1.5
        },
        {
            field: 'Lost and Damaged Date',
            align: 'left',
            renderCell: ({ row }) => parseDateAndTime(row?.lostDamagedTimestamp),
            flex: 1.2
        },
    ]


    return (
        <>
            {isCalculating && <Loader />}
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <DateRangePicker
                            defaultRange={dateRange}
                            maxDate={new Date()}
                            onChange={(range) => setDateRange(range)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <StatCard image={false} item={{ 'title': 'Total Lost Shipments', number: lostOrders?.length }}></StatCard>
                </Grid>
                <Grid item xs={12} md={3}>
                    <StatCard image={false} item={{ 'title': 'Lost Shipments Value', number: formatCurrency(Math.round(_.sumBy(lostOrders, (item) => parseFloat(item.invoiceValue)))) }}></StatCard>
                </Grid>
                <Grid item xs={12} md={3}>
                    <StatCard image={false} item={{ 'title': 'Shipments Lost Before Delivery Attempt', number: noDeliveryOrderCount }}></StatCard>
                </Grid>
                {lostOrders?.length > 0 && (
                    <>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ minHeight: 320, maxHeight: 320 }}>
                                <CardHeader sx={{ mb: 3 }}
                                    title="Zone Wise Lost Shipments"
                                />
                                <ProzoDataGrid
                                    columns={courierColumns}
                                    autoHeight={true}
                                    rows={_.orderBy(courierData, 'totalCount', 'desc') || []}
                                    sizePerPage={sizePerPage}
                                    setSizePerPage={setSizePerPage}
                                    setPage={setPage}
                                    page={page}
                                    pagination={true}
                                    hideFooterPagination={false}
                                    paginationMode={"client"}
                                    rowHeight={120}
                                    rowCount={courierData?.length || 0}
                                    getRowId={(row) => row.courierName}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ minHeight: 320, maxHeight: 320 }}>
                                <CardHeader sx={{ mb: 3 }}
                                    title="Courier Wise Lost Shipments"
                                />
                                <ProzoDataGrid
                                    columns={zoneColumns}
                                    autoHeight={true}
                                    rows={_.orderBy(zoneData, 'totalCount', 'desc') || []}
                                    sizePerPage={sizePerPage}
                                    setSizePerPage={setSizePerPage}
                                    setPage={setPage}
                                    page={page}
                                    pagination={true}
                                    hideFooterPagination={false}
                                    paginationMode={"client"}
                                    rowHeight={120}
                                    rowCount={zoneData?.length || 0}
                                    getRowId={(row) => row.merchantZone}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Card>
                                <CardHeader sx={{ mb: 3 }}
                                    title="All Lost Shipments"
                                />
                                <ProzoDataGrid
                                    columns={allShipmentsColumns}
                                    autoHeight={true}
                                    rows={lostOrders || []}
                                    sizePerPage={sizePerPage1}
                                    setSizePerPage={setSizePerPage1}
                                    setPage={setPage}
                                    page={page}
                                    pagination={true}
                                    paginationMode={"client"}
                                    hideFooterPagination={false}
                                    rowHeight={120}
                                    rowCount={lostOrders?.length || 0}
                                    getRowId={(row) => row.awbNumber}
                                />
                            </Card>
                        </Grid>
                    </>
                )}
            </Grid>

        </>
    )
}
