import { formatDate } from "../../../helpers/UtilityHelper"
// import jwt_decode from "jwt-decode";
// import { getToken } from "../../../helpers/ReduxHelpers";
import { getMerchant } from '../../../helpers/ReduxHelpers';

export const NON_NDR_TEMPLATE_KEY = 'STATUS'
export const NDR_TEMPLATE_KEY = 'NDR'

const templates = {
    [NON_NDR_TEMPLATE_KEY]: ["proship_order_placed", "proship_order_in_transit", "proship_out_for_delivery", "proship_order_picked_up", "proship_order_not_delivered", "proship_order_delivered", "proship_delivery_delay"],
    [NDR_TEMPLATE_KEY]: ['ndr_workflow_initiate', 'ndr_workflow_initiate_without_address_flow']
}

export default function getRequestFilter(dateRange, templateKey) {
    // const userToken = jwt_decode(getToken());
    // const merchantId =
    //     userToken && userToken.merchantOid ? userToken.merchantOid : "";

    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''
    

    const collection = 'interaction_audit'
    const filterValue = templates[templateKey]
    const field = 'template_name'
    const operator = 'in'
    const limit = 2147483647
    const excludes = 'payloadBody,associated_number,template_type,callback_body'

    const andfilter = [
        { field, operator, value: filterValue },
        {
            "field": "created_date",
            "operator": "gte",
            "value": `Date('${formatDate(dateRange[0].startDate, 'yyyy-MM-dd')} 00:00:00')`
        }, {
            "field": "created_date",
            "operator": "lte",
            "value": `Date('${formatDate(dateRange[0].endDate, 'yyyy-MM-dd')} 00:00:00')`
        }, {
            "field": "meta_data.merchantOid",
            "operator": "eq",
            "value": merchantId
        }, {
            "field": "status",
            operator: "eq",
            value: 'success'
        }
    ]
    return { collection, limit, andfilter, excludes }
}