import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from '../../../../common/hook-form';
import MUIModal from '../../../../common/MUIModal';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import RHFTextArea from '../../../../common/hook-form/RHFTextArea';
import { LoadingButton } from '@mui/lab';


const AddDeliveryLocation = (props) => {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Address name is required').matches(/^[0-9a-zA-Z-,'. ]+$/, 'Please enter a valid store name'),
        email: Yup.string().required('Email is required').email('Please enter a valid email'),
        contactNumber: Yup.string().required('Mobile Number is required').matches(/^[1-9][0-9]{9}$/, 'Please enter a mobile number'),
        gstin: Yup.string().required('Gstin is required').matches(/^([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[a-zA-Z0-9]{3})$/, 'Please enter a valid gstin'),
        address_line: Yup.string().required('Address is required'),
        pincode: Yup.string().required('Pincode is required').matches(/^\d{6}$/, 'Please enter a valid pincode'),
        state: Yup.string().required('State is required').matches(/^[a-zA-Z-'. ]+$/, 'Please enter a valid state'),
        city: Yup.string().required('City is required').matches(/^[a-zA-Z-'. ]+$/, 'Please enter a valid city'),
    });

    const formMethods = useForm({
        resolver: yupResolver(validationSchema)
    });
    const { handleSubmit, reset } = formMethods;

    const { show, handleClose, formLoading, currentId } = props;
    const onSubmit = (data) => {
        let defaultValues = {
            name: "",
            email: "",
            contactNumber: "",
            gstin: "",
            address_line: "",
            pincode: "",
            state: "",
            city: "",
            country: "",
            receiver_name: "",
            dropOrganization: ""

        };
        reset({ ...defaultValues });
        props.handleSubmit(data);
    };
    const hideModal = () => {
        let defaultValues = {
            name: "",
            email: "",
            contactNumber: "",
            gstin: "",
            address_line: "",
            receiver_name: "",
            pincode: "",
            state: "",
            city: "",
            country: "",
            dropOrganization: "",
        };
        reset({ ...defaultValues });
        handleClose();
    };
    useEffect(() => {
        if (currentId && Object.keys(currentId).length > 0 && currentId.id) {
            let defaultValues = {
                name: (currentId.name) ? currentId.name : "",
                email: (currentId.email) ? currentId.email : "",
                contactNumber: (currentId.contactNumber) ? currentId.contactNumber : "",
                gstin: (currentId.gstin) ? currentId.gstin : "",
                address_line: (currentId.address_line) ? currentId.address_line : "",
                pincode: (currentId.pincode) ? currentId.pincode : "",
                state: (currentId.state) ? currentId.state : "",
                city: (currentId.city) ? currentId.city : "",
                country: (currentId.country) ? currentId.country : "",
                dropOrganization: (currentId.dropOrganization) ? currentId.dropOrganization : "",
                receiver_name: (currentId.receiver_name) ? currentId.receiver_name : ""
            };
            reset({ ...defaultValues });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentId]);

    return (
        <>
            <MUIModal open={show} maxWidth="lg" title="Add New Delivery Location" handleClose={hideModal}>
                <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RHFTextField name="name" label="Address Name" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RHFTextField name="receiver_name" label="Receiver Name" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RHFTextField name="email" label="Email ID" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RHFTextField name="contactNumber" label="Contact Number" required />
                        </Grid>
                        <Grid item xs={12}>
                            <RHFTextArea name="address_line" label="Address" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RHFTextField name="pincode" label="Pincode" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RHFTextField name="city" label="City" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RHFTextField name="state" label="State" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RHFTextField name="gstin" label="GSTIN" required />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton type="submit" loading={formLoading} variant="contained" color="primary">
                                Save Delivery Location
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>

            </MUIModal>
        </>
    );
};
export default AddDeliveryLocation;