import React from "react";
import { Box, Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import PickupLocation from "./PickupLocation";
import { useForm } from "react-hook-form";
import { FormProvider } from "../../common/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup"
import DropLocation from "./DropLocation";
import PackageDetails from "./PackageDetails";
import OtherDetails from "./OtherDetails";
import { apiConstants, postGeneric } from "../../common/constants";
import { formatDate } from "../../helpers/UtilityHelper";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../common/PageTitle";
import { useSnackbar } from "notistack";
import { getMerchant } from "../../helpers/ReduxHelpers";

const steps = [
  "Pickup Details",
  "Delivery Details",
  "Box Details",
  "Other Details",
];

const INVOICE_VALUE_FOR_EWAY_BILL = 50000;

function CreatePTLShipment() {

  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const [creatingOrder, setIsCreatingOrder] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const merchant = getMerchant();
  const merchantId = merchant.id ? merchant.id : ''

  const methods = useForm({
    defaultValues: {
      // invoice_date: new Date(),
      riskType: 'OWNER',
      payment_mode: 'PREPAID',
      insured: false,
      appointment_delivery: false,
      invoice_value: 0,
      reference: '',
      invoice_number: '',
      ewaybill: '',
      ewb_exp_date: null,
      invoice_date: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        warehouse_name: Yup.string().required("Pickup Location is Required!"),
        delivery_location: Yup.string().required("Delivery Location is Required!"),
        delivery_details: Yup.object().shape({
          to_name: Yup.string().required("Customer Name is Required!"),
          to_phone_number: Yup.string(),
          to_address: Yup.string().required("Customer Address is Required!"),
          to_email: Yup.string(),
          to_pincode: Yup.string().required("Address Pincode is Required!"),
          to_city: Yup.string().required("Address City is Required!"),
          to_state: Yup.string().required("Address State is Required!"),
          gstin: Yup.string(),
        }),
        item_list: Yup.array(Yup.object().shape({
          item_name: Yup.string().required(""),
          units: Yup.number().required("").min(1, ""),
          additional: Yup.object().shape({
            length: Yup.number().required("").min(1, ""),
            breadth: Yup.number().required("").min(1, ""),
            height: Yup.number().required("").min(1, ""),
            weight: Yup.number().required("").min(1, "")
          })
        })),
        invoice_value: Yup.number().required("").min(1, ""),
        selling_price: Yup.number().required("").min(1, ""),
        invoice_date: Yup.date().required("Invoice date is a mandatory field!"),
        payment_mode: Yup.string().required(""),
        cod_amount: Yup.number().when('payment_mode', {
          is: val => val === 'COD',
          then: Yup.number().required(""),
        }).min(1, ""),
        reference: Yup.string().required(""),
        insured: Yup.boolean().required(""),
        appointment_delivery: Yup.boolean().required(""),
        invoice_number: Yup.string().required(""),
        ewaybill: Yup.string().when("invoice_value", {
          is: val => val > INVOICE_VALUE_FOR_EWAY_BILL,
          then: Yup.string().required("Eway bill number is required for invoices > Rs. 50,000")
        }),
        courier_id: Yup.string().when(["invoice_value", "ewaybill"], {
          is: (invoice_value, ewaybill) => invoice_value > INVOICE_VALUE_FOR_EWAY_BILL && ewaybill,
          then: Yup.string().required("Courier Id is mandatory")
        }),
        // ewb_exp_date: Yup.date().optional(),
        riskType: Yup.string().required(""),
      })
    ),
  });

  const { handleSubmit } = methods;


  const onSubmit = (values) => {
    setIsCreatingOrder(true)
    const formData = JSON.parse(JSON.stringify(values));
    const request = {
      ...formData,
      order_type: 'Forward Shipment',
      skipRule: false,
      merchantOId: merchantId
    }

    if (request.payment_mode === 'PREPAID') {
      request.cod_amount = 0;
    }

    if (request.invoice_date) {
      request.invoice_date = formatDate(request.invoice_date, "yyyy-MM-dd hh:mm:ss")
    }

    if (request.ewb_exp_date) {
      request.ewb_exp_date = formatDate(request.ewb_exp_date, "yyyy-MM-dd hh:mm:ss")
    }

    request.item_list.forEach((item) => {
      let deadWeight = item.additional.weight;
      let volWeight = (item.additional.length * item.additional.breadth * item.additional.height) / 4500;
      item.additional.weight = Math.max(deadWeight, volWeight) * 1000;
    })

    if (request.courier_id) {
      request.skipRule = true;
      request.courier_id = {
        prozoId: request.courier_id
      }
    }
    // return false;
    postGeneric(apiConstants.ASYNC_B2B_CREATE_ORDER, request)
      .then((response) => {
        enqueueSnackbar("Successfully Created!", { variant: 'success' });
        navigate("/b2b-orders");
      }).catch((err) => {
        const message = err?.response?.data?.message || err.message;
        enqueueSnackbar(message || "Error creating order", { variant: "error" });
      }).finally(() => {
        setIsCreatingOrder(false)
      })
  }

  return (
    <div id="main-content" className="container-fluid">
      <PageTitle>Create PTL Shipment</PageTitle>
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xs={2} md={2}>
          <Stepper orientation="vertical" activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={10} md={10}>
          <Box sx={{ p: 5, background: 'linear-gradient(325deg, #E0F7FA, #fff 100%)' }} component={Paper} elevation={1}>
            <Typography sx={{ mb: 3 }} variant="h6" >{activeStep + 1}. {steps[activeStep]}</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              {activeStep === 0 && (
                <PickupLocation setActiveStep={setActiveStep} />
              )}
              {activeStep === 1 && (
                <DropLocation setActiveStep={setActiveStep} />
              )}
              {activeStep === 2 && (
                <PackageDetails setActiveStep={setActiveStep} />
              )}
              {activeStep === 3 && (
                <OtherDetails creatingOrder={creatingOrder} setActiveStep={setActiveStep} />
              )}
            </FormProvider>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreatePTLShipment;