import React, { useEffect, useState } from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { apiConstants, postGeneric } from './constants';
import withLayout from '../hocs/Layout';
import PageTitle from './PageTitle';
import ProzoDataGrid from './ProzoDataGrid';
import Loader from '../common/Loader';
import { formatDate } from '../helpers/UtilityHelper';

const PushNotificationList = () => {
    const [allNotification, setAllNotification] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);

    useEffect(() => {
        setLoading(true)
        postGeneric(`${apiConstants.GET_NOTIFICATIONS}`, {
            "andfilter": [{
                field: "active",
                operator: "eq",
                value: true,
            }],
            "limit": 50
        })
            .then((res) => {
                const notifications = res?.data?.data.filter((val) => val.destination === "PUSH");
                setAllNotification(notifications);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = [
        {
            field: 'header',
            headerName: 'Header',
            flex: 0.8,
            renderCell: (params) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ fontWeight: 600 }}>
                            {params.value}
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'text',
            headerName: 'Message',
            flex: 2,
            renderCell: (params) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                            {params.value}
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'createdDate',
            headerName: 'Date',
            flex: 0.5,
            renderCell: (params) => (
                <Typography variant="body2">
                    {formatDate(params.value, 'dd MMM, yyyy')}
                </Typography>
            )
        },
        {
            field: 'view',
            headerName: 'Action',
            flex: 0.4,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {params.row.text1 && params.row.description ? (
                                <Tooltip title={params.row.text1} arrow>
                                    <Button
                                        variant="text"
                                        size="small"
                                        onClick={() => window.open(params.row.description, '_blank')}
                                    >
                                        View
                                    </Button>
                                </Tooltip>
                            ) : null}
                        </Grid>
                    </Grid>
                </>

            )
        }
    ];

    return (
        <div id="main-content" className="container-fluid">
            {loading && <Loader />}
            <PageTitle>All Notifications</PageTitle>
            <ProzoDataGrid
                columns={columns}
                autoHeight={true}
                rows={allNotification}
                sizePerPage={sizePerPage}
                setSizePerPage={setSizePerPage}
                setPage={setPage}
                page={page}
                pagination={true}
                rowHeight={180}
                paginationMode={'client'}
                rowCount={allNotification?.length}
                getRowId={(row) => row.id}
            />
        </div>
    );
};

export default withLayout(PushNotificationList);
