import { addDays, addMonths, addWeeks, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isWithinInterval } from "date-fns";
import _ from "lodash";
import { formatDate, getDiffByIgnoringMidNight, getYYYYMMDD } from "../../helpers/UtilityHelper";
import { useGetSelectedDateRange } from "../../common/contexts/DateSelector";
import { useMemo, useState, useEffect } from "react";

const weekStartsOn = 1;

function useGetDayWiseData(allDeliveredShipments = [], groupOn, isRvp = false) {

    const { from_date, to_date } = useGetSelectedDateRange();

    // State to manage loading state
    const [isLoading, setIsLoading] = useState(true);

    // Memoizing intervalArr to prevent recalculations on every render
    const intervalArr = useMemo(() => {
        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1);
            return eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn });
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1);
            return eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn });
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1);
            return eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn });
        }
        return [];
    }, [from_date, to_date, groupOn]);

    const allDeliveredShipmentsCount = allDeliveredShipments.length;

    // Memoizing grouped data by date to avoid unnecessary recalculations
    const allShipmentsGroupByDate = useMemo(() => _.groupBy(allDeliveredShipments, item => getYYYYMMDD(item?.awbRegisteredDate || new Date())), [allDeliveredShipments]);

    // Memoizing ageing groups to avoid unnecessary recalculations
    const groupByAgeing = useMemo(() => {
        return _(allDeliveredShipments)
            .groupBy(item => {

                if (isRvp) {

                    if (item.awbRegisteredDate && item.firstPickupAttemptTimestamp) {
                        let days = getDiffByIgnoringMidNight(item.awbRegisteredDate, item.firstPickupAttemptTimestamp);
                        if (days < 0) days = 0;
                        return days > 8 ? '8+' : days;
                    }

                } else {

                    if (item.firstDeliveryAttemptTimestamp && item.actualPickUpTime) {
                        const days = getDiffByIgnoringMidNight(item.actualPickUpTime, item.firstDeliveryAttemptTimestamp);
                        return days > 8 ? '8+' : days;
                    }

                }

                return 'N/A';
            })
            .map((items, ageing) => {
                const itemsGroupedByDate = _.groupBy(items, item => getYYYYMMDD(item?.awbRegisteredDate || new Date()));
                const count = items.length || 0;
                const percentage = (count / allDeliveredShipmentsCount) * 100;
                return { ageing, items: itemsGroupedByDate, count, percentage, split: [] };
            })
            .value();
    }, [allDeliveredShipments, allDeliveredShipmentsCount, isRvp]);

    useEffect(() => {
        setIsLoading(true); // Start loading

        // Process data interval-wise
        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index];
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : start;

            const rowInner = { name: null };

            if (groupOn === 'DOD') {
                rowInner.name = formatDate(start, 'dd MMM');
            } else if (groupOn === 'WOW') {
                rowInner.name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`;
            } else {
                rowInner.name = formatDate(start, 'MMM');
            }

            const allRegisteredDatesBetween = _.filter(Object.keys(allShipmentsGroupByDate), date =>
                isWithinInterval(new Date(date), { start, end })
            );

            const allDeliveredShipmentsGroupBetweenDate = allRegisteredDatesBetween
                .map(key => allShipmentsGroupByDate[key])
                .flat();

            groupByAgeing.forEach(data => {
                const addDatesWithIn = _.filter(Object.keys(data.items), date =>
                    isWithinInterval(new Date(date), { start, end })
                );

                const addDataWithInDate = addDatesWithIn
                    .map(key => data.items[key])
                    .flat();

                const count = addDataWithInDate.length || 0;
                const percentage = (count / allDeliveredShipmentsGroupBetweenDate.length) * 100;
                const split = { name: rowInner.name, count, percentage };

                data.split = [...data.split, split];
            });
        }

        setIsLoading(false); // End loading after processing
    }, [intervalArr, allShipmentsGroupByDate, groupByAgeing, groupOn]);

    return { finalData: groupByAgeing, isLoading };
}

export default useGetDayWiseData;
