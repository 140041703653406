import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { numberPrecision } from "../../../../helpers/UtilityHelper";
import MaterialTable from "../../../../common/MaterialTable";

export default function DeliveryTat({ data }) {

    const [tableView] = React.useState("perc")

    const tabelColumns = [
        { label: 'TAT', value: 'name', align: 'left' },
        {
            label: '% Shipments',
            value: (row) => numberPrecision(row[tableView]) + '%',
            align: 'right',
            numeric: true
        },
        {
            label: '% Cumulative',
            value: (row) => numberPrecision(row.cumulative) + '%',
            align: 'right',
            numeric: true
        },

    ];

    return (
        <Card elevation={1}>
            <CardHeader title="TAT Wise Split" />
            <CardContent>
                <MaterialTable size="small" fileName="ZoneWiseBookedShipments" addBorders rows={data} columns={tabelColumns} frozenColumnsCount={1} />
            </CardContent>
        </Card>
    )
}