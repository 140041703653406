import _ from 'lodash'
import { useGetAllShipmentsWithPickupAndDeliveryDataQuery } from "../../redux/commonRTK";
import RevenueSummary from './RevenueSummary';
import OrganizationalChart from '../charts/org/OrgChart';
import { formatCurrency, getOrderStatus, rto as rtoStatus, cancelled as cancelledStatus, lost, getYYYYMMDD } from '../../helpers/UtilityHelper';
import ShipingCostRevenue from './ShipingCostRevenue'
import Loader from '../../common/Loader';
import Performance from './Performance';
import { getMerchant } from '../../helpers/ReduxHelpers';

export default function ShippingOverview({ dateRange, shipType }) {
  const { fromDate: from_date, toDate: to_date } = { fromDate: dateRange[0].startDate, toDate: dateRange[0].endDate };

  const requestPayload = {
    from_date: getYYYYMMDD(from_date),
    to_date: getYYYYMMDD(to_date),
    merchant_list: getMerchant().id,
    shipmentType_list: shipType
  };

  const skip = !from_date || !to_date;

  const { data, isLoading, isFetching } = useGetAllShipmentsWithPickupAndDeliveryDataQuery(requestPayload, { skip });

  const allRegistedShipments = (data || []).map((i) => ({ ...i, price: Number(i.price) || 0 }));
  

  if (isLoading || isFetching) {
    return <Loader />
  }

  const scatterData = {
    count: allRegistedShipments?.length || 0,
    data: _(allRegistedShipments || []).map(function (item) {
      /* const awbRegistered = item?.order_history?.find(his => his?.orderStatusCode === 28)
      if (awbRegistered && awbRegistered.timestamp) {
        const order_date = formatDate(awbRegistered.timestamp, 'yyyy-MM-dd')
        return { ...item, order_date };
      } */

      return item;
    }).value()
  }

  return (
    <>
      <OrdersOrgChart data={scatterData} />
      <ShipingCostRevenue data={scatterData} from_date={from_date} to_date={to_date} />
      <ZoneWiseRevenueShipment scatterData={scatterData} />
      <Performance data={scatterData} />
    </>
  )
}

function OrdersOrgChart({ data: { data = [] } }) {
  let newObject = []
  const total = {
    title: 'Total Shipments',
    id: 1,
    count: data.length || 0,
  };

  const placed = {
    title: 'Shipments Placed',
    id: 2,
    parentId: 1,
    count: 0
  }

  const inTransit = {
    title: 'In-Transit',
    id: 3,
    parentId: 1,
    count: 0
  }

  const delivered = {
    title: 'Delivered',
    id: 4,
    parentId: 1,
    count: 0
  }

  const rto = {
    title: 'RTO',
    id: 5,
    parentId: 1,
    count: 0
  }

  let lastId = 6;
  const groupedData = _.groupBy(data, 'orderStatus')
  let rtoOrdersCost = 0
  for (const k in groupedData) {
    if (k === 'undefined' || k === 'UNPROCESSED') continue;
    const orderStatus = getOrderStatus(k);
    let parentId;
    if (orderStatus === 'ORDER_PLACED') {
      placed.count += groupedData[k].length
      parentId = placed.id
    } else if (orderStatus === 'INTRANSIT' || orderStatus === 'DELIVERY_FAILED') {
      inTransit.count += groupedData[k].length
      parentId = inTransit.id
    } else if (orderStatus === 'DELIVERED') {
      delivered.count += groupedData[k].length
      parentId = delivered.id
    } else if (orderStatus === 'RTO' || orderStatus === 'LOST' || orderStatus === 'CANCELLED') {
      if (orderStatus === 'RTO') {
        const rtoCost = _.sumBy(groupedData[k], 'price')
        rtoOrdersCost += rtoCost || 0
      }
      rto.count += groupedData[k].length
      parentId = rto.id
    }

    if (orderStatus !== 'DELIVERED') {
      newObject.push({
        id: lastId,
        parentId,
        count: groupedData[k].length,
        title: _.startCase(k)
      })

      lastId++;
    }
  }

  const chartData = [...newObject, placed, delivered, rto, inTransit, total]

  const revenueSummary = {
    totalRevenue: _(data).filter((val) => ![...rtoStatus, ...cancelledStatus, ...lost].includes(val.orderStatus)).sumBy('invoiceValue'),
    totalShipmentCost: Math.round(_(data).filter((val) => !cancelledStatus.includes(val.orderStatus)).sumBy('price')),
    totalShipments: data.length,
    totalRTOCost: rtoOrdersCost,
    totalLostShipmentsCost: _.sumBy(_.filter(data, { orderStatus: 'LOST' }), 'invoiceValue')
  }

  return (
    <>
      <RevenueSummary data={revenueSummary} />
      <OrganizationalChart data={chartData} />
    </>
  )
}

function ZoneWiseRevenueShipment({ scatterData }) {
  const data = _.groupBy(scatterData?.data || [], 'merchantZone')

  const chartData = Object.keys(data).map((k) => {
    const totalRevenue = _.sumBy(data[k], 'invoiceValue')
    const totalShiping = _.sumBy(data[k], 'price')

    const per = totalShiping ? (totalShiping / totalRevenue) * 100 : 0
    return { label: k === 'REGIONAL' ? 'WITHIN STATE' : k, totalRevenue, totalShiping, per }
  })

  return (
    <div className="card">
      <div className="card-title mb-5">Revenue - Shipping Cost Zone Wise Split</div>
      <div className="row">
        {
          chartData.map((row, i) => {
            if (row.label === 'undefined') return null
            let dim = (220 * row.per) / 100
            dim = 80 + (dim * 2)
            dim = (dim > 220) ? 220 : dim

            return (
              <div className="col justify-center" key={i}>
                <div className="circleParent mb-2">
                  <h6 className="text-center">{row.label} <br />{formatCurrency(Math.round(row.totalRevenue))}</h6>
                  <div className="circleChild" style={{ width: dim, height: dim }}>
                    <p className="text-white m-0">{formatCurrency(Math.round(row.totalShiping || 0))}</p>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}