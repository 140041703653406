import { eachDayOfInterval } from "date-fns/esm";
import _ from "lodash"
import { apiConstants, postGeneric } from "../../common/constants";
import { formatDate } from "../../helpers/UtilityHelper";
import { addDays } from "date-fns";
import { getMerchant } from "../../helpers/ReduxHelpers";

const EXCLUDED_PROPS = "additionalData,merchantPricing.pricingObj,returnInfo,merchant,courier,courierPricing,itemList,transactionCharge,giftwrapCharge,shipment_type,deliveryDetails.to_address,deliveryDetails.to_country,deliveryDetails.to_name,pickupDetails.from_name,pickupDetails.from_phone_number,pickupDetails.from_address,pickupDetails.from_country,pickupDetails.from_email,pickupDetails.gstin,orderId,skipRule,giftwrap_charge,transactionCharge,clientOrderId,overriddenCourierRule,actualCourierProviderName,giftwrapCharge,overriddenCourierRule,transactionCharge,skipRule,createdDate,lastModifiedDate,createdByUser,modifiedByUser,merchantId,skipRule,version,overriddenCourierRule,dispatchMode,channelName,invoiceNumber,transactionCharge,giftwrapCharge,securityKey,invoiceNumber,channelType,invoiceUrl,labelUrl,referenceNumber,securityKey,sortCode,mongoInvoiceSignedUrlDetails,mongoLabelSignedUrlDetails,customerDetail,merchant,orderManifest,merchantPricing.merchantRateCard,courierPricingRto,ndrData,merchantPricingRto.merchantRateCard,manualSyncDate,lastStatusUpdateTime,courierPricingRTO,order_history.courierPartnerEdd,order_history.remark,order_history.courierPartnerId,order_history.currentLocation,terminalStatus"

async function getShipmentsByParallelRequests(data) {

    const responseData = { count: 0, data: [] };

    try {

        if (!data.from_date || !data.to_date || !data.shipType) {
            return { data: responseData }
        }


        const days = eachDayOfInterval({
            start: new Date(data.from_date),
            end: new Date(data.to_date),
        })

        const chunks = _.chunk(days, 2)
        const responses = await Promise.all(
            chunks.map(
                ([from_date, to_date]) => postGeneric(
                    apiConstants.ORDER_LISTS,
                    getRequestObject(from_date, to_date, data.shipType, data)
                )
            ))

        for (const { data } of responses) {
            responseData.count += data.count
            responseData.data = [...responseData.data, ...data.data]
        }

    } catch (err) {
        // console.log(data)
        console.log(err);
    }

    return { data: responseData };
}

function getRequestObject(from_date, to_date, shipType, data) {
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''


    let toDate = to_date ? to_date : from_date
    toDate = addDays(toDate, 1);
    toDate = formatDate(toDate, 'yyyy-MM-dd HH:mm:ss')

    const obj = {
        excludes: EXCLUDED_PROPS,
        "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
        andfilter: [
            { field: "awbRegisteredDate", operator: "gte", value: `Date('${formatDate(from_date, 'yyyy-MM-dd HH:mm:ss')}')` },
            { field: "awbRegisteredDate", "operator": "lt", value: `Date('${toDate}')` },
            { field: "orderSubStatus", operator: "ne", value: "UNPROCESSED" },
            // { field: "shipmentType", operator: "ne", value: "B2B" },
            { field: "shipmentType", operator: "eq", value: shipType },
            { field: "merchant.$id", operator: "eq", value: merchantId },
            ...(data?.filters || [])
        ],
        offset: 0,
        limit: 1500000,
        skipCount: true
    }

    if (data.includes) {
        delete obj.excludes
        obj.includes = data.includes
    }

    return obj
}

export default getShipmentsByParallelRequests;