import React, { useState } from "react";
import withLayout from "../../hocs/Layout";
import { getMerchant } from '../../helpers/ReduxHelpers';
import { apiConstants, postGeneric } from '../../common/constants';
import { useFieldArray, useForm } from "react-hook-form";
import RateList from "./rateList";
import { Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Typography } from "@mui/material";
import { FormProvider, RHFRadioGroup, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import _ from 'lodash'
import PageTitle from "../../common/PageTitle";
import { useSnackbar } from "notistack"

const ShipmentRateCalculatorPTL = () => {
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''


    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [rateListing, setRateList] = useState(null);

    const onSubmit = (data) => {
        let boxDetails = []
        if (data.list) {
            boxDetails = _.map(data.list, (item, index) => ({
                item_name: `Box${index + 1}`,
                units: item.count,
                additional: {
                    length: item.length,
                    breadth: item.breadth,
                    height: item.height,
                    weight: item.weight * 1000
                }
            }));
        }
        const totalWeight = _.sumBy(boxDetails, (item) => item.additional.weight * item.units);
        const totalLength = _.sumBy(boxDetails, (item) => item.additional.length * item.units);
        const totalBreadth = _.sumBy(boxDetails, (item) => item.additional.breadth * item.units);
        const totalHeight = _.sumBy(boxDetails, (item) => item.additional.height * item.units);
        setLoading(true);
        const isAppointmentBased = (data.isAppointmentBased === "true");
        const isInsured = (data.isInsured === "true")
        let payload = Object.assign(
            {},
            data,
            {
                "isMerchant": true,
                "merchantOid": merchantId,
                "shipmentType": 'B2B',
                "debug": true,
                "weight": data.weight * 1000,
                "invoiceValue": data.codAmount
            },
            {
                "codAmount": (data.paymentType === "COD") ? data.codAmount : 0,
                "boxDetails": boxDetails,
                "weight": totalWeight,
                "length": totalLength,
                "breadth": totalBreadth,
                "height": totalHeight,
                "isAppointmentBased": isAppointmentBased,
                "isInsured": isInsured,
            },

        )
        delete payload.list
        // return false;
        // setRateList([]);
        postGeneric(apiConstants.SHIPMENT_RATE_CALCULATOR, payload).then(response => {
            if (!response?.data[0]?.message) {
                setRateList(response?.data);
                enqueueSnackbar("Done", { variant: "success" })
            } else {
                enqueueSnackbar(response?.data[0]?.message, { variant: "error" })
            }
        })
            .catch((err) => {
                enqueueSnackbar(err.response?.data?.message, { variant: "error" });
            })
            .finally(() => setLoading(false))
    }

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                orderType: Yup.string().required("Order Type is required!"),
                paymentType: Yup.string().required("Payment Type is required!"),
                from: Yup.number().typeError('Pickup pincode must be valid').positive().typeError('Pickup pincode must be valid').required("Pickup pincode is required!"),
                to: Yup.number().typeError('Destination pincode must be valid').positive().typeError('Destination pincode must be valid').required("Destination pincode is required!"),
                codAmount: Yup.number().typeError('COD Amount must be valid').positive().typeError('COD Amount must be valid').required("COD Amount is required!"),
                list: Yup.array().of(
                    Yup.object().shape({
                        count: Yup.number().typeError('Box count must be valid').positive().typeError('Box count must be valid').required("Box count is required!"),
                        length: Yup.number().typeError('Shipment length must be valid').positive().typeError('Shipment length must be valid').required("Shipment length is required!"),
                        breadth: Yup.number().typeError('Shipment breadth must be valid').positive().typeError('Shipment breadth must be valid').required("Shipment breadth is required!"),
                        height: Yup.number().typeError('Shipment height must be valid').positive().typeError('Shipment height must be valid').required("Shipment height is required!"),
                        weight: Yup.number().typeError('Shipment weight must be valid').positive().typeError('Shipment weight must be valid').required("Shipment weight is required!"),
                    })
                ),
            })
        ),
        defaultValues: {
            paymentType: 'PREPAID',
            orderType: 'FORWARD',
            from: '160001',
            to: '250001',
            length: '',
            breadth: '',
            height: '',
            weight: '',
            codAmount: '100',
            isInsured: 'true',
            isAppointmentBased: "false",
            isCarrierRisk: 'false',
            codType: 'CASH',
            list: [
                {
                    count: "",
                    length: "",
                    breadth: "",
                    height: "",
                    weight: "",
                },
            ],
            // codType: 'CASH'
        }
    })
    const { handleSubmit, control, register, errors, watch } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });

    const handleInputChange = (e) => {
        let input = e.target.value;
        input = input.replace(/[^0-9.]/g, '');
        const decimalCount = (input.match(/\./g) || []).length;
        if (decimalCount > 1) {
            input = input.slice(0, input.lastIndexOf('.'));
        }
        const parts = input.split('.');
        if (parts[1] && parts[1].length > 2) {
            input = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
        e.target.value = input;
    };

    return (
        <>
            <div id="main-content" className="container-fluid">
                <PageTitle>PTL Rate Calculator</PageTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card>
                            <CardHeader title={(
                                <>
                                    {/* <Typography variant="h6">PTL Shipment Rate Calculator</Typography> */}
                                    <Typography>Enter the following details to calculate your shipment rate</Typography>
                                </>
                            )} />
                            <CardContent>
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        {/* <Grid item xs={12} md={4}>
                                            <RHFRadioGroup color="warning" label="Shipment Type *" name="orderType" options={[{
                                                label: 'Forward',
                                                value: 'FORWARD'
                                            }, {
                                                label: 'Reverse',
                                                value: 'RVP'
                                            }]} />
                                        </Grid> */}
                                        <Grid item xs={12} md={4}>
                                            <RHFTextField color="warning" name="from" label="Pickup Area Pincode *" />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <RHFTextField color="warning" name="to" label="Delivery Area Pincode *" />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <RHFTextField color="warning" name="codAmount" label="Invoice Value *" />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <RHFRadioGroup color="warning" label="Appointment Delivery *" name="isAppointmentBased" options={[{
                                                label: 'No',
                                                value: "false"
                                            }, {
                                                label: 'Yes',
                                                value: "true"
                                            }]} />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <RHFRadioGroup color="warning" label="Insured *" name="isInsured" options={[{
                                                label: 'No',
                                                value: "false"
                                            }, {
                                                label: 'Yes',
                                                value: "true"
                                            }]} />
                                        </Grid>
                                        {watch("isInsured") === "false" && (
                                            <Grid item xs={12} md={2}>
                                                <RHFRadioGroup
                                                    color="warning"
                                                    label="Risk Carrier *"
                                                    name="isCarrierRisk"
                                                    options={[
                                                        { label: 'Carrier', value: 'true' },
                                                        { label: 'Self', value: 'false' },
                                                    ]}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={2}>
                                            <RHFRadioGroup color="warning" label="Payment Mode *" name="paymentType" options={[{
                                                label: 'Prepaid',
                                                value: 'PREPAID'
                                            }, {
                                                label: 'COD',
                                                value: 'COD'
                                            }]} />
                                        </Grid>
                                        {watch("paymentType") === "COD" && (
                                            <Grid item xs={12} md={3}>
                                                <RHFRadioGroup color="warning" label="COD Mode *" name="codType" options={[
                                                    { label: 'Cash', value: 'CASH' },
                                                    { label: 'Cheque', value: 'CHEQUE' },
                                                    { label: 'Draft', value: 'DRAFT' }
                                                ]} />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        {fields.map((field, index) => (
                                            <>
                                                <Grid item xs={6} md={2} key={`count.${index}`}>
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        name={`list[${index}].count`}
                                                        type="number"
                                                        color="warning"
                                                        label="No of boxes"
                                                        {...register(`list[${index}].count`)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={2} key={`weight.${index}`}>
                                                    {/* <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="number"
                                                        color="warning"
                                                        name={`list[${index}].weight`}
                                                        label="Weight (Per Box in Kg)"
                                                        {...register(`list[${index}].weight`)}
                                                    /> */}
                                                    {/* <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="text"
                                                        inputProps={{
                                                            pattern: "[0-9]*[.,]?[0-9]+" // to allow decimal numbers
                                                        }}
                                                        color="warning"
                                                        name={`list[${index}].weight`}
                                                        label="Weight (Per Box in Kg)"
                                                        {...register(`list[${index}].weight`)}
                                                    /> */}
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="text"
                                                        color="warning"
                                                        name={`list[${index}].weight`}
                                                        label="Weight (Per Box in Kg)"
                                                        onInput={handleInputChange}
                                                        {...register(`list[${index}].weight`)}

                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={2} key={`length.${index}`}>
                                                    {/* <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="text"
                                                        inputProps={{
                                                            pattern: "[0-9]*[.,]?[0-9]+" // to allow decimal numbers
                                                        }}
                                                        color="warning"
                                                        name={`list[${index}].length`}
                                                        label="Length (cm)"
                                                        {...register(`list[${index}].length`)}
                                                    /> */}
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="text"
                                                        color="warning"
                                                        name={`list[${index}].length`}
                                                        label="Length (cm)"
                                                        onInput={handleInputChange}
                                                        {...register(`list[${index}].length`)}
                                                    />

                                                </Grid>
                                                <Grid item xs={6} md={2} key={`breadth.${index}`}>
                                                    {/* <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="number"
                                                        inputProps={{
                                                            inputMode: 'decimal',
                                                            pattern: "[0-9]*[.,]?[0-9]+" // to allow decimal numbers
                                                        }}
                                                        color="warning"
                                                        name={`list[${index}].breadth`}
                                                        label="Breadth (cm)"
                                                        {...register(`list[${index}].breadth`)}
                                                    /> */}
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="text"
                                                        color="warning"
                                                        name={`list[${index}].breadth`}
                                                        label="Breadth (cm)"
                                                        onInput={handleInputChange}
                                                        {...register(`list[${index}].breadth`)}
                                                    />

                                                </Grid>
                                                <Grid item xs={6} md={2} key={`height.${index}`}>
                                                    {/* <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="text"
                                                        inputProps={{
                                                            pattern: "[0-9]*[.,]?[0-9]+" // to allow decimal numbers
                                                        }}
                                                        color="warning"
                                                        name={`list[${index}].height`}
                                                        label="Height (cm)"
                                                        {...register(`list[${index}].height`)}
                                                    /> */}
                                                    <TextField
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        type="text"
                                                        color="warning"
                                                        name={`list[${index}].height`}
                                                        label="Height (cm)"
                                                        onInput={handleInputChange}
                                                        {...register(`list[${index}].height`)}
                                                    />

                                                </Grid>
                                                {fields.length !== 1 && (
                                                    <Grid item xs={12} md={1}>
                                                        <Button
                                                            onClick={() => remove(index)}
                                                            color="error"
                                                            variant="contained"
                                                            startIcon={<RemoveCircleOutlineIcon />}
                                                        >

                                                        </Button>
                                                    </Grid>
                                                )}
                                                {fields.length === index + 1 && (
                                                    <Grid item xs={6} md={1}>
                                                        <Button
                                                            onClick={() =>
                                                                append({
                                                                    count: "",
                                                                    length: "",
                                                                    breadth: "",
                                                                    height: "",
                                                                    weight: "",
                                                                })
                                                            }
                                                            variant="contained"
                                                            sx={{mt:1}}
                                                            startIcon={<AddIcon sx={{ml:1.5}} />}
                                                        >

                                                        </Button>
                                                        {/* {data?.length > 1 ? <Typography color='error' variant="h6">Please click to view more</Typography> : ""} */}
                                                    </Grid>
                                                )}
                                            </>
                                        ))}
                                        {errors?.list && (
                                            <>
                                                {errors.list.map((error, index) => (
                                                    <div key={`error-${index}`}>
                                                        {error.weight && (
                                                            <Typography color="error" variant="body2">
                                                                {error.weight.message}
                                                            </Typography>
                                                        )}
                                                        {error.length && (
                                                            <Typography color="error" variant="body2">
                                                                {error.length.message}
                                                            </Typography>
                                                        )}
                                                        {error.breadth && (
                                                            <Typography color="error" variant="body2">
                                                                {error.breadth.message}
                                                            </Typography>
                                                        )}
                                                        {error.height && (
                                                            <Typography color="error" variant="body2">
                                                                {error.height.message}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {/* <Button onClick={() => resetField()} type="button" variant="outlined" color="error" size="large">Reset</Button> */}
                                            <LoadingButton loading={isLoading} /* sx={{ ml: 1 }} */ type="submit" variant="contained" color="warning" size="large">Calculate</LoadingButton>
                                        </Grid>
                                    </Grid>
                                </FormProvider>
                            </CardContent>
                        </Card>
                    </Grid>

                    {rateListing && (
                        <Grid item xs={12} md={12}>
                            <RateList rateListing={rateListing} />
                        </Grid>
                    )}
                </Grid>
            </div>

        </>
    );
};

export default withLayout(ShipmentRateCalculatorPTL);