import React, { useState } from "react";
import withLayout from "../../hocs/Layout";
import { getMerchant } from '../../helpers/ReduxHelpers';
import { apiConstants, postGeneric } from '../../common/constants';
import { useForm } from "react-hook-form";
import RateList from "./rateList";
import { Card, CardContent, CardHeader, Divider, Grid, InputAdornment, Typography } from "@mui/material";
import { FormProvider, RHFRadioGroup, RHFTextField } from "../../common/hook-form";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import PageTitle from "../../common/PageTitle";

const ShipmentRateCalculator = () => {
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''

    const [isLoading, setLoading] = useState(false);
    const [rateListing, setRateList] = useState(null);

    const onSubmit = (data) => {
        setLoading(true);
        const payload = Object.assign(
            {},
            data,
            {
                "isMerchant": true,
                "merchantOid": merchantId
            },
            {
                "codAmount": (data.paymentType === "COD") ? data.codAmount : 0
            }
        )


        setRateList([]);
        postGeneric(apiConstants.SHIPMENT_RATE_CALCULATOR, payload).then(response => {
            setRateList(response.data);
        })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false))
    }

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                orderType: Yup.string().required("Order Type is required!"),
                paymentType: Yup.string().required("Payment Type is required!"),
                from: Yup.number().typeError('Pickup pincode must be valid').positive().typeError('Pickup pincode must be valid').required("Pickup pincode is required!"),
                to: Yup.number().typeError('Destination pincode must be valid').positive().typeError('Destination pincode must be valid').required("Destination pincode is required!"),
                length: Yup.number().typeError('Shipment length must be valid').positive().typeError('Shipment length must be valid').required("Shipment length is required!"),
                breadth: Yup.number().typeError('Shipment breadth must be valid').positive().typeError('Shipment breadth must be valid').required("Shipment breadth is required!"),
                height: Yup.number().typeError('Shipment height must be valid').positive().typeError('Shipment height must be valid').required("Shipment height is required!"),
                weight: Yup.number().typeError('Shipment weight must be valid').positive().typeError('Shipment weight must be valid').required("Shipment weight is required!"),
                codAmount: Yup.number().typeError('COD Amount must be valid').positive().typeError('COD Amount must be valid').required("COD Amount is required!"),
            })
        ),
        defaultValues: {
            paymentType: 'PREPAID',
            orderType: 'FORWARD',
            from: '',
            to: '',
            length: '',
            breadth: '',
            height: '',
            weight: '',
            codAmount: '',
        }
    })
    const { handleSubmit: handleSubmitForm, /* resetField */ } = methods

    return (
        <>
            <div id="main-content" className="container-fluid">
            <PageTitle>D2C Rate Calculator</PageTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader title={(
                                <>
                                    <Typography variant="h6">Shipment Rate Calculator</Typography>
                                    <Typography>Enter the following details to calculate your shipment rate</Typography>
                                </>
                            )} />
                            <CardContent>
                                <FormProvider methods={methods} onSubmit={handleSubmitForm(onSubmit)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFRadioGroup color="warning" label="Shipment Type *" name="orderType" options={[{
                                                label: 'Forward',
                                                value: 'FORWARD'
                                            }, {
                                                label: 'Reverse',
                                                value: 'RVP'
                                            }]} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFRadioGroup color="warning" label="Payment Mode *" name="paymentType" options={[{
                                                label: 'Prepaid',
                                                value: 'PREPAID'
                                            }, {
                                                label: 'COD',
                                                value: 'COD'
                                            }]} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField color="warning" name="from" label="Pickup Area Pincode *" />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <RHFTextField color="warning" name="to" label="Delivery Area Pincode *" />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <RHFTextField color="warning" name="length" label="Length *" InputProps={{
                                                endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                            }} />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <RHFTextField color="warning" name="breadth" label="Breadth *" InputProps={{
                                                endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                            }} />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <RHFTextField color="warning" name="height" label="Height *" InputProps={{
                                                endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                            }} />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <RHFTextField color="warning" name="weight" label="Weight *" InputProps={{
                                                endAdornment: <InputAdornment position="start">gm</InputAdornment>,
                                            }} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <RHFTextField color="warning" name="codAmount" label="Invoice Amount *" />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            {/* <Button onClick={() => resetField()} type="button" variant="outlined" color="error" size="large">Reset</Button> */}
                                            <LoadingButton loading={isLoading} /* sx={{ ml: 1 }} */ type="submit" variant="contained" color="warning" size="large">Calculate</LoadingButton>
                                        </Grid>
                                    </Grid>
                                </FormProvider>
                            </CardContent>
                        </Card>
                    </Grid>
                    {rateListing && (
                        <Grid item xs={12} md={6}>
                            <RateList rateListing={rateListing} />
                        </Grid>
                    )}
                </Grid>
            </div>

        </>
    );
};

export default withLayout(ShipmentRateCalculator);