import React from "react"
import { addMonths, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isWithinInterval } from 'date-fns'
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector"
import { flattenResponseData, formatDate, getOrderStatus, getShipmentInfo, getYYYYMMDD, lost, numberPrecision } from "../../../../helpers/UtilityHelper"
import _ from 'lodash'
import { useGetSelectedFilters } from "../../../../common/contexts/Filters"
import { getMerchant } from "../../../../helpers/ReduxHelpers"
import { useGenericPostRequestQuery } from "../../../../redux/commonRTK"
import { apiConstants } from "../../../../common/constants"
import useGetCourier from '../useGetCourier'
import { addDays } from "date-fns"
import { addWeeks } from "date-fns"

const weekStartsOn = 1

export default function useLostPerformance(groupOn = 'WOW', shipmentType) {
    const { id } = getMerchant()
    const { from_date, to_date } = useGetSelectedDateRange()
    const filters = useGetSelectedFilters()
    // const { userInfo } = useSelector((state) => state.user)
    // const shipmentInfo = getShipmentInfo(userInfo, shipmentType);
    const merchant = getMerchant();
    const shipmentInfo = getShipmentInfo(merchant, shipmentType);  


    const skip = !from_date || !to_date
    const request = { from_date, to_date, merchant_list: id, orderType_list: 'FORWARD', shipmentType_list: shipmentInfo }
    const { data: allRegistered, isLoading: isLoadingReg, isFetching: isFetchingReg } = useGenericPostRequestQuery({
        data: request,
        url: apiConstants.ALL_AWB_REGISTERED_BY_DATE_RANGE
    }, { skip })

    const { data: allCouriers, isLoading: isLoadingCouriers, isFetching: isFetchingCouriers } = useGetCourier()
    const [isCalc, setIsCalc] = React.useState(true)

    const results = React.useMemo(() => {

        if (isFetchingReg || isLoadingReg || isFetchingCouriers || isLoadingCouriers) {
            setIsCalc(true)
            return;
        }

        const allRegisteredData = _.filter(flattenResponseData(allRegistered), filters)
        const allLostData = _.filter(allRegisteredData, function (item) {
            return lost.includes(getOrderStatus(item.orderStatus))
        })

        let intervalArr = [],
            lineChartData = [],
            allLostOrdersCount = allLostData.length,
            allRegisteredCount = allRegisteredData.length,
            allUniqCouriers = [];

        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1)
            intervalArr = eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1)
            intervalArr = eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1)
            intervalArr = eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        }

        const allLostDataGroupedByDate = _.groupBy(allLostData, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })

        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index]
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : new Date()

            let name = null;
            if (groupOn === 'DOD') {
                name = formatDate(start, 'dd MMM')
            } else if (groupOn === 'WOW') {
                name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`
            } else {
                name = formatDate(start, 'MMM')
            }

            const row = { name }

            const allLostDatesBetween = _.filter(Object.keys(allLostDataGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })

            const allLostDataBetweenDate = allLostDatesBetween.map(key => allLostDataGroupedByDate[key]).flat()

            row.totalLostCases = allLostDataBetweenDate.length
            row.lostPercentage = Number(numberPrecision((allLostDataBetweenDate.length / allLostOrdersCount) * 100))
            row.lostRevenue = _.sumBy(allLostDataBetweenDate, 'invoiceValue')

            // Calculating lost zone wise split
            const lostGroupedByZone = _(allLostDataBetweenDate)
                .groupBy('merchantZone')
                .map(function (data, zone) {
                    return { zone, value: data.length }
                }).value()

            for (const data of lostGroupedByZone) {
                row[data.zone] = data.value
            }

            const lostGroupedByCourier = _(allLostDataBetweenDate)
                .groupBy('courier')
                .map(function (data, id) {
                    const courier = _.find(allCouriers, { id })
                    let courierName = 'NA'
                    if (courier) {
                        courierName = `${courier.parent}/${courier.id}`
                    }
                    return { name: courierName, value: data.length }
                }).value()

            for (const data of lostGroupedByCourier) {
                if (!allUniqCouriers.includes(data.name)) {
                    allUniqCouriers.push(data.name)
                }
                row[`C_${data.name}`] = data.value
            }

            lineChartData.push(row)
        }

        const allLostGroupByCourier = _(allLostData)
            .groupBy('courier')
            .map(function (data, key) {
                const lostCount = data.length
                const courier = _.find(allCouriers, { id: key })
                const lostPercentage = Number(numberPrecision((lostCount / allLostOrdersCount) * 100))
                return { name: (courier?.name || "").replace(/_/g, " "), count: lostCount, percentage: lostPercentage }
            }).value()

        const allLostGroupByZone = _(allLostData)
            .groupBy('merchantZone')
            .map(function (data, key) {
                const lostCount = data.length
                const lostPercentage = Number(numberPrecision((lostCount / allLostOrdersCount) * 100))
                return { name: key, count: lostCount, percentage: lostPercentage }
            }).value()

        const totalLostValue = _.sumBy(allLostData, 'invoiceValue')
        const totalLostPercentage = Number(numberPrecision((allLostOrdersCount / allRegisteredCount) * 100))
        const result = { lineChartData, total: allRegisteredCount, lost: allLostOrdersCount, totalLostValue, totalLostPercentage, allUniqCouriers, allLostGroupByCourier, allLostGroupByZone, allLostData }

        setIsCalc(false)

        return result;

    }, [from_date, to_date, allRegistered, isLoadingReg, isFetchingReg, groupOn, isFetchingCouriers, isLoadingCouriers, allCouriers, filters])

    if (isCalc) return { isLoading: isCalc }
    return { data: results, isLoading: isCalc };
}