// ImageCarousel.js
import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    ImageList,
    ImageListItem,
} from '@mui/material';


function ImageCarousel({ open, onClose, images }) {
    return (<>
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle sx={{ padding: 0, margin: 0 }}>
                <IconButton
                    aria-label="close"
                    onClick={() => onClose(false)}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: 0,
                    margin: 0,
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                    },
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#888 #f1f1f1',
                }}>
                <ImageList sx={{ width: '100%', height: 'auto', padding: 0, margin: 0 }} cols={1} rowHeight={400}>
                    {images && images.length > 0 ? (
                        images.map((img, index) => (
                            <ImageListItem key={index} sx={{ width: '100%', height: '100%', padding: 0, margin: 0 }}>
                                <img src={img} alt={`Item ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </ImageListItem>
                        ))
                    ) : (
                        <ImageListItem sx={{ width: '100%', height: '100%', padding: 0, margin: 0 }}>
                            <img src={images} alt="Selected Item" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </ImageListItem>
                    )}
                </ImageList>
            </DialogContent>
        </Dialog>
    </>)
}
export default ImageCarousel;
