/** @format */

import axios from "axios";
// import jwt_decode from "jwt-decode";

import { endOfMonth, startOfMonth, subDays, subMonths } from "date-fns";
import { formatDate } from "../helpers/UtilityHelper";
export const apiConstants = {
  BASE_LOCAL: process.env.REACT_APP_API_ENDPOINT,
  // BASE_LOCAL: "https://proship.prozo.com/api/",
  //  BASE_LOCAL: 'http://localhost:8099/api/',
  AUTH: "auth/signin",
  DASHBOARD_ORDER_SUMMARY: "aggregate/orders/",
  ORDER_DETAILS: "order/getOrder",
  ORDER_LISTS: "order/search",
  MANIFEST_LISTS: "manifest/search",
  ORDER_LISTS_CACHED: "order/searchCached",
  CHANNEL_ORDER_SEARCh: "channel/order/search",
  TRACK_BULK_UPLOAD_LISTS: "order/track_uploaded_orders",
  TRACK_ORDER: "order/track_waybill",
  SERVICEABILITY_PINCODE: "tools/serviceability",
  FORGOT_PASSWORD: "auth/reset_password",
  RESET_PASSWORD: "auth/set_password",
  SYNC_ORDER: "order/refreshWaybillStatus",
  COURIER_LIST: "courierPartner/search",
  SIGNUP: "merchant/create",
  DOWNLOAD_ALL_ORDER: "report/orders",
  GET_MERCHANT: "merchant",
  GET_MERCHANT_SEARCH: "merchant/search",
  UPDATE_MERCHANT: "merchant/update_bankDetails",
  ADD_LOCATION: "merchant/register_pickup",
  DOWNLOAD_SAMPLE_LOCATION: "order/Download_samples",
  NDR_BULK_UPLOAD: "ndr/bulkAction",
  NPR_BULK_UPLOAD: "npr/bulkAction",
  NPR_BULK_UPLOAD_V2: "npr/newBulkAction",
  UPLOAD_LOCATION: "merchant/upload_pickup_locations",
  UPLOAD_MERCHANT_LOGO: "merchant/uploadlogo",
  CREATE_USER: "auth/user_signup",
  NDR_LISTS: "order/search",
  NPR_LISTS: "npr/search",
  CANCEL_ORDER: "order/cancel_order",
  BULK_UPLOAD_ORDER: "order/upload_orders",
  B2B_ORDER_FETCH_LABEL: "order/async/b2b/fetch_label",
  BULK_MERCHANT_SERVICEABILITY: "tools/serviceability_bulk",
  GET_MERCHANT_USERS: "auth/users",
  DOWNLOAD_NDR: "order/downloadNdrReport",
  NDR_ACTION: "order/ndrActionUpdate",
  GET_ALL_WALLET: "wallet/merchantWallet",
  GET_WALLET_HISTORY: "wallet/history",
  GET_WALLET_AUDIT: "wallet/audit",
  // AGGREGATES NDR
  AGGREGATE_NDR_REASON: "aggregate/ndr_orders/NDR_REASON/",
  AGGREGATE_NDR_DELIVERY_PINCODE: "aggregate/ndr_orders/DELIVERY_PINCODE/",
  AGGREGATE_NDR_STATUS: "aggregate/ndr_orders/NDR_STATUS/",
  AGGREGATE_PAYMENT_MODE: "aggregate/ndr_orders/PAYMENT_MODE/",
  AGGREGATE_NDR_AGE: "aggregate/ndr_orders/NDR_AGE/",
  AGGREGATE_NDR_OFD_COUNT: "aggregate/ndr_orders/NDR_OFD_COUNT/",
  AGG_ORDERS_DAY_WISE_COURIER: "aggregate/orders/DAYWISE_COURIER/",
  AGG_RTO_ORDERS_BY_STATUS: "aggregate/rto_orders/ORDER_STATUS/",
  AGG_RTO_ORDERS_BY_CITY: "aggregate/rto_orders/CITY/",
  AGG_RTO_ORDERS_BY_PINCODES: "aggregate/rto_orders/DELIVERY_PINCODE/",
  AGG_ORDER_ITEM_WEIGHT: "aggregate/orders/ITEM_WEIGHT/",
  AGG_ORDER_BY_STATE: "aggregate/orders/STATE/",
  AGG_ORDER_BY_PAYMENT_MODE: "aggregate/orders/PAYMENT/",
  AGG_ORDER_BY_PICKUP_PINCODE: "aggregate/orders/PICKUP_PINCODE/",
  AGG_ORDER_BY_STATUS: "aggregate/orders/STATUS/",
  AGG_RTO_BY_COURIER: "aggregate/rto_orders/COURIER/",
  //Wallet payment
  ADD_WALLET_BALANCE: "wallet/addbalance",
  VERIFY_PAYMENT: "wallet/verifypayment",
  //Change password
  CHANGE_PASSWORD: "auth/change_password",
  AGG_ORDER_BY_STATUS_CUSTOM: "aggregate/orders/custom/STATUS_RANGE/",
  SHIPMENT_RATE_CALCULATOR: "tools/list/pricing",
  MERCHANT_ACTIVE_SLA: "tools/activesla",
  COURIERS: "courierPartner",
  LIST_MODULES: "auth/listmodules",
  UPDATE_USER_ROLE: "auth/update_role",
  UPDATE_USER_ACTION: "auth/accountAction",
  AGG_ORDER_ZONE: "aggregate/orders/ZONE/",
  REPORT_GENERIC_SEARCH: "tools/generic/search",
  CONFIG_SEARCH: "config/search",
  //Notification
  POST_SUBSCRIPTION: "merchant/subscription",
  GET_SUBSCRIPTION: "merchant/merchant_notifications",
  POST_SUBSCRIPTION_B2B: "merchant/subscription/b2b",
  GET_SUBSCRIPTION_B2B: "merchant/merchant_notifications_b2b",
  //Get User Profile
  GET_USER: "auth/search",
  UPLOAD_PROFILE_PIC: "merchant/user/uploadPic/",
  //Wallet Transactions
  ORDER_TRANSACTION: "wallet/gettransactionsfororders",
  OTHER_TRANSACTION: "wallet/gettransactionsnonorder",
  //Download Reports
  DOWNLOAD_REPORT: "reports/get",
  CREATE_ORDER: "order/create",
  ASYNC_B2B_CREATE_ORDER: "async/b2b/order/create",
  BULK_CREATE_ORDER: "order/Bulk_order_create",
  BULK_CREATE_ORDER_v2: "order/v2/bulk_order_create_B2C",
  BULK_CREATE_ORDER_B2B: "async/b2b/order/Bulk_order_create",
  GET_BULK_UPLOAD_ORDER: "order/get_bulkOrder_result",
  GET_BULK_UPLOAD_NDR: "ndr/bulkActionStatus",
  GET_BULK_UPLOAD_NPR: "npr/bulkActionStatus",
  BULK_DOWNLOAD_LABELS: "tools/getBulkLabelsAndPdfZip",
  GET_ALL_REPORT_TYPES: "reports/types",
  GET_ALL_GENERATED_REPORTS: "reports/get/merchant",
  CREATE_REPORT_ASYNC: "reports/getAsync",
  CREATE_SELECTOR: "selector/create",
  GET_SKU_DIM: "product/get/all",
  UPLOADS_REMARKS: "remarks/upload",
  REMARKS: "remarks/get",
  SAVE_REMRKS: "remarks/save",
  TAC: "merchant/acceptMerchantTAC",
  VERIFY_OTP: "merchant/verify_otp",
  GENERATE_OTP: "merchant/generate_otp",
  ADD_MANDATORY: "merchant/addMandatoryFields",
  GET_WEIGHT_DICREPENACY: "tools/weight_discrepancy/filter",
  WEIGHT_DISCREPANCY_UPDATE: "tools/weight_discrepancy/update",
  IMAGE_UPLOAD_WEIGHT_DISCREPANCY: "tools/wd/submit_images",
  WEIGHT_DISCREPANCY_LINK_UPLOAD: "tools/wd/submit_image_links",
  FETCH_CUSTOM_LABEL: "order/async/b2b/fetch_label",
  FETCH_ZONE_MAPPING: "tools/fetchZoneMap",
  CONTROL_TOWER_PICKUP_PENDING:
    "external/ct/controltower/v1/wms/freightsnapshot/all_pickup_pending_orders_grouped_by_mechant",
  CONTROL_TOWER_DELIVERY_PENDING:
    "external/ct/controltower/v1/wms/freightsnapshot/all_delivery_pending_orders_grouped_by_mechant",
  CONTROL_TOWER_PICKED_UP:
    "external/ct/controltower/v1/wms/freightsnapshot/all_pickedup_orders_today_grouped_by_mechant",
  CONTROL_TOWER_RTO_PENDING:
    "external/ct/controltower/v1/wms/freightsnapshot/all_rto_delivery_pending_orders_grouped_by_mechant",
  CONTROL_TOWER_LOST:
    "external/ct/controltower/v1/wms/freightsnapshot/bydate/all_lost_damaged_orders_today_grouped_by_mechant",
  RATE_CARD: "merchant",
  CREATE_REVERSE_ORDER: "order/createReverseWithReference",
  ALL_AWB_REGISTERED_BY_DATE_RANGE:
    "external/ct/controltower/v1/wms/freightsnapshot/bydate/all_awb_registered_orders_today_grouped_by_mechant",
  ALL_DELIVERED_BY_DATE_RANGE:
    "external/ct/controltower/v1/wms/freightsnapshot/bydate/all_delivered_orders_today_grouped_by_mechant",
  ALL_RTO_DELIVERED_BY_DATE_RANGE:
    "external/ct/controltower/v1/wms/freightsnapshot/bydate/all_rto_delivered_orders_today_grouped_by_mechant",
  ALL_INTRANSIT_ORDERS:
    "external/ct/controltower/v1/wms/freightsnapshot/all_delivery_pending_orders_grouped_by_mechant",
  ALL_NOT_PICKED_ORDERS:
    "external/ct/controltower/v1/wms/freightsnapshot/all_pickup_pending_orders_grouped_by_mechant",
  SEARCH_INVOICE: "finance/orderInvoice/filter",
  WEBHOOK_SEARCH: "merchant/webHooks/",
  WEBHOOK: "merchant/webHookUpdate",
  GET_REPORT_CONFIG: "reports/config/get",
  UPDATE_REPORT_CONFIG: "reports/config/update",
  CREATE_REPORT_CONFIG: "reports/config/add",
  GENERATE_MANIFEST: "manifest/generate",
  GENERATE_BULK_MANIFEST: "manifest/generateBulk",
  MANIFEST_SEARCH_ORDERS: "manifest/search_orders",
  BULK_ACTION_UPLOAD: "finance/wd/bulkAction",
  DOWNLOAD_SAMPLE_FILE: "order/Download_samples",
  GET_BULK_ACTION_STATUS: "finance/wd/bulkActionStatus",
  GET_BULK_LABELS: "tools/getBulkLabelsAndPdfZipComma",
  DELIVERY_DB_SEARCH: "tools/generic/deliveryDB/search",
  DOWNLOAD_POD: "tools/downloadPODs",
  PTL_PUR_SEARCH: "b2b/pickup/search",
  MERCHANT_DETAILS: "config/merchant",
  MANUAL_ALLOCATE_COURIER: "b2b/pickup/allocate_courier",
  MERCHANT_SEARCH: "merchant/search",
  MANUAL_CANCEL_COURIER: "b2b/pickup/cancel_request",
  BULK_PTL_PUR: "b2b/pickup/bulk_pickup_orders_B2B",
  BULK_UPLOAD_B2B_TRACK: "b2b/tracking/v2/bulk_tracking_b2b_upload",
  GET_DELIVERY_LOCATIONS: "merchant/delivery_location",
  ADD_DELIVERY_LOCATIONS: "merchant/save_delivery_location",
  UPLOAD_DELIVERY_LOCATIONS: "merchant/upload_delivery_location",
  UPDATE_MERCHANT_CONFIG: "config/merchant",
  UPDATE_NDR_EMAIL_CONFIG: "reports/config/update",
  ADD_NDR_EMAIL_CONFIG: "reports/config/add",
  GET_NDR_EMAIL_CONFIG: "reports/config/get",
  WALLET_EMAIL_CONFIG: "wallet/updateNotificationConfig",
  UPDATE_CUSTOMER_DETAILS: "channel/order/updateCustomer",
  GENERATE_BULK_INVOICE: "tools/generateBulkInvoice",
  GENERATE_BULK_LABELS: "tools/generateBulkLabel",
  CREATE_FRESHDESK: "freshdesk/create",
  FILTER_FRESHDESK: "freshdesk/filter",
  CLOSE_FRESHDESK: "freshdesk/close",
  GET_TICKETID_FRESHDESK: "freshdesk/get",
  REPLY_FRESHDESK: "freshdesk/reply",
  RVP_ORDER: "returnOrder/returnWithFwdReference_unitwise",
  MERCHANT_CATEGORY: "QCCP/findAllCategory",
  MERCHANT_CATEGORY_QUES: "QCCP/getQCByCategory",
  MERCHANT_CATEGORY_QUES_POST: "config/addQCQuestionsMerchantByQCCode",
  MERCHANT_QC_LIST: "config",
  COD_REMITTANCE_REPORT: "cod/reportsMerged",
  COD_MERCHANT_REPORT: "cod/filter/report",
  GENERIC_UPDATE: 'tools/generic/update',
  REFRESH_URL: 'tools/refresh/url',
  REFRESH_URL_S3: 'tools/refreshAnySignedUrl',
  REATTEMPT_NPR: 'order/nprActionUpdate',
  UPLOAD_SKU_IMAGE: 'product/uploadImageByReturn',
  GET_RETURN_SHIPMENT_DETAILS: 'returnAndExchangeOrder/getReturnShipmentDetails',
  APPROVE_RETURN_REQUEST: 'returnAndExchangeOrder/returnOrExchange_unitwise',
  REJECT_RETURN_REQUEST: "returnAndExchangeOrder/sendRejectedMessage",
  GET_RETURN_AWB: "returnAndExchangeOrder/getReturnAwb",
  get_specific_data_delivery:
    "external/ct/controltower/v1/wms/freightsnapshot/getSpecificData/delivery",
  get_specific_data_pickup:
    "external/ct/controltower/v1/wms/freightsnapshot/getSpecificData/pickup",
  get_specific_data_rto_delivery:
    "external/ct/controltower/v1/wms/freightsnapshot/getSpecificData/rtoDelivery",
  get_ndr_analysis_data: "external/ct/controltower/v1/wms/getNdrAnalysisData",
  REFRESH_S3_URL: "tools/refreshS3Ref",
  NDR_RULEUPDATE: "ndr/automatedRule/ruleUpdate",
  NDR_RULEDEACTIVATE: "ndr/automatedRule/fileUpload",
  RETURN_EXCHANGE_ORDER_LIST: "returnAndExchangeOrder/search",
  GENERATE_AUTH_TOKEN_FOR_PROSHIP:
    "external/ct/controltower/api/auth/generateAuthTokenForProship",
  PROSHIP_PILOT_URL:
    "https://9p5xeeuyw9.execute-api.ap-south-1.amazonaws.com/Dev/proshipWithStreaming/",
  getSnapshotsNotUpdateLastNHours: `external/ct/controltower/v1/wms/freightsnapshot/getSnapshotsNotUpdateLastNHours`,
  GET_NOTIFICATIONS: "ui/notification/search",
};

export const viewbyDDOptSelected = "last_7_days";

export function getDashboardDateFilter(key = null) {
  const lastMonths = 5;
  const today = new Date();
  const startOfThisMonth = startOfMonth(today);
  const dates = [
    /* {
            fromDate: formatDate(subDays(today, 15), 'yyyy-MM-dd'),
            toDate: formatDate(today, 'yyyy-MM-dd'),
            label: 'Last 15 Days',
            key: 'last_15_days'
        }, */

    {
      fromDate: formatDate(subDays(today, 7), "yyyy-MM-dd"),
      toDate: formatDate(today, "yyyy-MM-dd"),
      label: "Last 7 Days",
      key: "last_7_days",
    },
    {
      fromDate: formatDate(subDays(today, 30), "yyyy-MM-dd"),
      toDate: formatDate(today, "yyyy-MM-dd"),
      label: "Last 30 Days",
      key: "last_30_days",
    },
    {
      fromDate: formatDate(startOfThisMonth, "yyyy-MM-dd"),
      toDate: formatDate(today, "yyyy-MM-dd"),
      label: "This Month",
      key: "this_month",
    },
  ];

  for (let i = 1; i <= lastMonths; i++) {
    const stDate = startOfMonth(subMonths(startOfThisMonth, i));
    const enMonth = endOfMonth(stDate);
    dates.push({
      fromDate: formatDate(stDate, "yyyy-MM-dd"),
      toDate: formatDate(enMonth, "yyyy-MM-dd"),
      label: formatDate(stDate, "MMM yyyy"),
      key: `last_${i}_month`,
    });
  }

  if (!key) return dates.find((d) => d.key === viewbyDDOptSelected);
  if (key === "all") return dates;
  return dates.find((d) => d.key === key);
}

export const razorPayID = process.env.REACT_APP_RAZORPAY_ID;
// export const razorPayID = "rzp_live_RYluVDMxkii3jY";
export const viewbyDDOpt = [
  { optKey: "last_15_days", optValue: "Last 15 Days" },
  { optKey: "last_1_month", optValue: "Last 1 Month" },
  { optKey: "last_3_month", optValue: "Last 3 Months" },
  /* { "optKey": "last_6_month", "optValue": "Last 6 Months" } */
];

export function post(endpoint, data) {
  return axios.post(`${endpoint}`, data);
  // return axios.post(`${finalUrl}`, data);
}

export function postGeneric(endpoint, data) {
  let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`;
  return axios.post(`${finalUrl}`, data);
}
export function downloadZip(endpoint, data) {
  let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`;
  const config = {
    responseType: "blob",
  };
  return axios.post(`${finalUrl}`, data, config);
}

export function getGeneric(endpoint, params = {}, requestParams = {}) {
  const searchParams = new URLSearchParams(params);
  for (const k of Object.keys(params)) {
    searchParams.set(k, params[k]);
  }

  let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`;
  if (Object.keys(params).length > 0) {
    finalUrl += `?${searchParams.toString()}`;
  }

  return axios.get(finalUrl, requestParams);
}

export function get(endpoint) {
  const finalUrl = `${endpoint}`;
  return axios.get(finalUrl);
}

export function patch(endpoint, data) {
  return axios.patch(`${endpoint}`, data);
}

export function postMultipart(endpoint, data) {
  let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`;
  return axios.post(finalUrl, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getConcurrent(endpoints) {
  return axios.all(endpoints.map((endpoint) => axios.get(endpoint)));
}

export function getDownload(endpoint) {
  return axios.get(`${endpoint}`, {
    headers: {
      responseType: "blob",
    },
  });
}

export function postDownload(endpoint, data) {
  return axios.post(`${endpoint}`, data, {
    headers: {
      responseType: "blob",
    },
  });
}

export function getExcel(endpoint, params = {}, requestParams = {}) {
  const searchParams = new URLSearchParams(params);
  for (const k of Object.keys(params)) {
    searchParams.set(k, params[k]);
  }

  let finalUrl = `${apiConstants.BASE_LOCAL}${endpoint}`;
  if (Object.keys(params).length > 0) {
    finalUrl += `?${searchParams.toString()}`;
  }

  return axios.get(finalUrl, requestParams);
}

const RETRY_LIMIT = 30;
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function sendMessage(message, threadId, ctToken) {
  let messageResponse = null;

  try {
    const requestPayload = { message, thread_id: threadId };
    const poolData = await makeChatBotCall(requestPayload, ctToken);
    let currentIteration = 0;

    while (currentIteration <= RETRY_LIMIT) {
      try {
        const apiResponse = await makeChatBotCall(poolData, ctToken);
        if (apiResponse?.success && apiResponse?.status === "completed") {
          messageResponse = apiResponse;
          break;
        }

        await wait(3000);
        currentIteration++;
      } catch (error) {
        console.error(`Error during fetching poll: ${error.message}`);
        throw error;
      }
    }
  } catch (e) {
    console.error(`Unexpected error: ${e.message}`);
    throw e;
  }

  if (messageResponse === null) {
    throw new Error("Unable to fetch message response!");
  }

  return messageResponse;
}

const forProshipPilotAxios = axios.create();

export async function makeChatBotCall(payload, ctToken) {
  let responseData = null;

  try {
    let endpoint = `${apiConstants.PROSHIP_PILOT_URL}addMessage`;

    try {
      const apiResponse = await forProshipPilotAxios.post(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${ctToken}`,
          "x-proship-token": `${localStorage.getItem("userToken")}`,
        },
      });

      if (apiResponse.status === 200) {
        responseData = apiResponse.data;
      }
    } catch (error) {
      console.error(`Error during API call: ${error.message}`);
      throw error;
    }
  } catch (e) {
    // Log or handle the error appropriately
    console.error(`Unexpected error: ${e.message}`);
    throw e;
  }

  return responseData;
}

export async function setAutoMerchantName(payload, ctToken) {
  const messageResponse = await makeChatBotCall(payload, ctToken);
  let lastIndex = messageResponse?.last_index;
  let isPoll = messageResponse.poll;
  let isStatus = messageResponse.status;
  let iteration = 0;

  while (isPoll && isStatus === "in_progress" && iteration <= 30) {
    const pollPayload = {
      poll: true,
      thread_id: messageResponse.thread_id,
      run_id: messageResponse.run_id,
      last_index: lastIndex,
    };
    const response = await makeChatBotCall(pollPayload, ctToken);
    isPoll = response.poll;
    isStatus = response.status;
    if (response.message && response.last_index > 0) {
      lastIndex = response.last_index;
    }
    iteration++;
    await wait(1000);
  }

  return messageResponse;
}
