import React from "react";
import { Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { formatNumber, tatBuckets as tatAllBuckets, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box } from "@mui/system";
import { NumbersTwoTone, PercentTwoTone } from "@mui/icons-material";
import { StackedBarChart } from "../../../charts/BarChart";
import MaterialTable from "../../../../common/MaterialTable";

const bucketFilterObj = {
    4: { from: 3, itemsToRemove: 2, key: 'TAT >2' },
    3: { from: 2, itemsToRemove: 3, key: 'TAT >1' },
    2: { from: 1, itemsToRemove: 4, key: 'TAT >=1' }
}

export default function TatWiseSplit({ data, title }) {
    const [view, setView] = React.useState("chart");
    const [tableView, setTableView] = React.useState("percentage");
    const [bucketFilter, setBucketFilter] = React.useState(5);

    let finalData = data;
    let tatBuckets = [...tatAllBuckets]

    if (bucketFilter !== 5) {

        const bucketFilterToApply = bucketFilterObj[bucketFilter]
        const slicedArr = tatBuckets.splice(bucketFilterToApply.from, bucketFilterToApply.itemsToRemove);
        const newBucket = bucketFilterToApply.key;

        finalData = finalData.map(function (row) {
            const count = slicedArr.reduce((acc, k) => {
                return acc += row[`${k}_count`]
            }, 0);
            const percentage = slicedArr.reduce((acc, k) => {
                return acc += row[`${k}_percentage`]
            }, 0);

            row[`${newBucket}_count`] = count;
            row[`${newBucket}_percentage`] = percentage;
            return row;
        })

        tatBuckets.push(newBucket)
    }

    const cumulativeData = finalData.map((element) => {
        let cumulative = 0;
        tatBuckets.forEach((bucket) => {

            const value = element[`${bucket}_percentage`];
            cumulative += value;

            element[`${bucket}_percentage_table`] = cumulative;

        })

        return element;
    });


    const { rows, allColumns } = transformChartDataToTable(cumulativeData, [
        ...[...tatBuckets].map((z) => {
            const key = tableView === 'percentage' ? `${z}_percentage_table` : `${z}_count`
            return { key, label: z }
        })
    ]);


    const tatColors = {
        'In TAT': 'rgb(0, 197, 153)',
        'TAT +1': 'rgba(240, 180, 27, 0.8)',
        'TAT +2': 'rgb(0, 167, 235)',
        'TAT +3': '#FC46AA',
        'TAT >3': 'rgb(255, 11, 80)',
        'TAT >2': 'rgb(255, 11, 80)',
        'TAT >1': 'rgb(255, 11, 80)'
        ,        // Add more tat buckets and corresponding colors as needed
    };

    const tableColumns = [
        {
            label: 'Bucket',
            value: 'name',
            align: 'left'
        },
        ...(allColumns.map((z) => ({
            numeric: true,
            align: 'right',
            value: (row) => {
                return `${formatNumber(row[z])}`
            },
            label: z
        })))
    ]

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        labelY: tableView === 'percentage' ? '% Shipments' : '# Shipments',
        stackId: 'stackedBar',
        data: tatBuckets.map((tat) => ({
            dataKey: `${tat}_${tableView}`,
            label: tat,
            chartType: 'bar',
            unit: tableView === 'percentage' ? '%' : null,
            fill: tatColors[tat]
        }))
    }

    return (
        <Card elevation={1}>
            <CardHeader title={title} action={(
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ToggleButtonGroup color="error" exclusive size="small" onChange={(_e, v) => setBucketFilter(v)} value={bucketFilter}>
                        <ToggleButton disabled>SHOW</ToggleButton>
                        <ToggleButton value={5}>TAT +3</ToggleButton>
                        <ToggleButton value={4}>TAT +2</ToggleButton>
                        <ToggleButton value={3}>TAT +1</ToggleButton>
                        <ToggleButton value={2}>TAT</ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup color="error" size="small" exclusive onChange={(_e, v) => setTableView(v)} value={tableView}>
                        <ToggleButton value="percentage" title="Percentage">
                            <PercentTwoTone fontSize="small" />
                        </ToggleButton>
                        <ToggleButton value="count" title="Total Count">
                            <NumbersTwoTone fontSize="small" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup color="primary" size="small" exclusive onChange={(_e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart" title="View Chart">
                            <BarChartIcon fontSize="small" />
                        </ToggleButton>
                        <ToggleButton value="table" title="View Table">
                            <TableChartIcon fontSize="small" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />
            <CardContent>
                {(view === 'chart') && (
                    <StackedBarChart data={data} chartOptions={chartOptions} />
                )}
                {(view === 'table') && (
                    <MaterialTable size="small" exportExcel={false} rows={rows} columns={tableColumns} frozenColumnsCount={1} />
                )}
            </CardContent>
        </Card>
    )
}