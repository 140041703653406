import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { RHFDateField, RHFTextField } from "../../common/hook-form";
import _ from "lodash";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AddCircleTwoTone, RemoveCircleTwoTone } from "@mui/icons-material";

const emptyItemObject = {
    item_name: "",
    units: 0,
    additional: {
        length: 0,
        breadth: 0,
        height: 0,
        weight: 0
    }
};

function PackageDetails({ setActiveStep }) {
    const { trigger, control, watch, setValue } = useFormContext()
    const invoiceValue = watch('invoice_value')
    const { append, remove, fields } = useFieldArray({
        name: 'item_list',
        control: control,
    })

    const onNextHandler = async () => {
        const status = await trigger(['item_list', 'invoice_number', 'invoice_value', 'selling_price', 'reference', 'invoice_date']);
        if (status) {
            setActiveStep(3)
        }
    }

    React.useEffect(() => {
        setValue('selling_price', invoiceValue)

        // eslint-disable-next-line
    }, [invoiceValue])


    React.useEffect(() => {
        if (fields.length === 0) {
            append(emptyItemObject)
        }

        // eslint-disable-next-line
    }, [fields])

    const itemLists = watch('item_list');
    const overallDetails = [
        { title: 'Total Boxes', value: _.sumBy(itemLists, 'units'), unit: '' },
        { title: 'Shipment Length', value: _.sumBy(itemLists, 'additional.length'), unit: 'cm' },
        { title: 'Shipment Breadth', value: _.max(_.map(itemLists, 'additional.breadth')), unit: 'cm' },
        { title: 'Shipment Height', value: _.sumBy(itemLists, 'additional.height'), unit: 'cm' },
        { title: 'Shipment Weight (Dead)', value: _.sumBy(itemLists, item => item.units * item.additional.weight), unit: 'kg' },
        { title: 'Shipment Weight (Vol.)', value: _.sumBy(itemLists, item => (item.units * item.additional.height * item.additional.breadth * item.additional.length)/4500).toFixed(2), unit: 'kg' },
    ];

    const onKeyPressHandler = (e) => {
        if (e.charCode === 13) {
            onNextHandler();
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="caption" fontWeight={500}><strong>Note:</strong> All package dimensions must be specified in centimeters (cm) & Weight in kgs</Typography>
            </Grid>
            {fields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                    <Stack direction="row" columnGap={1} alignItems="center" sx={{
                        backgroundColor: '#3783e71a',
                        borderRadius: 1,
                        p: 2,
                        border: '1px solid #ccc'
                    }}>
                        <RHFTextField onKeyPress={onKeyPressHandler} name={`item_list.${index}.item_name`} label="Box Name" required variant="filled" autoFocus={true} />
                        <RHFTextField onKeyPress={onKeyPressHandler} name={`item_list.${index}.units`} label="No of Boxes" type="number" required variant="filled" />
                        <RHFTextField onKeyPress={onKeyPressHandler} name={`item_list.${index}.additional.length`} label="Length" type="number" required variant="filled" />
                        <RHFTextField onKeyPress={onKeyPressHandler} name={`item_list.${index}.additional.breadth`} label="Breadth" type="number" required variant="filled" />
                        <RHFTextField onKeyPress={onKeyPressHandler} name={`item_list.${index}.additional.height`} label="Height" type="number" required variant="filled" />
                        <RHFTextField onKeyPress={onKeyPressHandler} name={`item_list.${index}.additional.weight`} label="Weight" type="number" required variant="filled" />
                        {((index + 1) === fields.length) && (
                            <IconButton size="small" color="primary" onClick={() => append(emptyItemObject)}>
                                <AddCircleTwoTone />
                            </IconButton>
                        )}
                        {(fields.length !== 1) && (
                            <IconButton size="small" color="error" onClick={() => remove(index)}>
                                <RemoveCircleTwoTone />
                            </IconButton>
                        )}
                    </Stack>
                </Grid>
            ))}

            <Grid item xs={12}>
                <Stack direction="row" columnGap={2} rowGap={2} alignItems="center"  sx={{ mb: 3, }}>
                    {overallDetails.map((detail) => (
                        <Box
                            key={detail.title}
                            sx={{
                                // backgroundColor: '#3783e71a',
                                borderRadius: 1,
                                minWidth: 150,
                                p: 1,
                                border: '1px solid #3498db',
                                textAlign: 'center',
                            }}>
                            <Typography variant="caption">{detail.title}</Typography>
                            <Typography fontWeight={500}>{detail?.value || 0} <small style={{ fontSize: 10 }}>{detail.unit}</small></Typography>
                        </Box>
                    ))}
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <RHFTextField onKeyPress={onKeyPressHandler} name="reference" label="Reference Number" required variant="filled" InputProps={{
                    endAdornment: (
                        <Button size="small" onClick={() => { setValue('reference', 'PROSHIP_GENERATED_REFERENCE') }}>Generate</Button>
                    )
                }} />
            </Grid>
            <Grid item xs={3}>
                <RHFTextField onKeyPress={onKeyPressHandler} name="invoice_number" label="Invoice Number" required variant="filled" />
            </Grid>
            <Grid item xs={3}>
                <RHFDateField onKeyPress={onKeyPressHandler} name="invoice_date" label="Invoice Date" required />
            </Grid>


            <Grid item xs={3}>
                <RHFTextField onKeyPress={onKeyPressHandler} name="invoice_value" label="Invoice Value" type="number" required variant="filled" />
            </Grid>



            <Grid item xs={12}>
                <Stack direction="row" columnGap={1} sx={{ mt: 3 }}>
                    <Button variant="contained" size="small" color="error" onClick={() => setActiveStep(1)}>Prev</Button>
                    <Button variant="contained" size="small" onClick={onNextHandler}>Next</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default PackageDetails;