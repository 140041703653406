import { isToday, subDays } from "date-fns";
import _ from "lodash";

import { apiConstants, getGeneric, postGeneric } from "../../common/constants";
import { delivered, flattenResponseData, getYYYYMMDD } from "../../helpers/UtilityHelper";
import getShipmentsByParallelRequests from "./parallel_shipments";

async function getShipmentWithPickupAndDeliveryData(requestPayloadParam) {

    let finalData = [];

    try {

        const requestPayload = { ...requestPayloadParam }

        const orderStatus = ['CANCELLED_ORDER', 'AWB_REGISTERED', 'PICKUP_PENDING', 'PICKUP_FAILED'];
        const todayDate = new Date();

        const isEndDateToday = isToday(new Date(requestPayload.to_date));

        if (isEndDateToday) {
            requestPayload.to_date = getYYYYMMDD(subDays(todayDate, 1));
        }

        const allShipments = await getGeneric(apiConstants.ALL_AWB_REGISTERED_BY_DATE_RANGE, requestPayload);
        let allRegistedShipments = (flattenResponseData(allShipments?.data || []));

        if (isEndDateToday) {
            const formatedDate = getYYYYMMDD(todayDate);
            const requestPayloadToday = { from_date: formatedDate, to_date: formatedDate, shipType: requestPayload.shipmentType_list };
            const allTodayShipmentsShipments = await getShipmentsByParallelRequests(requestPayloadToday);

            try {

                const transformedShipmentsProperties = allTodayShipmentsShipments?.data?.data.map((item) => ({
                    ...item,
                    invoiceValue: item.invoice_value,
                    paymentMode: item.payment_mode,
                    courier: item.courierDetail.key,
                    awbNumber: item.awb_number,
                    pickupPincode: item.pickup_details.from_pincode,
                    price: item.merchant_pricing.price,
                    dropPincode: item.delivery_details.to_pincode,
                    dropCity: item.delivery_details.to_city,
                    deliveryState: item.delivery_details.to_state,
                    shipment_weight: _.sumBy(item.shipment_detail, 'item_weight'),
                }));

                allRegistedShipments = [...allRegistedShipments, ...transformedShipmentsProperties];

            } catch (err) {

            }

        }

        const allPickupShipmentsRaw = allRegistedShipments.filter(item => !orderStatus.includes(item.orderStatus));
        const pickupPayload = { awbNumbers: allPickupShipmentsRaw?.map(r => r.awbNumber) };

        const allDeliveredShipmentsRaw = allRegistedShipments.filter(item => delivered.includes(item.orderStatus));
        let deliveredPayload = { awbNumbers: allDeliveredShipmentsRaw?.map(r => r.awbNumber) };

        const allPickupResponse = await postGeneric(apiConstants.get_specific_data_pickup, [pickupPayload]);
        const allDeliveredResponse = await postGeneric(apiConstants.get_specific_data_delivery, [deliveredPayload]);

        const additionalPickupData = allPickupResponse?.data || [];
        const additionalDelivered = allDeliveredResponse?.data || [];

        const additionalPickupDataMap = additionalPickupData.reduce((map, item) => {
            map.set(item.awbNumber, item)
            return map;
        }, new Map());

        const additionalDeliveredMap = additionalDelivered.reduce((map, item) => {
            map.set(item.awbNumber, item)
            return map;
        }, new Map());

        finalData = allRegistedShipments?.map((item) => {

            let shipment = { ...item }

            if (additionalPickupDataMap?.has(shipment.awbNumber)) {
                const pickupDetails = additionalPickupDataMap.get(shipment.awbNumber);
                shipment = { ...shipment, ...pickupDetails };
            }

            if (additionalDeliveredMap?.has(shipment.awbNumber)) {
                const deliveryDetails = additionalDeliveredMap.get(shipment.awbNumber);
                shipment = { ...shipment, ...deliveryDetails };
            }


            return shipment;

        });

    } catch (e) {
        console.log(e);
    }

    return { data: finalData };
}

export default getShipmentWithPickupAndDeliveryData;