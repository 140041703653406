import React, { useEffect, useState } from 'react';
import ProzoDataGrid from '../../common/ProzoDataGrid';
import { Button, Chip, Grid } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { parseDateAndTime } from '../../helpers/UtilityHelper';
import Loader from '../../common/Loader';
import { useGetNPRDataQuery } from '../../redux/commonRTK';
import { buildFilterConditions } from '../../helpers/UtilityHelper';
import { ActionLogModal } from '.';
import { Link } from 'react-router-dom';
import PageTitle from '../../common/PageTitle';
import { getMerchant } from '../../helpers/ReduxHelpers';

const InitiatedNdrData = (props) => {

    const [initiatedNdrData, setInitiatedNDRData] = useState({});
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [logModal, setLogModal] = useState(false);
    const [logModalData, setLogModalData] = useState();
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''

    const exclude =
        "terminalStatus,shipmentDetail.cartonItems,invoiceNumber,shipmentType,returnInfo,labelUrl,dispatchMode,giftwrapCharge,transactionCharge,shippingCharge,isInsured,codType,riskType,appointmentDelivery,async,order_date,courierDetail.key,createdDate,actualPickUpTime,lastStatusUpdateTime,epd,edd,order_history,merchantPricing,merchant,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,actualCourierProviderName,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,channelName,itemList,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customerDetail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,modifiedByUser,lastModifiedDate,courier";

    const filterConditions = buildFilterConditions(props.filters);
    const { data: initiatedData, isLoading, refetch } = useGetNPRDataQuery({
        andfilter: [
            // {
            //     "field": "orderStatus",
            //     "operator": "in",
            //     "value": [
            //         "PICKUP_FAILED"
            //     ]
            // },
            {
                "field": "is_reverse",
                "operator": "eq",
                "value": true

            },
            {
                "field": "nprData.nprStatus",
                "operator": "in",
                "value": [
                    "INITIATED"
                ]
            },
            { field: "merchant.$id", operator: "eq", value: merchantId },
            ...filterConditions.andfilter,
        ],
        orfilter: [...filterConditions.orfilter],
        offset: page * sizePerPage,
        limit: sizePerPage,
        skipCount: true,
        controllerFilter: true,
        excludes: exclude,

    });
    useEffect(() => {
        setPage(0)
    }, [sizePerPage, props.filters])


    const columns = [
        {
            field: 'orderdetails', headerAlign: "left", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                console.log('rowwwww', row);
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            {row.reference}
                        </Grid>
                        {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                            <Grid item xs={12}>
                                {row.shippingLabelId}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {(row.awbRegisteredDate) ? parseDateAndTime(row.awbRegisteredDate) : ""}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'courierDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Courier Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Link style={{ color: "#3783e7", textDecoration: "underline" }} to={`/order-detail?waybill=${row.awb_number}`}>
                                    {row.awb_number}
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                {row?.courierDetail?.parent}<br />
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'status', headerAlign: "left", hideSortIcons: true, headerName: 'Status', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                const orderStatus = row.orderStatus.replace(/_/g, ' ');
                return (<Chip color="error" size="small" label={orderStatus} />)
            },
        },
        {
            field: 'shipment', headerAlign: "left", hideSortIcons: true, headerName: 'Pickup Address', flex: 1.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container >
                            <Grid item xs={12}>
                                Name : {row.pickup_details.from_name}
                            </Grid>
                            <Grid item xs={12}>
                                Phone : {row.pickup_details.from_phone_number}
                            </Grid>
                            <Grid item xs={12}>
                            {row.pickup_details?.from_address},{row.pickup_details?.from_city},{row.pickup_details?.from_state},{row.pickup_details?.from_pincode}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'nprCpStatusDescription', headerAlign: "left", hideSortIcons: true, headerName: 'NPR Description', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.nprData && row.nprData.nprCpStatusDescription) ? row.nprData.nprCpStatusDescription : "-NA-"}

                            </Grid>
                            <Grid item xs={12}>
                                {(row.nprData && row.nprData.nprReportedDate) ? parseDateAndTime(row.nprData.nprReportedDate, true) : ""}
                            </Grid>

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'ofPCount', headerAlign: "left", hideSortIcons: true, headerName: 'OFP Attempt Count', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            {(row.nprData && row.nprData.ofPCount) ? `${row.nprData.ofPCount} attempt` : "0 attempts"}
                        </Grid>

                    </>
                )
            },
        },
        {
            field: 'actionLog', headerAlign: "left", hideSortIcons: true, headerName: 'Action Log', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    setLogModalData(row.nprData?.actionUpdateLog);
                                    setLogModal(true);
                                }}
                            >
                                Action Log
                            </Button>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Payment Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },

    ];

    useEffect(() => {
        if (initiatedData) {
            setInitiatedNDRData(initiatedData);
        }
    }, [initiatedData]);

    useEffect(() => {
        refetch();
    }, [page, sizePerPage, props.filters, refetch]);


    return (
        <>
            <PageTitle>NPR Management</PageTitle>
            <Grid item container spacing={2}>
                {(isLoading) && <Loader />}
                <ActionLogModal data={logModalData} logModal={logModal} setLogModal={setLogModal} />
                <Grid item xs={12}>
                    <ProzoDataGrid columns={columns}
                        // disableColumnFilter={true}rowsPerPageOptions
                        autoHeight={true}
                        rows={(initiatedNdrData.data && initiatedNdrData.data.length > 0) ? initiatedNdrData.data : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        filterMode={"server"}
                        rowHeight={180}
                        rowCount={Number.MAX_VALUE}
                        getRowId={(row) => row.id} />
                </Grid>
            </Grid>
        </>
    );
};

export default InitiatedNdrData

