import { Button, Chip, Grid, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { FormProvider, RHFTextField } from "../../../common/hook-form"
import Loader from "../../../common/Loader"
import { useDispatch, useSelector } from 'react-redux';
import { getTotalUnitsOfOrder, maskAddress, parseDateAndTime, shouldMaskDataPD } from "../../../helpers/UtilityHelper"
import withLayout from "../../../hocs/Layout"
import { useLazyGetPaginatedShipmentsQuery } from "../../../redux/commonRTK"
import { syncOrder } from '../../../redux/actions/orderList/orderListAction';
import { exclude, ItemListModal } from './index';
// import { Link } from 'react-router-dom'
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Link } from 'react-router-dom'
import { OrderStatusWiseFilter } from "./index";
import PageTitle from "../../../common/PageTitle"
import MUIModal from "../../../common/MUIModal"
import QcDetails from "./QcDetails"

const excludes = exclude + ",customerDetail";


function ReverseOrders() {
    const { merchant } = useSelector((state) => state.user.userInfo);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0)
    const [itemModalData, setItemModalData] = useState();
    const [itemModal, setItemModal] = useState(false);
    const [andFilters, setAndFilters] = useState([]);
    const [isLoadingResync, setisLoadingResync] = useState(false);
    // const orFilter = [];
    const [getPaginatedShipments, { data, isLoading, isFetching }] = useLazyGetPaginatedShipmentsQuery()

    const defaultFilters = [
        // { field: "is_reverse", operator: "eq", value: true },
        { field: "orderSubStatus", operator: "ne", value: "UNPROCESSED" },
        { field: "orderType", operator: "eq", value: "REVERSE SHIPMENT" },
        { field: "shipmentType", operator: "eq", value: "B2C" },
        { field: "merchant.$id", operator: "eq", value: merchant.id }
    ]

    useEffect(() => {
        if (data?.count && data?.data) {
            setTotalRows(data.count)
        }
    }, [data])

    useEffect(() => {
        setPage(0)
    }, [sizePerPage])

    useEffect(() => {
        getPaginatedShipments({
            offset: (page * sizePerPage),
            limit: sizePerPage,
            andfilter: [...defaultFilters, ...andFilters],
            excludes,
            "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
        })
        //dispatch(getOrderLists({ "excludes": exclude, "andfilter": [...defaultFilters, ...andFilters], "orfilter": orFilter, "offset": (page * sizePerPage), "limit": sizePerPage, "skipCount": true }));
        // eslint-disable-next-line
    }, [page, andFilters])

    const methods = useForm({
        defaultValues: {
            reference: '',
            pickup_pincode: '',
            drop_pincode: '',
            awb: '',
            status: ''
        }
    });
    const {
        handleSubmit,
        reset
    } = methods;

    const applyFilters = (filters) => {
        let appliedFilters = [];
        if (filters.status) {
            appliedFilters.push({ "field": "orderStatus", "operator": "in", "value": [filters.status] })
        }
        if (filters.reference) {
            appliedFilters.push({ "field": "reference", "operator": "eq", "value": filters.reference })
        }

        if (filters.pickup_pincode) {
            appliedFilters.push({ "field": "pickupDetails.from_pincode", "operator": "eq", "value": filters.pickup_pincode })
        }

        if (filters.drop_pincode) {
            appliedFilters.push({ "field": "deliveryDetails.to_pincode", "operator": "eq", "value": filters.drop_pincode })
        }
        if (filters.awb) {
            appliedFilters.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb })
        }

        setAndFilters(appliedFilters)
    }


    const handleSync = (awbNumber, reference) => {
        if (awbNumber) {
            setisLoadingResync(true)
            dispatch(syncOrder({ waybill: awbNumber, reference: reference })).finally(() => setisLoadingResync(false))
            // console.log(orderListInfo);
        }
    }
    const { report } = useSelector(state => state);

    const columns = [
        {
            field: 'actions', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1.3, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.awb_number) ? row.awb_number : ""}<br />
                            </Grid>
                            <Grid item xs={12}>
                                {row?.courierDetail?.parent}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Details', flex: 1.3, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <div className="col-12 col-md position-relative mt-4 mt-md-0">
                            <div className="row g-0">
                                <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                                    <div className="flex-shrink-0"> <img src="img/location.svg" height="17" alt="" /> </div>
                                    <div className="flex-grow-1 ms-md-3 mt-2 mt-md-0">{(row.pickup_details && row.pickup_details.from_name) ? row.pickup_details.from_name : "-NA-"}.<br />
                                        {/* {(row.pickup_details && row.pickup_details.from_pincode) ?
                                            row.pickup_details.from_pincode :
                                            "-NA-"} */}
                                        {(row.pickup_details && row.pickup_details.from_pincode) ? (shouldMaskDataPD(report, row) ?
                                            maskAddress(row.pickup_details.from_pincode) : row.pickup_details.from_pincode) : "-NA-"}
                                    </div>
                                </div>
                                <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                                    <div className="flex-shrink-0 text-end text-md-start"> <img src="img/location.svg" height="17" alt="" /> </div>
                                    <div className="flex-grow-1 ms-md-3 d-flex justify-content-end justify-content-md-start mt-2 mt-md-0">{(row.delivery_details && row.delivery_details.to_name) ? row.delivery_details.to_name : ""}<br />
                                        {(row.delivery_details && row.delivery_details.to_pincode) ? row.delivery_details.to_pincode : "-NA-"}</div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            field: 'shipment', headerAlign: "center", hideSortIcons: true, headerName: 'Package Details', flex: 1.3, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Vol Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_length * row.shipment_detail[0].item_breadth * row.shipment_detail[0].item_height) / 5000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Dimensions : {(row.shipment_detail && row.shipment_detail[0]) ? `${row.shipment_detail[0].item_length} x ${row.shipment_detail[0].item_breadth} x ${row.shipment_detail[0].item_height} cm` : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'itemDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Item Details', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button onClick={() => { setItemModal(true); setItemModalData(row.item_list) }}>Items</Button>
                            </Grid>
                            <Grid item xs={12}>
                                Total Units : {getTotalUnitsOfOrder(row.item_list)}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Invoice Amt', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                // const paymentMode = row.payment_mode ? row.payment_mode : '';
                // const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (<Chip variant="filled" label={row.orderStatus} />)
            },
        },
        {
            field: 'qcDetails', headerAlign: "center", hideSortIcons: true, headerName: 'QC Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                if (row.qcDetails) {
                    return (
                        <>
                            <Button onClick={() => handleOpen(row)}>View</Button>
                        </>
                    )
                }
                else {
                    return "-"
                }
            },
        },
        {
            field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <Button variant="contained" size="small" color="warning" component={Link} to={`/order-detail?waybill=${row.awb_number}`}>Details</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" size="small" color="warning" onClick={() => handleSync(row.awb_number, row.reference)}>Resync</Button>
                        </Grid>

                    </Grid>
                )
            },
        },
    ];
    const [row, setRow] = useState();
    const [viewQc, setViewQc] = useState(false)
    const handleOpen = (row) => {
        setViewQc(true)
        setRow(row)
    }
    const handleClose = () => {
        setViewQc(false)
    }
    return (
        <>
            {isLoadingResync && <Loader />}
            <MUIModal open={viewQc} handleClose={handleClose} children={<QcDetails data={row} />} title={"Qc Link"} maxWidth='sm' />
            <div id="main-content" className="container-fluid">
                <PageTitle>RVP Shipments</PageTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 0 }}>
                            <FormProvider methods={methods} onSubmit={handleSubmit(applyFilters)}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={2} md>
                                        <RHFTextField name="reference" label="Order/Reference Number" variant="filled" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <RHFTextField name="pickup_pincode" label="Pickup Pincode" variant="filled" />
                                    </Grid>
                                    {/* <Grid item xs={2} >
                                    <RHFTextField name="drop_pincode" label="Drop Pincode" variant="filled" />
                                </Grid> */}
                                    <Grid item xs={2}>
                                        <OrderStatusWiseFilter active={6} />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Button type="submit" variant="contained">Filter</Button>
                                        <Button sx={{ ml: 1 }} type="submit" variant="contained" color="error" onClick={() => {
                                            reset()
                                        }}>Reset</Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <ProzoDataGrid
                            columns={columns}
                            hideFooterPagination={false}
                            autoHeight={true}
                            rows={data?.data || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            filterMode={"server"}
                            rowCount={totalRows}
                            getRowId={(row) => row.id} />
                    </Grid>
                </Grid>
                {(isLoading || isFetching) && <Loader />}
                <ItemListModal data={itemModalData} itemModal={itemModal} setItemModal={setItemModal} />
            </div>
        </>
    )
}
export default withLayout(ReverseOrders)