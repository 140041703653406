import React from "react";
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box } from "@mui/system";
import _ from 'lodash'
import PercentIcon from '@mui/icons-material/Percent';
import LooksOneIcon from '@mui/icons-material/LooksOne';

import { rvpAgeing, stringToColor, transformChartDataToTable } from "../../../../helpers/UtilityHelper";
import { DualAxisChart } from "../../../../components/charts/CompositChart";

export default function PickupAttemptAgeing({ data, title }) {

    const [view, setView] = React.useState("chart")
    const [tableView, setTableView] = React.useState("percentage")

    let { rows, allColumns } = transformChartDataToTable(data, [
        ...rvpAgeing.map((z) => {
            const key = tableView === 'percentage' ? `Pickup_${z}_perc` : `Pickup_${z}_count`
            return { key, label: z }
        })
    ]);

    rows = _.orderBy(rows, (i) => parseInt(i.name));

    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        yaxis: {
            left: {
                label: 'Pickup Attempts %',
                data: rvpAgeing.map((z) => ({
                    dataKey: `Pickup_${z}_perc`,
                    label: z,
                    chartType: 'line',
                    fill: stringToColor(z),
                    unit: '%'
                })),
            }
        },
    }

    return (
        <Card elevation={1}>
            <CardHeader title={title} action={(
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButtonGroup color="primary" size="small" exclusive onChange={(e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart" title="View Chart">
                            <BarChartIcon />
                        </ToggleButton>
                        <ToggleButton value="table" title="View Table">
                            <TableChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {(view === "table") && (
                        <ToggleButtonGroup sx={{ ml: 1 }} color="primary" size="small" exclusive onChange={(e, v) => setTableView(v)} value={tableView}>
                            <ToggleButton value="percentage" title="View Data In %">
                                <PercentIcon />
                            </ToggleButton>
                            <ToggleButton value="number" title="View Data In Count">
                                <LooksOneIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                </Box>
            )} />
            <CardContent sx={{ px: view === 'chart' ? 2 : 0 }}>
                {view === 'chart' && (
                    <DualAxisChart data={data} chartOptions={chartOptions} />
                )}
                {view === 'table' && (
                    <TableContainer sx={{ maxHeight: 1200, overflowX: 'auto' }}>
                        <Table size="small" density sx={{ minWidth: 750, borderCollapse: 'collapse' }}>
                            <TableHead sx={{ backgroundColor: '#000', color: '#fff' }}>
                                <TableRow sx={{ borderBottom: '1px solid #ccc' }}>
                                    {/* Header sticky cell */}
                                    <TableCell sx={{
                                        borderRight: '1px solid #ccc',
                                        color: '#fff',
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 2,
                                        backgroundColor: '#000'  // Header background color
                                    }} colSpan={1}>Name</TableCell>
                                    {allColumns.map((val, j) => (
                                        <TableCell key={j} sx={{ borderRight: '1px solid #ccc', color: '#fff' }}>{val}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, i) => (
                                    <TableRow key={i} sx={{ borderBottom: '1px solid #ccc' }}>
                                        <TableCell sx={{
                                            borderRight: '1px solid #ccc',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 1,
                                            backgroundColor: '#fff',
                                            padding: '6px',
                                        }}>{row.name}</TableCell>
                                        {allColumns.map((val, j) => (
                                            <React.Fragment key={j}>
                                                <TableCell sx={{ borderRight: '1px solid #ccc' }}>
                                                    {_.get(row, val, 0) + (tableView === 'percentage' ? '%' : '')}
                                                </TableCell>

                                            </React.Fragment>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
            </CardContent>
        </Card>
    )
}