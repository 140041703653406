import * as React from "react";
import { Box } from "@mui/system";
import { Badge } from "@mui/material";
// import { formatCurrency, formatNumber } from '../../helpers/UtilityHelper'
import withLayout from "../../hocs/Layout";
import MUITabs from "../../common/MUITabs";
import ForwardDelayed from "./Forward";
import useForwardDelay from "../../common/hooks/useForwardDelay";
import useRtoDelay from "../../common/hooks/useRtoDelay";
import Loader from "../../common/Loader";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import usePickupDelay from "../../common/hooks/usePickupDelay";
import PickupDelay from "./PickupDelay";
import { getMerchant } from "../../helpers/ReduxHelpers";
import RtoDelay from "./RtoDelay";
import PageTitle from "../../common/PageTitle";

function ControlTower() {

  // const { merchant } = useSelector((state) => state.user.userInfo);
  // const merchantId =
  //     merchant?.id || (userToken && userToken.merchantOid ? userToken.merchantOid : "");
  const merchant = getMerchant();
  const merchantId = merchant.id ? merchant.id : ''
 

  const [tab, setTab] = React.useState(0);
  const { isCalculating, data: forwardDelayed } = useForwardDelay(merchantId);
  const { isCalculating: isCalculatingRTO, data: rtoDelayed } = useRtoDelay(merchantId);
  const {
    isCalculating: isCalculatingPickupDelay,
    pickUpDelayed,
    // pickedUpOrders,
  } = usePickupDelay(merchantId);
  if (isCalculating || isCalculatingPickupDelay || isCalculatingRTO) {
    return <Loader />;
  }

  return (
    <div id="main-content" className="container-fluid pb-4">
      <PageTitle>Proship Control Tower</PageTitle>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: 1,
          marginBottom: 2,
        }}
      >
        <MUITabs
          headers={[
            {
              key: 0,
              value: "Forward Delays",
              icon: (
                <Badge
                  badgeContent={forwardDelayed?.length}
                  color="error"
                  max={5000}
                >
                  <LocalShippingIcon color="error" />
                </Badge>
              ),
            },
            {
              key: 1,
              value: "Pickup Delays",
              icon: (
                <Badge
                  badgeContent={pickUpDelayed?.length}
                  color="error"
                  max={5000}
                >
                  <LocalShippingIcon color="error" />
                </Badge>
              ),
            },
            {
              key: 2,
              value: "RTO Delays",
              icon: (
                <Badge
                  badgeContent={rtoDelayed?.length}
                  color="error"
                  max={5000}
                >
                  <LocalShippingIcon color="error" />
                </Badge>
              ),
            },
            /* { key: 2, value: 'Reattempt' },
                        { key: 3, value: 'RTO Delayed' }, */
          ]}
          active={tab}
          handleChange={(_e, newValue) => setTab(newValue)}
        />
      </Box>
      {tab === 0 && <ForwardDelayed data={forwardDelayed} />}
      {tab === 1 && (
        <PickupDelay
          pickUpDelayed={pickUpDelayed}
          // pickedUpOrders={pickedUpOrders}
        />
      )}
      {tab === 2 && (
        <RtoDelay
          rtoDelayed={rtoDelayed}
          // pickedUpOrders={pickedUpOrders}
        />
      )}
    </div>
  );
}

export default withLayout(ControlTower);
