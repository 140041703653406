import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { FormProvider, RHFFileField } from "../../common/hook-form";
import { useFieldArray, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RHFTextArea from "../../common/hook-form/RHFTextArea";
import { apiConstants, post } from "../../common/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { getMerchant } from "../../helpers/ReduxHelpers";

const Reply = ({ ticketId, handleClose, setReload, emails }) => {
    const [isLoading, setIsLoading] = useState(false);
    // const { userInfo } = useSelector((state) => state.user);
    // const userToken = jwt_decode(getToken());
    // const merchantId = userToken && userToken ? userToken : "";

    const merchant = getMerchant();

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                text: Yup.string().trim().required("This field is required")
            })
        ),
        defaultValues: {
            list: [
                {
                    file: null
                },
            ],
            text: '',
        },
    });

    const { handleSubmit, reset, control } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });



    const onSubmit = async (data) => {
        setIsLoading(true);
        let formData = new FormData();
        // formData.append('body', `<h4>${userInfo.merchant?.name} : ${merchantId?.fullname} / ${merchantId?.name}</h4>
        formData.append('body', `<h4>${merchant?.name} : ${merchant?.name} / ${merchant?.email}</h4>
        <p>
           ${data.text + "|tikprzautoProd"}
        </p>
        `);
        if (emails && emails.length > 0) {
            emails.forEach(email => {
                formData.append('cc_emails[]', email);
            });
        }
        // formData.append('cc_emails', userInfo?.merchant?.email);
        if (data.file) {
            formData.append('attachments', data.file[0])
        }
        if (data.list && data.list.length > 0) {
            data.list.forEach((item, index) => {
                if (item.file && item.file[0]) {
                    formData.append(`attachments`, item.file[0]);
                }
            });
        }
        try {
            await post(`${apiConstants.BASE_LOCAL}${apiConstants.REPLY_FRESHDESK}/${ticketId}`, formData);
            setIsLoading(false);
            handleClose();
            setReload(reload => reload + 1);
            toast("Successfully submitted", { type: "success" });
        } catch (err) {
            console.error(err);
            setIsLoading(false);
            handleClose();
            toast("Some error occurred!", { type: "error" });
        }
    };

    return (
        <>
            <Grid item container spacing={2}>
                <Grid item xs={12} md={12}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                                <RHFTextArea name="text" label="Write here *" />
                            </Grid>
                            {fields.map((field, index) => {
                                return (
                                    <React.Fragment key={field.id}>
                                        <Grid container spacing={2} sx={{ mt: 1, ml: 0.6 }}>
                                            <Grid item xs={6}>
                                                <RHFFileField name={`list.${index}.file`} label={`Upload File ${index + 1}`} />
                                            </Grid>
                                            {
                                                fields.length !== 1 && (
                                                    <Grid item xs={1.5} sx={{ mt: 2 }}>
                                                        <Button
                                                            onClick={() => remove(index)}
                                                            color="error"
                                                            variant="contained"
                                                            startIcon={<RemoveCircleOutlineIcon />}
                                                            size="small"
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Grid>
                                                )
                                            }
                                            {fields.length === index + 1 && (
                                                <Grid item xs={1.5} sx={{ mt: 2 }}>
                                                    <Button
                                                        onClick={() =>
                                                            append({
                                                                file: "",
                                                            })
                                                        }
                                                        variant="contained"
                                                        startIcon={<AddIcon />}
                                                        size="small"
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}
                            <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                                <LoadingButton
                                    loading={isLoading}
                                    type="submit"
                                    variant="contained"
                                >
                                    Reply
                                </LoadingButton>
                                <Button
                                    sx={{ ml: 1 }}
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        reset();
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Grid>
            </Grid>
        </>
    );
};

export default Reply;
