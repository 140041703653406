import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { apiConstants } from "../../../common/constants";

export const getOrderLists = createAsyncThunk(
    'getOrdeDetails',
    async (param, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('userToken')
                },
            };
            const url = `${apiConstants.BASE_LOCAL}${apiConstants.ORDER_LISTS}`;
            const { data } = await axios.post(
                url,
                {
                    "andfilter": param.andfilter,
                    "orfilter": param.orfilter,
                    "offset": param?.offset,
                    "limit": param?.limit,
                    "excludes": param?.excludes,
                    "skipCount": param?.skipCount,
                    "sort": param?.sort
                },
                config
            )
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const syncOrder = createAsyncThunk(
    'syncOrder',
    async (param, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('userToken')
                },
            };
            const url = `${apiConstants.BASE_LOCAL}${apiConstants.SYNC_ORDER}?waybill=${param.waybill}&reference=${param.reference}`;
            const { data } = await axios.get(
                url,
                config
            )
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);