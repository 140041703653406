import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { RHFRadioGroup, RHFSelectField } from "../../../common/hook-form";
import { formatCurrency } from "../../../helpers/UtilityHelper";
import { addDays, format } from "date-fns";
import { apiConstants, post } from "../../../common/constants";
import _ from 'lodash'
import { LoadingButton } from "@mui/lab";
import { useGetCouriersQuery } from "../../../redux/commonRTKPersist";
import { CourierSkeleton } from "../../../common/Skeletons";
import { useSnackbar } from "notistack";


function SelectCourier({ setActiveStep, formData, pickupLocations, creatingOrder, merchantId }) {
    const [newCourier, setNewCourier] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { data: allCouriers } = useGetCouriersQuery()
    const { watch } = useFormContext();
    const courierSelection = watch('courier_type')
    const [dataFetched, setDataFetched] = useState(false);
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if (formData && courierSelection === "Other" && !dataFetched) {
            setIsLoading(true)
            const selectedAddress = pickupLocations?.find(loc => loc.id === formData?.pickupLocation);
            const pickup_details = {
                from_address: selectedAddress?.address_line,
                from_city: selectedAddress?.city,
                from_country: selectedAddress?.country,
                from_name: selectedAddress?.name,
                from_pincode: selectedAddress?.pincode,
                from_state: selectedAddress?.state,
                from_phone_number: selectedAddress?.contactNumber,
                from_email: selectedAddress?.email,
                gstin: selectedAddress?.gstin
            }

            let length = _.sumBy(formData?.shipment_detail, "item_length")
            let breadth = _.sumBy(formData?.shipment_detail, "item_breadth")
            let height = _.sumBy(formData?.shipment_detail, "item_height")
            let weight = _.sumBy(formData?.shipment_detail, "item_weight")
            if (pickup_details) {
                post(`${apiConstants.BASE_LOCAL}${apiConstants.SHIPMENT_RATE_CALCULATOR}`, {
                    orderType: "RVP",
                    // to: parseInt(formData.delivery_details?.to_pincode),
                    to: parseInt(pickup_details?.from_pincode),
                    from: parseInt(formData.pickup_details?.from_pincode),
                    paymentType: formData?.payment_mode.toLowerCase() === "prepaid" ? "PREPAID" : "COD",
                    "length": length,
                    "breadth": breadth,
                    "height": height,
                    "weight": weight,
                    "dispatchMode": "SURFACE",
                    "codAmount": Number(formData.cod_amount) || 0,
                    "isMerchant": true,
                    onlyCouriersAsPerRuleMapping: true,
                    merchantOid: merchantId

                }).then((res) => {
                    setIsLoading(false)
                    const pricing = res?.data;
                    const newCourier = allCouriers?.map((d) => {
                        const updatedCourier = { ...d };
                        pricing.forEach((t) => {
                            if (t.provider?.id === updatedCourier.prozoId) {
                                updatedCourier.priceAfterTax = t.priceAfterTax;
                                updatedCourier.providerMinTatDays = t.providerMinTatDays;
                                updatedCourier.providerMaxTatDays = t.providerMaxTatDays;
                            }
                        });
                        return updatedCourier;
                    })
                    let filteredCouriers = newCourier.filter(item =>
                        item.priceAfterTax &&
                        item.type === 'RVP'
                    )
                    const now = new Date();
                    filteredCouriers.forEach(shipment => {
                        let minTatDays = shipment.providerMinTatDays || 0;
                        let maxTatDays = shipment.providerMaxTatDays || 0;
                        shipment.minDeliveryDate = format(addDays(now, minTatDays), 'dd, MMM');
                        shipment.maxDeliveryDate = format(addDays(now, maxTatDays), 'dd, MMM');
                    });
                    setNewCourier(filteredCouriers);
                    setDataFetched(true);
                }).catch((err) => {
                    console.log(err)
                    enqueueSnackbar(err?.response?.data?.message || "Error fetching pricing", { variant: "error" });
                    setIsLoading(false)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, courierSelection, dataFetched])

    return (
        <>
            {(isLoading) ? <CourierSkeleton /> :
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RHFRadioGroup label="Courier Selection" name="courier_type" options={[
                                {
                                    label: "As per Recommendation Rules",
                                    value: "Prozo Choice",
                                },
                                { label: "Other Courier", value: "Other" },
                            ]}
                            />
                        </Grid>
                        {courierSelection === 'Other' && (
                            <Grid item xs={10}>
                                <RHFSelectField
                                    variant="filled"
                                    width="100%"
                                    name="courierPartner"
                                    label="Courier Partner"
                                    noOptionsText='No courier partner available for this pickup location'
                                    options={(newCourier)?.filter(row => !row.isExternal).map((c) => {
                                        return {
                                            label: <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <Typography variant="body2">{c?.name}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">{formatCurrency(c?.priceAfterTax)}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        {c.providerMinTatDays !== undefined && c.providerMaxTatDays !== undefined && c.providerMaxTatDays > 0 ?
                                                            `TAT: ${c.providerMinTatDays}-${c.providerMaxTatDays} Days` :
                                                            'TAT: Not Available'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        {c.providerMinTatDays !== undefined && c.providerMaxTatDays !== undefined && c.providerMaxTatDays > 0 ?
                                                            `EDD: ${c.minDeliveryDate}-${c.maxDeliveryDate}` :
                                                            'EDD: Not Available'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>,
                                            value: c?.prozoId
                                        };
                                    })}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Stack direction="row" columnGap={1} sx={{ mt: 3 }}>
                                <Button variant="contained" size="small" color="error" onClick={() => setActiveStep(3)}>Back</Button>
                                <LoadingButton loading={creatingOrder} variant="contained" size="small" type="submit">Create Shipment</LoadingButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}

export default SelectCourier;