import Loader from "../../common/Loader";
import withLayout from "../../hocs/Layout";
import { useState } from "react";
import { Chip, Grid, IconButton, Tooltip } from "@mui/material";
import ProzoDataGrid from "../../common/ProzoDataGrid";
import { useEffect } from "react";
import { getMerchant } from "../../helpers/ReduxHelpers";
import { apiConstants, post } from "../../common/constants";
import { formatCurrency, formatDate } from "../../helpers/UtilityHelper";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import PageTitle from "../../common/PageTitle";

function MerchantInvoice() {
    // const methods = useForm();
    // const { handleSubmit, reset } = methods
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(20);
    const [data, setData] = useState([])
    const [rowCount, setRowCount] = useState(0)
    const { id: merchantId } = getMerchant()

    useEffect(() => {
        setLoading(true);
        const request = {
            merchantRef: merchantId,
            reportStage: 'E_INVOICED',
            skip: page * sizePerPage,
            limit: sizePerPage

        }
        post(`${apiConstants.BASE_LOCAL}${apiConstants.SEARCH_INVOICE}`, request
        ).then((res) => {
            setLoading(false);
            setData(res?.data?.responseObj)
        }).catch((err) => {
            console.log(err)
            setLoading(false);
        })

    }, [sizePerPage, page])

    useEffect(() => {
        const request = {
            merchantRef: merchantId,
            reportStage: 'E_INVOICED',
            skip: 0,
            limit: 10000
        }
        post(`${apiConstants.BASE_LOCAL}${apiConstants.SEARCH_INVOICE}`, request
        ).then((res) => {
            setRowCount(res?.data?.responseObj.length)
        })

    }, [])


    const columns = [
        // {
        //     field: 'merchantName', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Merchant', width: 180
        // },
        { field: 'reportFrequency', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Report Frequency', flex: 1 },
        {
            field: 'createdAt', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Created Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs={12}>
                            {formatDate(row?.createdAt)}
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                )
            },
        },

        {
            field: 'reportStage', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Status', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                const status = row?.reportStage ? row?.reportStage : '';
                const color = status === 'GENERATED' ? "warning" : status === 'OPS_APPROVED' ? 'primary' : "success";

                return (
                    <Chip size="small" color={color} label={status} />
                )
            },
        },
        {
            field: 'fromDate', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'From Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.fromDate)
                )
            },
        },
        {
            field: 'toDate', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'To Date', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    formatDate(row?.toDate)
                )
            },
        },
        {
            field: 'totalOrders', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Total Orders', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.totalOrders ? row?.totalOrders : 0}</>
                )
            },
        },
        {
            field: 'totalInvoicedAmount', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Invoiced Amount', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>{row?.totalInvoicedAmount ? formatCurrency(row?.totalOrders) : formatCurrency(0)}</>
                )
            },
        },
        {
            field: 'action', headerAlign: "center", align: 'center', hideSortIcons: true, headerName: 'Action', flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Tooltip title="Download Invoice">
                            <IconButton onClick={() => window.open(row?.s3Link?.signedLink)} color="primary" size="small">
                                <DownloadSharpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Download E Invoice">
                            <IconButton onClick={() => window.open(row?.uploadedEInvoiceS3Link?.signedLink)} color="success" size="small">
                                <DownloadSharpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            },
        },]

    return (
        <div id="main-content" className="container-fluid">
            {(loading) && <Loader />}
            <PageTitle>Merchant Invoice</PageTitle>
            {/* <div id="page-title" className="col-12 col-md-3 col-lg-3">
                    <Link to={"/"} className="page-title">
                        <img src="img/back.svg" width="21px" alt="" /> Merchant Invoice
                    </Link>
                </div> */}
            {/* <Card>
                    <CardContent> */}
            <ProzoDataGrid
                columns={columns}
                hideFooterPagination={false}
                autoHeight={true}
                rows={data || []}
                sizePerPage={sizePerPage}
                setSizePerPage={setSizePerPage}
                setPage={setPage}
                page={page}
                pagination={true}
                filterMode={"server"}
                rowCount={rowCount}
                getRowId={(row) => row.id} />
            {/* </CardContent>
                </Card> */}
        </div>
    )
}

export default withLayout(MerchantInvoice);