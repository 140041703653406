import React from "react";
import { Button, CircularProgress, Grid, Icon, Typography } from "@mui/material";
import { RHFTextField } from "../../../common/hook-form";
import { useFormContext } from "react-hook-form";

/* let timerRef = null; */
function CustomerDetailsRVP({ setActiveStep, details }) {
    const { trigger, /* setValue */ } = useFormContext()
    const [fetchingCustomerDetails, /* setFetchingCustomerDetails */] = React.useState(false)

    const onChangeHandler = (e) => {
        /* clearTimeout(timerRef);

        const value = e.target.value

        timerRef = setTimeout(() => {
            //setFetchingCustomerDetails(true);
            //setValue('delivery_details.to_phone_number', value)
        }, 1000); */
    }

    const onNextHandler = async () => {
        if (await trigger(['pickup_details'])) {
            setActiveStep(1)
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="body2">Who is returning the order? (Sender's Info)</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <RHFTextField
                    // onChange={onChangeHandler}
                    variant="filled"
                    name="pickup_details.from_phone_number"
                    label="Phone Number"
                    placeholder="Enter buyer's phone number"
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: fetchingCustomerDetails ? <Icon><CircularProgress size={18} /></Icon> : null
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <RHFTextField
                    variant="filled"
                    name="pickup_details.from_name"
                    label="Name"
                    placeholder="Enter buyer's full name"
                    required
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <RHFTextField
                    variant="filled"
                    name="pickup_details.from_email"
                    label="Email Address"
                    placeholder="Enter buyer's email address"
                    required
                />
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography variant="body2">Where is the order being picked up from? (Sender's Address)</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <RHFTextField
                    variant="filled"
                    name="pickup_details.from_address"
                    label="Complete Address"
                    placeholder="House/Floor No. Building Name or Street, Locality"
                    required
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <RHFTextField
                    variant="filled"
                    name="pickup_details.from_pincode"
                    label="Pincode"
                    placeholder="Enter buyer's pincode"
                    required
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <RHFTextField
                    variant="filled"
                    name="pickup_details.from_city"
                    label="City"
                    required
                    placeholder="Enter buyer's city"
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <RHFTextField
                    variant="filled"
                    name="pickup_details.from_state"
                    label="State"
                    placeholder="Enter buyer's state"
                    required
                />
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
                <Button variant="contained" size="small" onClick={onNextHandler}>Next</Button>
            </Grid>
        </Grid>
    );

}

export default CustomerDetailsRVP;