import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ErrorBoundary from "../../../../common/ErrorBoundary";
import { TableSkeleton } from "../../../../common/Skeletons";
import useForwardDeliveries from "./useForwardDeliveries";
import InfoBox from "../../../../common/InfoBox";
import { formatNumber, getDDMMYYYY, numberPrecision } from "../../../../helpers/UtilityHelper";
import CourierWiseSplit from "./CourierWiseSplit";
import AgeingSplitSplit from "./AgeingSplitSplit";
import ExportChartDataToExcel from "../../../../common/ExportChartDataToExcel";
import ZoneWiseSplit from "./ZoneWiseSplit";
import useGetCourier from "../useGetCourier";
import _ from 'lodash';
import { useSelector } from "react-redux";
import TatWiseSplit from "./TatWiseSplit";
import DayWiseAttempt from "../../../../common/DayWiseAttempt";

function ForwardDeliveriesComponent() {

    const [calcOn, setCalcOn] = React.useState('DOD')
    const [shipType, setShipType] = useState("B2C")
    const { isLoading, data } = useForwardDeliveries(calcOn, shipType)
    const { data: allCouriers } = useGetCourier()
    const { userInfo } = useSelector((state) => state.user)
    const downloadOptions = [
        {
            label: 'Download Delivery Attempt Analysis',
            fileName: 'DELIVERY_ATTEMPT_ANALYSIS',
            data: data?.allRegisteredData || [],
            columns: [
                { label: 'AWB Number', value: 'awbNumber' },
                { label: 'Courier', value: (row) => _.filter(allCouriers, { 'id': row.courier })[0]?.parent },
                { label: 'Status', value: 'orderStatus' },
                { label: 'Zone', value: 'merchantZone' },
                { label: 'Pickup Pincode', value: 'pickupPincode' },
                { label: 'Drop Pincode', value: 'dropPincode' },
                { label: 'AWB Date', value: (row) => row.awbRegisteredDate ? getDDMMYYYY(row.awbRegisteredDate) : '' },
                { label: 'Pickup Date', value: (row) => row.actualPickUpTime ? getDDMMYYYY(row.actualPickUpTime) : '' },
                { label: 'Delivery Date', value: (row) => row.actualDeliveryTime ? getDDMMYYYY(row.actualDeliveryTime) : '' },
                { label: 'First Attempt In/Out TAT', value: (row) => row.firstAttemptWithinSLA ? "IN" : "OUT" },
            ]
        },
        {
            label: 'Download Delivery Analysis',
            fileName: 'DELIVERY_ANALYSIS',
            data: data?.allRegisteredData || [],
            columns: [
                { label: 'AWB Number', value: 'awbNumber' },
                { label: 'Courier', value: (row) => _.filter(allCouriers, { 'id': row.courier })[0]?.parent },
                { label: 'Status', value: 'orderStatus' },
                { label: 'Zone', value: 'merchantZone' },
                { label: 'Pickup Pincode', value: 'pickupPincode' },
                { label: 'Drop Pincode', value: 'dropPincode' },
                { label: 'AWB Date', value: (row) => row.awbRegisteredDate ? getDDMMYYYY(row.awbRegisteredDate) : '' },
                { label: 'Pickup Date', value: (row) => row.actualPickUpTime ? getDDMMYYYY(row.actualPickUpTime) : '' },
                { label: 'First Delivery Attempt', value: (row) => row.firstDeliveryAttemptTimestamp ? getDDMMYYYY(row.firstDeliveryAttemptTimestamp) : '' },
                { label: 'Delivery Date', value: (row) => row.actualDeliveryTime ? getDDMMYYYY(row.actualDeliveryTime) : '' },
                { label: 'Delivery In/Out TAT', value: (row) => row.isBreached ? "OUT" : "IN" },
            ]
        }
    ]

    const handleChange = (event) => {
        setShipType(event.target.value);
    }
    return (
        <Accordion expanded>
            <AccordionSummary sx={{ boxShadow: 1, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">Forward Deliveries</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {(userInfo?.merchant?.isD2CMerchant && userInfo?.merchant?.isPTLMerchant) &&
                                <FormControl variant="outlined" size="small" sx={{ mr: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Shipment Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={shipType}
                                        onChange={handleChange}
                                        label="Shipment Type"
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                color: 'red', // Change the icon color
                                            },
                                        }}
                                    >
                                        <MenuItem value={"B2C"}>B2C</MenuItem>
                                        <MenuItem value={"B2B"}>B2B</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            <ExportChartDataToExcel options={downloadOptions} />
                            <ToggleButtonGroup
                                color="primary"
                                value={calcOn}
                                exclusive
                                size="small"
                                onChange={(_e, v) => setCalcOn(v)}
                                sx={{
                                    '& .MuiToggleButton-root': {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        border: '1px solid',
                                    }
                                }}
                            >
                                <ToggleButton value="DOD">DoD</ToggleButton>
                                <ToggleButton value="WOW">WoW</ToggleButton>
                                <ToggleButton value="MOM">MoM</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        The data below provides analysis on the delivery performance. All data is populated on basis of the date of shipment creation in Proship.
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f5f8fa', px: 0 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        {isLoading && <TableSkeleton rows={10} />}
                        {!isLoading && (
                            <Grid container spacing={1}>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="Delivered Shipments" value={`${formatNumber(data?.summary?.totalDelivered)} (${numberPrecision(data?.summary?.forwardDeliveredPercentage)}%)`} />
                                </Grid>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="Delivered (In-TAT)" value={`${formatNumber(data?.summary?.deliveredInTatCount)} (${numberPrecision(data?.summary?.deliveredInTatPercentage)}%)`} />
                                </Grid>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="Delivered (Out-TAT)" value={`${formatNumber(data?.summary?.deliveredOutTatCount)} (${numberPrecision(data?.summary?.deliveredOutTatPercentage)}%)`} />
                                </Grid>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="Delivery Attempt (In-TAT)" value={`${formatNumber(data?.summary?.deliveryAttemptInTatCount)} (${numberPrecision(data?.summary?.deliveryAttemptInTatPercentage)}%)`} />
                                </Grid>
                                <Grid item xs={12 / 5}>
                                    <InfoBox header="Delivery Attempt (Out-TAT)" value={`${formatNumber(data?.summary?.deliveryAttemptOutTatCount)} (${numberPrecision(data?.summary?.deliveryAttemptOutTatPercentage)}%)`} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CourierWiseSplit title="Courier Wise Delivery Attempt In-TAT / Out-TAT" data={data?.courierWiseDeliveryAttempt || []} uniqCourierIds={data?.uniqCourierIds || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CourierWiseSplit title="Courier Wise Delivery In-TAT / Out-TAT" data={data?.courierWiseDelivered || []} uniqCourierIds={data?.uniqCourierIds || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ZoneWiseSplit
                                        title="Zone Wise - Delivery Attempt out-TAT Trend"
                                        data={data?.zoneWiseDeliveryAttempt || []}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ZoneWiseSplit title="Zone Wise Delivered In-TAT / Out-TAT" data={data?.zoneWiseDelivered || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <AgeingSplitSplit title="Delivery Out-TAT Ageing Analysis" data={data?.deliveryOutTat || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TatWiseSplit key="TATSplit_1" title="TAT Adherence (Actual Delivery Date)" data={data?.tatSplit || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TatWiseSplit key="TATSplit_2" title="TAT Adherence (First Delivery Attempt Date)" data={data?.tatSplitFirstDeliveryAttempt || []} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DayWiseAttempt
                                        key={calcOn}
                                        title="Day Wise Delivery Attempt Split"
                                        data={data?.allRegisteredData || []}
                                        groupOn={calcOn}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default function ForwardDeliveries() {
    return (
        <ErrorBoundary>
            <ForwardDeliveriesComponent />
        </ErrorBoundary>
    )
}