import React from "react"
import { addDays, addMonths, addWeeks, differenceInDays, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isWithinInterval } from 'date-fns'
import _ from 'lodash'
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector"
import { avgPickupAgeing, flattenResponseData, formatDate, getShipmentInfo, getYYYYMMDD, numberPrecision, placed } from "../../../../helpers/UtilityHelper"
import { useGetSelectedFilters } from "../../../../common/contexts/Filters"
import { getMerchant } from "../../../../helpers/ReduxHelpers"
import { useGenericPostRequestQuery } from "../../../../redux/commonRTK"
import { apiConstants } from "../../../../common/constants"
import useGetCourier from "../useGetCourier"

const weekStartsOn = 1

export default function usePickupPerformance(groupOn = 'WOW', shipmentType) {
    const { id } = getMerchant()
    const { from_date, to_date } = useGetSelectedDateRange()
    const filters = useGetSelectedFilters()
    // const { userInfo } = useSelector((state) => state.user)
    // const shipmentInfo = getShipmentInfo(userInfo, shipmentType);

    const merchant = getMerchant();
    const shipmentInfo = getShipmentInfo(merchant, shipmentType);  


    const skip = !from_date || !to_date
    const request = { from_date, to_date, merchant_list: id, orderType_list: 'FORWARD', shipmentType_list: shipmentInfo }
    const { data: allRegistered, isLoading: isLoadingReg, isFetching: isFetchingReg } = useGenericPostRequestQuery({
        url: apiConstants.ALL_AWB_REGISTERED_BY_DATE_RANGE,
        data: request
    }, { skip })

    const { data: allCouriers, isLoading: isLoadingCouriers, isFetching: isFetchingCouriers } = useGetCourier()
    const [isCalc, setIsCalc] = React.useState(true)

    const allRegisteredData = _.filter(flattenResponseData(allRegistered || []), filters)
    const allPickedUp = React.useMemo(() => {
        return _(allRegisteredData).filter(function (item) {
            return !['CANCELLED_ORDER', ...placed].includes(item.orderStatus)
        }).value()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allRegisteredData?.length])

    const pickupPayload = { merchantId: id, awbNumbers: allPickedUp.map(r => r.awbNumber) }
    const genericRequest = { url: apiConstants.get_specific_data_pickup, type: 'POST', data: [pickupPayload] }
    const { data: actualPickupDetails, isLoading: isLoadingPickup, isFetching: isFetchingPickup } = useGenericPostRequestQuery(genericRequest, { skip: allPickedUp.length === 0 })

    // Create a hashmap from actualPickupDetails
    const pickupDetailsMap = (actualPickupDetails || []).reduce((map, pd) => {
        map.set(pd.awbNumber, pd)
        return map;
    }, new Map());

    // Map and merge data more efficiently
    const allPickedUpData = allPickedUp.map(order => {
        let pickupDetails = {}
        if (pickupDetailsMap.has(order.awbNumber)) {
            pickupDetails = pickupDetailsMap.get(order.awbNumber)
        }
        //const pickupDetails = pickupDetailsMap[order.awbNumber] || {};
        return { ...order, ...pickupDetails };
    });

    const allPickedUpDataCount = allPickedUpData.length

    const results = React.useMemo(() => {

        if (isFetchingReg || isLoadingReg || isFetchingCouriers || isLoadingCouriers || isLoadingPickup || isFetchingPickup) {
            setIsCalc(true)
            return;
        }

        const allPickedUpOrders = _(allPickedUpData).map(function (i) {
            const item = { ...i }
            const breachedDays = differenceInDays(new Date(item.actualPickUpTime), new Date(item.awbRegisteredDate)) || 0
            const withInTime = breachedDays ? false : true

            item.withInTime = withInTime
            item.breachDay = breachedDays

            return item;
        }).value()

        const breachedPickup = _.filter(allPickedUpOrders, { withInTime: false })
        const totalPickupDays = _.sumBy(allPickedUpOrders, order => Math.max(order.breachDay, 0));

        let intervalArr = [],
            lineChartData = [],
            totalShipmentPickedUp = allPickedUpDataCount,
            totalBreachedShipments = breachedPickup.length,
            avgPickupDays = totalPickupDays / totalShipmentPickedUp;

        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1)
            intervalArr = eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1)
            intervalArr = eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1)
            intervalArr = eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        }

        const allPickedUpOrdersGroupedByDate = _.groupBy(allPickedUpOrders, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })

        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index]
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : new Date()

            let name = null;
            if (groupOn === 'DOD') {
                name = formatDate(start, 'dd MMM')
            } else if (groupOn === 'WOW') {
                name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`
            } else {
                name = formatDate(start, 'MMM')
            }

            const row = { name }

            const allLostDatesBetween = _.filter(Object.keys(allPickedUpOrdersGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })

            const allPickedUpOrdersBetweenDate = allLostDatesBetween.map(key => allPickedUpOrdersGroupedByDate[key]).flat()
            const totalPickupDays = _.sumBy(allPickedUpOrdersBetweenDate, 'breachDay')
            const avgPickup = Number(numberPrecision(totalPickupDays / allPickedUpOrdersBetweenDate.length))

            _(allPickedUpOrdersBetweenDate)
                .groupBy(function (item) {
                    return getAgeingKey(item)
                })
                .forIn(function (value, key) {
                    const count = value?.length || 0
                    const percentage = Number(numberPrecision((count / allPickedUpOrdersBetweenDate.length) * 100))
                    row[`Ageing_${key}_count`] = count
                    row[`Ageing_${key}_percentage`] = percentage
                })


            row.avgPickup = avgPickup < 0 ? 0 : avgPickup
            lineChartData.push(row)
        }

        const pickupPincodeWiseAgeing = _(allPickedUpOrders)
            .groupBy('pickupPincode')
            .map(function (items, pincode) {
                return getAgeingSplit(items, pincode)
            }).value()

        const courierWiseAgeing = _(allPickedUpOrders)
            .groupBy('courier')
            .map(function (items, key) {
                const courier = _.find(allCouriers, { id: key })
                let courierName = "NA"
                if (courier) {
                    courierName = `${courier.parent}/${courier.id}`
                }
                return getAgeingSplit(items, courierName)
            }).value()

        const result = { courierWiseAgeing, pickupPincodeWiseAgeing, totalShipmentPickedUp, totalBreachedShipments, avgPickupDays, lineChartData }

        setIsCalc(false)

        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from_date, to_date, allPickedUpDataCount, isLoadingReg, isFetchingReg, groupOn, isFetchingCouriers, isLoadingCouriers, allCouriers, isFetchingPickup, isLoadingPickup])

    if (isCalc) return { isLoading: isCalc }
    return { data: results, isLoading: isCalc };
}

function getAgeingKey(item) {
    if (item.breachDay > 4) {
        return '4+_Days'
    } else if ([0, 1].includes(item.breachDay)) {
        return `${item.breachDay}_Day`
    }

    return `${item.breachDay}_Days`
}

function getAgeingSplit(items, key) {
    const totalItems = items.length
    const row = { name: key }
    const groupByAgeing = _.groupBy(items, function (item) {
        return getAgeingKey(item)
    })

    for (const ageing of avgPickupAgeing) {
        const count = groupByAgeing?.[ageing]?.length || 0
        row[ageing] = count
        row[`${ageing}_perc`] = Number(numberPrecision((count / totalItems) * 100))
    }

    return row
}