import React, { useEffect, useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import { RHFTextField } from '../../../common/hook-form';
import {
    Button,
    Grid,
} from "@mui/material";
import RHFSelectField from "../../../common/hook-form/RHFSelectField";
import { factList, fieldoperatorList, ndrReasonList, ofdCountList, paymentModeList } from "../../../helpers/UtilityHelper";
import useGetCourier from "../../dashboard/performance/useGetCourier";
import _ from "lodash";

const FormField = ({ watch, control, reset }) => {

    const { fields, append, remove } = useFieldArray({
        control,
        name: "list",
    });

    const selectedFacts = fields.map(field => field.fact);

    const availableOptions = useMemo(() => {
        return factList.filter((factItem) => !selectedFacts.includes(factItem.value));
    }, [selectedFacts]);

    return (
        <>
            {fields.map((field, index) => {
                const selectedFactLabel = factList.find(fact => fact.value === field.fact)?.label || '';
                return <Form key={field.id} index={index} watch={watch} fields={fields.length} append={append} remove={remove} reset={reset}
                    availableOptions={availableOptions}
                    // selectedFact={field.fact}
                    selectedFact={{ value: field.fact, label: selectedFactLabel }}
                />
            })}
        </>
    )
}

export default FormField


const Form = ({ index, watch, fields, append, remove, reset, selectedFact, availableOptions }) => {
    const { data: allCouriers } = useGetCourier()

    const couriers = useMemo(() => {
        return allCouriers && _.uniqBy(
            allCouriers.filter(val => val.supported_shipment === "B2C"),
            'parent'
        ).map(val => ({
            label: val.parent,
            value: val.parent,
        }));
    }, [allCouriers]);

    const getActionOptions = (factValue) => {
        const actionOptionsMap = {
            ofdCount: ofdCountList,
            ndrReason: ndrReasonList,
            paymentMode: paymentModeList,
            courierParentName: couriers
        };
        return actionOptionsMap[factValue] || [];
    };

    const data = watch(`list.${index}.fact`)
    const factData = useMemo(() => getActionOptions(data), [data, couriers]);


    useEffect(() => {
        reset(`list[${index}].fieldValue`)
    }, [data])

    return (
        <>
            <Grid container spacing={2} sx={{ ml: 1 }} key={index}>
                <Grid item xs={3} sx={{ overflow: 'hidden' }}>
                    <RHFSelectField
                        name={`list[${index}].fact`}
                        label="Fact"
                        width="100%"
                        options={
                            selectedFact.value
                                ? [...availableOptions, selectedFact]
                                : availableOptions
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <RHFSelectField
                        name={`list[${index}].operator`}
                        label="Operator"
                        width="100%"
                        options={fieldoperatorList}
                    />
                </Grid>
                {watch(`list[${index}].fact`) === "daysSinceFirstAttempt" ?
                    <Grid item xs={3}>
                        <RHFTextField
                            name={`list[${index}].firstAttempt`}
                            label="Days since first reattempt"
                            width="100%"
                            color="secondary"
                            variant="filled"
                        />
                    </Grid>
                    :
                    <Grid item xs={3} sx={{ overflow: 'hidden' }}>
                        <RHFSelectField
                            name={`list[${index}].fieldValue`}
                            label="Field Value"
                            width="100%"
                            multiSelect={true}
                            options={factData}
                        />
                    </Grid>
                }
                {fields !== 1 && (
                    <Grid item xs={1.5} sx={{ mt: 1 }}>
                        <Button
                            onClick={() => remove(index)}
                            color="error"
                            variant="contained"
                            size="small"
                        >
                            Remove
                        </Button>
                    </Grid>
                )}
                {fields === index + 1 && (
                    <Grid item xs={1.5} sx={{ mt: 1 }}>
                        <Button
                            onClick={() =>
                                append({
                                    fact: '',
                                    operator: '',
                                    fieldValue: [],
                                })
                            }
                            variant="contained"
                            size="small"
                        >
                            Add
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    )
}