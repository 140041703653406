import { Button, Grid, Stack, Typography } from "@mui/material";
import { useGetMerchantQuery } from "../../redux/commonRTK";
import { getMerchant } from "../../helpers/ReduxHelpers";
import { FormSkeleton } from "../../common/Skeletons";
import { RHFSearchableSelectField } from "../../common/hook-form";
import _ from "lodash";
import { addressString } from "../../helpers/UtilityHelper";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

function DropLocation({ setActiveStep }) {
    const merchant = getMerchant();
    const selectedDeliveryLocationId = useWatch({ name: 'delivery_location' })
    const { setValue, trigger } = useFormContext()

    const { data, isLoading, isFetching } = useGetMerchantQuery({ id: merchant.id })

    const deliveryLocations = data?.deliveryLocationList || [];
    
    const deliveryLocationOptions = _(deliveryLocations)
        .filter({ status: 'ACTIVE', address_type: 'DELIVERY' })
        .map((loc) => ({
            value: loc.id,
            label: addressString(loc)
        })).value()

    React.useEffect(() => {
        const selectedDeliveryLocation = _.find(deliveryLocations, { id: selectedDeliveryLocationId });
        setValue('delivery_details', {
            to_name: selectedDeliveryLocation?.name || selectedDeliveryLocation?.receiver_name,
            to_phone_number: selectedDeliveryLocation?.contactNumber || '',
            to_address: selectedDeliveryLocation?.address_line || '',
            to_email: selectedDeliveryLocation?.email || '',
            to_pincode: selectedDeliveryLocation?.pincode || '',
            to_city: selectedDeliveryLocation?.city || '',
            to_state: selectedDeliveryLocation?.state || '',
            to_country: selectedDeliveryLocation?.country || '',
            gstin: selectedDeliveryLocation?.gstin || '',
        })

        // eslint-disable-next-line
    }, [selectedDeliveryLocationId])

    if (isLoading || isFetching) {
        return <FormSkeleton />
    }

    const onNextHandler = async () => {
        const status = await trigger(['delivery_location', 'delivery_details']);
        if (status) {
            setActiveStep(2)
        }
    }

    const onKeyPressHandler = (e) => {
        if (e.charCode === 13) {
            onNextHandler();
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ mb: 3 }}>
                <RHFSearchableSelectField
                    options={deliveryLocationOptions}
                    width="100%"
                    label="Please Select Delivery Location*"
                    name="delivery_location"
                    onKeyPress={onKeyPressHandler}
                    autoFocus={true}
                />
                <Typography variant="body2">
                    If Delivery Location is not present then create a new
                    <Button sx={{ py: 0, textTransform: 'none' }} target="_blank" href="/delivery-location">
                        Delivery Location
                    </Button>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" columnGap={1}>
                    <Button variant="contained" size="small" color="error" onClick={() => setActiveStep(0)}>Prev</Button>
                    <Button variant="contained" size="small" onClick={onNextHandler}>Next</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default DropLocation;