import React, { useEffect, useState } from "react";
import {
    apiConstants,
    postGeneric,
} from "../../../common/constants";

import {
    Button,
    Grid,
} from "@mui/material";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import MUIModal from "../../../common/MUIModal";
import { NDRCreateAutomatedRule } from "./createNdrRule";
import Loader from "../../../common/Loader"
import { useSnackbar } from "notistack";
import ConfirmDialog from "../../../common/ConfirmDialog";
import SideDrawer from "../../weight/list/SideDrawer";
import PageTitle from "../../../common/PageTitle";
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from "@mui/x-data-grid";

const NDRAutomatedRule = () => {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [reload, setReload] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [open, setOpen] = useState(false)
    const [viewOpen, setViewOpen] = useState(false)
    const [viewData, setViewData] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar()
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [deactivateConfirm, setDeactivateConfirm] = useState(false);
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''

    useEffect(() => {
        setIsLoading(true)
        const filtersToApply = [{ field: "merchantOid", operator: "eq", value: merchantId }];
        const payload = {
            "collection": "merchant_config",
            // "offset": page * sizePerPage,
            // "limit": sizePerPage,
            "skipCount": true,
            "countOnly": false,
            "includes": "ndrAutomatedRule",
            "andfilter": filtersToApply,
            "orfilter": [],
        }
        postGeneric(apiConstants.REPORT_GENERIC_SEARCH, payload).then((res) => {
            setIsLoading(false)
            setData(res.data?.data)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
        })
    }, [merchantId, reload])

    const columns = [
        {
            field: 'name', headerAlign: "left", hideSortIcons: true, headerName: 'Name', flex: 0.5
        },
        {
            field: 'condition', headerAlign: "left", hideSortIcons: true, headerName: 'Condition', flex: 1,
            align: 'left', renderCell: (params) => {
                const { row } = params;
                const conditions = row?.conditions?.map((val) => convertToCamelCase(val.fact));
                return conditions.join(', ');
            },
        },
        {
            field: 'actiontype', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Action Type', flex: 0.5, renderCell: (params) => {
                const { row } = params;
                return row.result.type
            }
        },
        {
            field: 'action', align: 'left', headerAlign: "left", hideSortIcons: true, headerName: 'Action', flex: 0.4, renderCell: (params) => {
                const { row } = params
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <Button onClick={() => { handleOpen(row) }}
                                    variant="contained"
                                    size="small"
                                >
                                    View
                                </Button>
                                <Button onClick={() => { toggleDelete(row) }}
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    disabled={data && data[0]?.ndrAutomatedRule?.rules?.length === 1}
                                    sx={{ ml: 1 }}
                                >
                                    Remove
                                </Button>
                            </Grid>
                            <Grid item xs={12}></Grid>
                        </Grid>

                    </>
                )
            }
        },
    ]

    const handleOpen = (val) => {
        setViewOpen(true)
        setViewData(val)
    }

    function convertToCamelCase(snakeCase) {
        return snakeCase.toLowerCase().replace(/([-_][a-z])/g, group =>
            group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        );
    }

    const closeRule = (val) => {
        let payload = {
            "ruleList": val.name,
            "action": val.result.type === "INITIATE_RTO" ? 'rto' : 'reattempt'
        };
        val.conditions?.forEach((item) => {
            const camelCaseKey = convertToCamelCase(item.fact);
            payload[camelCaseKey] =
                item.fact === "DAYS_SINCE_FIRST_ATTEMPT" ? JSON.parse(item.value).toString()
                    : item.fact === "PREFERRED_DELIVERY_DATE" ? item.value
                        : JSON.parse(item.value).map((value) => value.toString())
        });
        postGeneric(`${apiConstants.NDR_RULEUPDATE}?merchantOid=${merchantId}&status=INACTIVE`, payload).then((res) => {
            if (res.data.status === "SUCCESS") {
                enqueueSnackbar("Success!", { variant: "success" });
                setReload(reload => reload + 1)
            } else {
                enqueueSnackbar("Error while removing rule!", { variant: "error" });
            }
        }).catch((err) => {
            enqueueSnackbar("Some error occurred!", { variant: "error" });
            console.log(err);
        })
    }

    const toggleDelete = (id) => {
        setCurrentId(id);
        setDeleteConfirm(true);
    };
    const toggleDeactivate = (id) => {
        // setCurrentId(id);
        setDeactivateConfirm(true);
    };

    const deactivateRule = () => {
        postGeneric(`${apiConstants.NDR_RULEDEACTIVATE}?merchantOid=${merchantId}&status=INACTIVE`).then((res) => {
            enqueueSnackbar("Success!", { variant: "success" });
            setReload(reload => reload + 1)
        }).catch((err) => {
            enqueueSnackbar("Some error occurred!", { variant: "error" });
            console.log(err);
        })
    }

    return (
        <>
            <ConfirmDialog
                title=''
                open={deleteConfirm}
                setOpen={setDeleteConfirm}
                onConfirm={() => closeRule(currentId)}
            >
                Are you sure you want to remove this rule?
            </ConfirmDialog>
            <ConfirmDialog
                title=''
                open={deactivateConfirm}
                setOpen={setDeactivateConfirm}
                onConfirm={() => deactivateRule(currentId)}
            >
                Are you sure you want to Deactivate ?
            </ConfirmDialog>
            {isLoading && <Loader />}
            {/* <MUIModal
                open={open}
                title="Create Rule"
                handleClose={() => setOpen()}
                children={<NDRCreateAutomatedRule setOpen={setOpen} setReload={setReload} />}
            >
            </MUIModal> */}
            <SideDrawer title='Create New Automated Rule'
                subtitle='Provide details below'
                open={open ? true : false}
                setOpen={() => setOpen(false)}
                drawerWidth={'65%'}
                children={<NDRCreateAutomatedRule setOpen={setOpen} setReload={setReload} handleOpen={handleOpen} />}>
            </SideDrawer>
            <MUIModal
                open={viewOpen}
                title="Rule Details"
                handleClose={() => setViewOpen()}
                children={<ViewRuleDetail viewData={viewData} />}
            >
            </MUIModal>
            <PageTitle>Automated Rule Management</PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={3} paddingBottom={2}>
                        <Grid item xs={12} md={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                // size="small"
                                endIcon={<AddIcon />}
                                onClick={() => {
                                    setOpen(true)
                                    localStorage.removeItem("factName")
                                }}
                            >
                                Create
                            </Button>
                        </Grid>
                        {data && data[0]?.ndrAutomatedRule?.rules?.length === 1 ?
                            <Grid item xs={12} md={6} display={'flex'} justifyContent={'right'}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => { toggleDeactivate() }}
                                >
                                    Deactivate
                                </Button>
                            </Grid> : ""
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        rows={data[0]?.ndrAutomatedRule?.rules?.length > 0 ? data[0]?.ndrAutomatedRule?.rules?.map((row, index) => ({ ...row, id: index + 1 })) : []}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        pagination={true}
                        hideFooterPagination={false}
                        paginationMode={"client"}
                        rowCount={data[0]?.ndrAutomatedRule?.rules?.length || 0}
                        getRowId={(row) => row.id}
                    />
                </Grid>
            </Grid>
        </>

    );
};
export default NDRAutomatedRule;


const ViewRuleDetail = ({ viewData }) => {
    const columns = [
        {
            field: 'fact',
            headerAlign: 'left',
            hideSortIcons: true,
            headerName: 'Fact',
            flex: 0.5,
            renderCell: (params) => {
                const { row } = params;
                return row.fact;
            },
        },
        {
            field: 'operator',
            headerAlign: 'left',
            hideSortIcons: true,
            headerName: 'Operator',
            flex: 0.4,
            renderCell: (params) => {
                const { row } = params;
                return row.operator;
            },
        },
        {
            field: 'actionType',
            headerAlign: 'left',
            hideSortIcons: true,
            headerName: 'Action Type',
            flex: 0.5,
            renderCell: (params) => {
                return viewData?.result?.type
            },
        },
        {
            field: 'value',
            headerAlign: 'left',
            hideSortIcons: true,
            headerName: 'Value',
            flex: 0.6,
            renderCell: (params) => {
                const { row } = params;
                const parsedValue = JSON.parse(row.value);
                const formattedValue = Array.isArray(parsedValue) ? parsedValue.join(', ') : parsedValue;
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {formattedValue}
                            </Grid>
                        </Grid>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <DataGrid
                columns={columns}
                rows={Array.isArray(viewData?.conditions) ? viewData.conditions?.map((row, index) => ({ ...row, id: index + 1 })) : []}
                autoHeight
                hideFooterPagination={true}
                getRowId={(row) => row.id}
            />
        </>
    )
}